import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-manage-parents',
  templateUrl: './manage-parents.component.html',
  styleUrls: ['./manage-parents.component.css']
})
export class ManageParentsComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) { }

  parentData: any;
  ngOnInit(): void {
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'email': ''+sessionStorage.getItem('admin_email')+'', 'password': ''+sessionStorage.getItem('admin_password')+'' };

    this.http.post(''+this.siteurl+'/admin/manageParents',formData).subscribe(
        data => {
          console.log(data);
          this.parentData = data;
        }

      );
  }

}
