import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-game-big-small',
  templateUrl: './game-big-small.component.html',
  styleUrls: ['./game-big-small.component.css']
})
export class GameBigSmallComponent implements OnInit {
siteurl = this.shareurl.url;
minutes: number = 2;
timeLeft: number = 60;
interval:any;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { }

  game_id:any;
  student_id:any;
  ngOnInit(): void {
    this.game_id=this.route.snapshot.params['gid'];
    this.student_id=this.route.snapshot.params['sid'];
    this.startTimer();
  }
  selectRightans(event:any){
    $('.select-right-ans .row .inner').removeClass('active');
    $(event.target).addClass('active');
  }
  startTimer() {
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.timeLeft = 60;
          }
          if (this.timeLeft == 0) {
            // clearInterval(this.interval);
            // alert('time up');
            if(this.minutes == 0){
              clearInterval(this.interval);
              alert('time up');
            }else{
              this.minutes --;
            }
            
          }

        }, 1000);
      }
      pauseTimer() {
      clearInterval(this.interval);
    }

}
