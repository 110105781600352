<div class="teacher-on-demand">
	<div class="nav-step">
		<ul>
			<li class="one active">01</li>
			<li class="two">02</li>
			<li class="three">03</li>
			<li class="four">04</li>
		</ul>
	</div> <!-- nav-step -->
	<div class="step-content">
		<div class="step1">
			<h1 class="sound_bubble_demoregular text-center mb-3">Level of Teaching</h1>
			<ul>
				<li>Adult <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>College <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>High School <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>Meddle School <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>Elementory <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
			</ul>
			<div class="text-center">
				<button class="step-btn">Next</button>
			</div> <!-- text-center -->
		</div> <!-- step1 -->
		<div class="step2">
			<span class="back"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</span>
			<h1 class="sound_bubble_demoregular text-center mb-3">How Soon you will be available?</h1>
			<ul>
				<li>Right Now <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>Within a week <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>In 1-2 months <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>Just browsing <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
			</ul>
			<div class="text-center">
				<button class="step-btn">Next</button>
			</div> <!-- text-center -->
		</div> <!-- step2 -->
		<div class="step3">
			<span class="back"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</span>
			<h1 class="sound_bubble_demoregular text-center mb-3">What Subject you are the expected ?</h1>
			<div class="subject">
			<ul>
				<li>Art</li>
				<li>Business</li>
				<li>Computers</li>
				<li>Dance</li>
				<li>English</li>
				<li>Foreign Language</li>
				<li>Math</li>
				<li>Music & Drama</li>
				<li>Musical Instruments</li>
				<li>Science</li>
				<li>Special Education</li>
				<li>Sports & Fitness</li>
				<li>Test Prep</li>
				<li>Other</li>
			</ul>
			</div>
			<div class="text-center">
				<button class="step-btn">Next</button>
			</div> <!-- text-center -->
		</div> <!-- step3 -->
		<div class="step4">
			<span class="back"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</span>
			<h1 class="sound_bubble_demoregular text-center mb-3">Do you prefer in person or online tutoring?</h1>
			<ul>
				<li><div>Online <p>Widest selection of prices and expertise.</p></div> <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li><div>In Person <p>Face-to-face with a tutor near you.</p></div> <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
				<li>Either <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></li>
			</ul>
			<div class="text-center">
				<button class="step-btn" data-toggle="modal" data-target="#exampleModalCenter">Join Now</button>
			</div> <!-- text-center -->
		</div> <!-- step4 -->
	</div> <!-- step-content -->
</div> <!-- teacher-on-demand -->

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Your Request to join the Teacher on Demands has been sent to the admin</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
      </div>
    </div>
  </div>
</div>