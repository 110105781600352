<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>

<!-- body -->
<div class="vpod-body">
<div class="vpod-nav">
<ul>
    <li><a routerLink="/parent/pod-detail/{{pod_id}}">Home</a></li>
    <li><a routerLink="/parent/assignments/{{pod_id}}">Assignments</a></li>
    <li><a routerLink="/parent/notes/{{pod_id}}">Notes</a></li>
    <li><a routerLink="/parent/quiz/{{pod_id}}" class="active">Quiz</a></li>
    <li><a routerLink="/parent/team-preparation/{{pod_id}}">Team Preparation</a></li>
</ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
        <h3 class="text_color3">{{quizDetail.pod.name}}</h3>
  </div>
  <div class="vpod-body-inner">


          <div class="box mt-2 view-submission d-flex align-items-start justify-content-between">
            

                <div class="left border-right pr-3">
                  <div class="border rounded p-3 alert-secondary">
                    <span class="d-block w-100">Quiz title:</span>
                    <p class="h6 mt-2 mb-0">{{quizDetail.quiz.title}}</p>
                  </div>

                  <div class="border rounded p-3 alert-secondary mt-2 mb-2">
                    <span class="d-block w-100 mb-2">Quiz Description:</span>
                    <p>{{quizDetail.quiz.description}}</p>
                  </div>

                  <div *ngIf="quizDetail.res == 'passed'">
                    <img class="mw-100 d-block m-auto" src="../assets/img/time-over.png">
                    <h2 class="sound_bubble_demoregular d-block text-center mt-2">The quiz time is over now. Please try again.</h2>
                  </div>
                  <div class="time-counter mt-5" *ngIf="quizDetail.res == 'not started yet'">
                    <h3 class="sound_bubble_demoregular">Time Remaining</h3>
                    <ul>
                      <li>
                        <span>{{day}}</span>
                        Day
                      </li>
                      <li>
                        <span>{{hour}}</span>
                        Hour
                      </li>
                      <li>
                        <span>{{minute}}</span>
                        Minute
                      </li>
                      <li>
                        <span>{{second}}</span>
                        Second
                      </li>
                    </ul>
                  </div>
                  

<ng-container *ngIf="quizDetail.res == 'continue'">
                  <form class="mt-3" [formGroup]="form" (ngSubmit)="submitQuiz()">
                    
                    
                      <div class="d-flex align-items-center justify-content-between">
                      <h3 class="sound_bubble_demoregular">Question</h3>
                      <div class="d-flex align-items-center">
                        <select class="w-100 student_name" (change)="selectStudent()" name="student_name" formControlName="student_name">
                          <option value="" disabled selected>Select Student</option>
                          <option *ngFor="let student of quizDetail.students" value="{{student.id}}">{{student.fname}} {{student.lname}}</option>
                        </select>
                      </div>
                    </div> <!-- select-student -->

                    <div class="question-inner w-100 p-3 mt-3">
                        <p class="w-100 text-right">Time Remaining: {{continue_minutes}}:{{continue_seconds}}</p>
                        <div class="box" *ngFor="let question of quizDetail.questions;let i=index">
                          <h5>Q {{i + 1}}: {{question.statement}}</h5>
                          <div class="box-inner">
                          <div class="inner" (click)="selectRightans($event)" *ngFor="let option of question.options">
                              <input type="radio" name="mcroption{{i}}" value="{{option}}">
                              <span>A</span>
                              <p>{{option}}</p>
                          </div> <!-- inner -->
                        </div> <!-- box-inner -->
                        </div> <!-- box -->
                      </div> <!-- question-inner -->
                      <button type="submit" class="btn btn-primary mt-3">Submit</button>

                  </form>
                  </ng-container>
               

                  </div>
                  <div class="right">
                    <div class="clv_bg rounded pt-3 pb-3 text-center text-white mb-3">
                      <h2 class="mb-2 sound_bubble_demoregular">Total Marks</h2>
                      <h1 class="text_color4 sound_bubble_demoregular">{{quizDetail.quiz.total_marks}}</h1>
                    </div>
                    

                  </div> <!-- right -->
                </div>
            

                    </div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->

                        <p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  