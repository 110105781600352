import { Component, OnInit } from '@angular/core';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loading: any;

  constructor(private loaderService: LoaderService) {
    // this.loaderService.isLoading.subscribe((x) => {
    //   this.loading = x;
    // });
  }

  ngOnInit(): void { }

}