
<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}" class="active">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">Science</h3>
</div>
	<div class="vpod-body-inner">
<div class="mark-present d-flex align-items-center">
  <div class="custom-control custom-radio mr-5">
    <input type="radio" class="custom-control-input" id="present" name="mark_all">
    <label class="custom-control-label" for="present">Mark all Present</label>
  </div>
  <div class="custom-control custom-radio">
    <input type="radio" class="custom-control-input" id="absent" name="mark_all">
    <label class="custom-control-label" for="absent">Mark all Absent</label>
  </div>
</div>
          <div class="card-body p-0">
            <div class="mtable">
                <table datatable class="table">
                  <thead>
                  <tr>
                    <th>Roll Number</th>
                    <th>Name</th>
                    <th>Father Name</th>
                    <th>Present</th>
                    <th>Absent</th>
                    <th>Leave</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student1_present" name="1attendence">
  <label class="custom-control-label" for="student1_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student1_absent" name="1attendence">
  <label class="custom-control-label" for="student1_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student1_leave" name="1attendence">
  <label class="custom-control-label" for="student1_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student2_present" name="2attendence">
  <label class="custom-control-label" for="student2_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student2_absent" name="2attendence">
  <label class="custom-control-label" for="student2_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student2_leave" name="2attendence">
  <label class="custom-control-label" for="student2_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student3_present" name="3attendence">
  <label class="custom-control-label" for="student3_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student3_absent" name="3attendence">
  <label class="custom-control-label" for="student3_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student3_leave" name="3attendence">
  <label class="custom-control-label" for="student3_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student4_present" name="4attendence">
  <label class="custom-control-label" for="student4_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student4_absent" name="4attendence">
  <label class="custom-control-label" for="student4_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student4_leave" name="4attendence">
  <label class="custom-control-label" for="student4_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student5_present" name="5attendence">
  <label class="custom-control-label" for="student5_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student5_absent" name="5attendence">
  <label class="custom-control-label" for="student2_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student5_leave" name="5attendence">
  <label class="custom-control-label" for="student5_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student6_present" name="6attendence">
  <label class="custom-control-label" for="student6_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student6_absent" name="6attendence">
  <label class="custom-control-label" for="student6_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student6_leave" name="6attendence">
  <label class="custom-control-label" for="student6_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student7_present" name="7attendence">
  <label class="custom-control-label" for="student7_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student7_absent" name="7attendence">
  <label class="custom-control-label" for="student7_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student7_leave" name="7attendence">
  <label class="custom-control-label" for="student7_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student8_present" name="8attendence">
  <label class="custom-control-label" for="student8_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student8_absent" name="8attendence">
  <label class="custom-control-label" for="student8_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student8_leave" name="8attendence">
  <label class="custom-control-label" for="student8_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student9_present" name="9attendence">
  <label class="custom-control-label" for="student9_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student9_absent" name="9attendence">
  <label class="custom-control-label" for="student9_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student9_leave" name="9attendence">
  <label class="custom-control-label" for="student9_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student10_present" name="10attendence">
  <label class="custom-control-label" for="student10_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student10_absent" name="10attendence">
  <label class="custom-control-label" for="student10_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student10_leave" name="10attendence">
  <label class="custom-control-label" for="student10_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student11_present" name="11attendence">
  <label class="custom-control-label" for="student11_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student11_absent" name="11attendence">
  <label class="custom-control-label" for="student11_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student11_leave" name="11attendence">
  <label class="custom-control-label" for="student11_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student12_present" name="12attendence">
  <label class="custom-control-label" for="student12_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student12_absent" name="12attendence">
  <label class="custom-control-label" for="student12_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student2_leave" name="12attendence">
  <label class="custom-control-label" for="student12_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student13_present" name="13attendence">
  <label class="custom-control-label" for="student13_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student13_absent" name="13attendence">
  <label class="custom-control-label" for="student13_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student13_leave" name="13attendence">
  <label class="custom-control-label" for="student13_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student14_present" name="14attendence">
  <label class="custom-control-label" for="student14_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student14_absent" name="14attendence">
  <label class="custom-control-label" for="student14_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student14_leave" name="14attendence">
  <label class="custom-control-label" for="student14_leave"></label>
</div>
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>Banner Bruce</td>
                    <td>Petter Parker</td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student15_present" name="15attendence">
  <label class="custom-control-label" for="student15_present"></label>
</div></td>
                    <td><div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student15_absent" name="15attendence">
  <label class="custom-control-label" for="student2_absent"></label>
</div></td>
                     
                    <td>
                      <div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="student15_leave" name="15attendence">
  <label class="custom-control-label" for="student15_leave"></label>
</div>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!--- mtable ---->
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->