<!-- body -->
                  <div class="vpod-body">
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Pending POD Detail</h3>
                  </div>
                    <div class="vpod-body-inner p-3">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="d-flex align-items-start justify-content-between flex-wrap">
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
                                <h6>Name</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].fname}} {{pendingPod.pod[0].lname}}'s POD</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Country</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].cname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>State</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].sname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>City</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].city}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Zip Code</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].zipcode}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Primary Speaking Language</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].pplname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Secondary Speaking Language</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].pslname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Grade</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].grade_id}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Learning Enviroment</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].lename}}</p>
                              </div> <!-- div -->

                            </div>
                        </div>
                        <div class="col-md-4">
                          <div class="border rounded p-3 resume mb-3" style="box-shadow:0px 0px 28px 0px #e6e6e6;border-radius:15px;">
                            <h4>Select Child</h4>
                            <select class="d-block bg-secondary text-white rounded p-2 mt-3 w-100">
                                <option>Select Child </option>
                            </select>
                            <button type="Submit" class="btn btn-primary d-inline-block text-white pl-4 pr-4 mt-3">
                            Update</button>
                          </div>
                          <a routerLink="/parent/add-student" class="btn btn-success d-inline-block text-white mt-2 pl-4 pr-4">
                            Add New Child</a>
                        </div> <!-- col-md-4 -->
                      </div>
                        
                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->