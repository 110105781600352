<!-- sign up -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="signup-page parent-signup teacher">
  <div class="inner">
    <h2> Teacher Complete Profile!  </h2>
    <ul class="errors mb-2"></ul>
      <form class="w-100" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="parent-first-step d-flex align-items-start justify-content-between w-100 flex-wrap">
        <div class="name input">
        <input class="first_name" type="text" name="fname" placeholder="First Name" value="" formControlName="fname" />
        </div>
        <div class="name input">
        <input class="last_name" type="text" name="lname" placeholder="Last Name" value="" formControlName="lname">
        </div>
        
        <div class="country input select">
        <select (change)="changecountry()" class="country_select" name="country_id" formControlName="country_id">
          <option value="">Country</option>
        </select>
        </div>
        <div class="u_state input select">
          <select class="select_state" name="state_id"  formControlName="state_id">
            <option value="">State</option>
          </select>
        </div>
        <div class="city input">
        <input type="text" name="city" placeholder="City" formControlName="city">
        </div>
        
        <div class="address input">
        <input type="text" name="address" placeholder="Address" formControlName="address">
        </div>
        <div class="dob input">
          <input type="text" onfocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" name="dob" placeholder="Date of Birth" formControlName="dob">
        </div>


        <div class="zip_code input">
        <input type="text" name="zipcode" placeholder="Zip Code" formControlName="zipcode">
        </div>
        
        
        <div class="location input select">
            <select class="miles" name="miles" formControlName="miles">
              <option>Willing to travel to Pod location</option>
            </select>
        </div>

        <div class="language input select">
            <select name="ppsl"  formControlName="ppsl" class="select_ppl">
              <option value="">Pod Primary Speaking Language</option>
            </select>
        </div>

        <div class="language input select">
            <select name="pssl" formControlName="pssl" class="select_pssl">
              <option value="">Pod Secondary Speaking Language</option>
            </select>
        </div>

        <div class="p_status input select">
            <select name="certification" formControlName="certification">
              <option value="">Certification status</option>
              <option value="active">Active</option>
              <option value="notactive">Not Active</option>
            </select>
        </div>

        <div class="level input select">
          <select name="grade_id" formControlName="grade_id" class="grade">
              <option value="">Preferred Grade Level</option>
            </select>
        </div>
        <div class="level input select">
            <select class="teachingSkills" name="teachingSkill" formControlName="teachingSkill">
              <option value="">Special Needs Teaching Skills</option>
            </select>
        </div>
      <div class="cv input">
        <h6 class="mb-1">Your CV</h6>
        <input class="form-control-file bg-white" type="file" name="cv" formControlName="cv" (change)="uploadCV($event)">
      </div>
        <a href="javascript:void(0)" (click)="nextButtonFunction()" class="btn login first mt-3">Next</a>
        </div>

        <div class="parent-second-step d-flex align-items-start justify-content-between w-100 flex-wrap hide">
          <div class="w-100">
          <a href="javascript:void(0)" (click)="stepTwo()" class="first-back"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
          </div>
          <h6 class="w-100 mb-3 mt-3">COVID-19 Question Assessment Survey </h6>
        

        <div class="questions border rounded p-3">
        <div class="box d-flex align-items-center justify-content-between pb-3 border-bottom">
          <div class="content">
              <h6>Do  you have cough?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="cough_yes" name="ccough" formControlName="cough" value="yes" required="required">
              <label class="custom-control-label" for="cough_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="cough_no" name="ccough" formControlName="cough" value="no" required="required">
              <label class="custom-control-label" for="cough_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
        </div> 
        <!-- box -->
        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do  you have colds?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="colds_yes" name="ccold" formControlName="cold" value="yes">
              <label class="custom-control-label" for="colds_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="colds_no" name="ccold" formControlName="cold" value="no">
              <label class="custom-control-label" for="colds_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
          <!-- options -->
        </div> 
        <!-- box -->
        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you having Diarhea?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="diarhea_yes" name="ddiarhea" formControlName="diarhea" value="yes">
              <label class="custom-control-label" for="diarhea_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="diarhea_no" name="ddiarhea" formControlName="diarhea" value="no">
              <label class="custom-control-label" for="diarhea_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have sore throat?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="throat_yes" name="ssore_throat" formControlName="sore_throat" value="yes">
              <label class="custom-control-label" for="throat_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="throat_no" name="ssore_throat" formControlName="sore_throat" value="no">
              <label class="custom-control-label" for="throat_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
          <!-- options -->
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you experiencing MYLAGIA or Body Aches?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="mylagia_yes" name="mmylagia" formControlName="mylagia" value="yes">
              <label class="custom-control-label" for="mylagia_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="mylagia_no" name="mmylagia" formControlName="mylagia" value="no">
              <label class="custom-control-label" for="mylagia_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have a headache?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="headache_yes" name="hheadache" formControlName="headache" value="yes">
              <label class="custom-control-label" for="headache_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="headache_no" name="hheadache" formControlName="headache" value="no">
              <label class="custom-control-label" for="headache_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
          <!-- options -->
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have fever (Temperature 37.8 C and above)</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">

            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="fever_yes" name="ffever" formControlName="fever" value="yes">
              <label class="custom-control-label" for="fever_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="fever_no" name="ffever" formControlName="fever" value="no">
              <label class="custom-control-label" for="fever_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 

        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you having difficulty breathing?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="breathing_yes" name="bbreathing" formControlName="breathing" value="yes">
              <label class="custom-control-label" for="breathing_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="breathing_no" name="bbreathing" formControlName="breathing" value="no">
              <label class="custom-control-label" for="breathing_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
          <!-- options -->
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you experiencing Fatigue?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="fatigue_yes" name="ffatigue" formControlName="fatigue" value="yes">
              <label class="custom-control-label" for="fatigue_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="fatigue_no" name="ffatigue" formControlName="fatigue" value="no">
              <label class="custom-control-label" for="fatigue_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
          <!-- options -->
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Have you traveled recently during tha past 14 days?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="traveled_yes" name="ttravel14days" formControlName="travel14days" value="yes">
              <label class="custom-control-label" for="traveled_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="traveled_no" name="ttravel14days" formControlName="travel14days" value="no">
              <label class="custom-control-label" for="traveled_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have a travel history to a COVID-19 INFECTED AREA?</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="covid_yes" name="ttravelcovid" formControlName="travelcovid" value="yes">
              <label class="custom-control-label" for="covid_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="covid_no" name="ttravelcovid" formControlName="travelcovid" value="no">
              <label class="custom-control-label" for="covid_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
        </div> 
        <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have direct contact or is taking care of a positive COVID-19 PATIENT? (3 PTS)</h6>
          </div> 
          <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="takingc_yes" name="ccontactcovid" formControlName="contactcovid" value="yes">
              <label class="custom-control-label" for="takingc_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="takingc_no" name="ccontactcovid" formControlName="contactcovid" value="no">
              <label class="custom-control-label" for="takingc_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> 
          <!-- options -->
        </div> 
        <!-- box -->

      </div>

        <button class="btn login mt-3">Submit</button>
        </div>

      </form>
  </div>
</div>