<div class="inner-header d-flex align-items-center justify-content-between mb-4">
      <h3>Manage Teachers</h3>
</div>

    <!-- body -->
    <div class="card-body pt-0">
      <div class="mtable">
  <table datatable class="table">
    <thead>
    <tr>
      <th>No</th>
      <th>Image</th>
      <th>Teacher Name</th>
      <th>Status</th>
      <th>City</th>
      <th>Zipcode</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let teacher of teacherData.teachers; let i = index">
      <td>{{i + 1}}</td>
       <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{teacher.image}}"></td>
      <td>{{teacher.fname}} {{teacher.lname}}</td>
      <td>
        <ng-container *ngIf="teacher.approved == 1">
          <div class="alert alert-success d-inline-block" role="alert">Accepted</div>
        </ng-container>
        <ng-container *ngIf="teacher.approved == 2">
          <div class="alert alert-danger d-inline-block" role="alert">Rejected</div>
        </ng-container>
        <ng-container *ngIf="teacher.approved == 0">
          <div class="alert alert-primary d-inline-block" role="alert">Pending</div>
        </ng-container>
    </td>
      <td>{{teacher.city}}</td>
      <td>{{teacher.zipcode}}</td>
       
      <td>
        <a routerLink="/admin/teacher-detail/{{teacher.id}}" class="btn btn-primary">View</a>
      </td>
    </tr>
    
    
    </tbody>
  </table>
</div> <!--- mtable --->
</div> <!-- card-body -->