import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailvarification',
  templateUrl: './emailvarification.component.html',
  styleUrls: ['./emailvarification.component.css']
})
export class EmailvarificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
