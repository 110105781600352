import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) { }

  ngOnInit(): void {
    $('.loading-overlay').hide();
  }
  menuClick(event:any){
  	$('.clv-dasboard .main-sidebar .main-menu li a').removeClass('active');
  	$(event.target).addClass('active');
  }
  logoutData:any;
  logout(){
    $('.loading-overlay').show();
    // sessionStorage.removeItem('admin_email');
    // sessionStorage.removeItem('admin_password');
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

}
