import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-processing',
  templateUrl: './email-processing.component.html',
  styleUrls: ['./email-processing.component.css']
})
export class EmailProcessingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
