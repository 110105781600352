import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-parent-request-join-pod',
  templateUrl: './parent-request-join-pod.component.html',
  styleUrls: ['./parent-request-join-pod.component.css']
})
export class ParentRequestJoinPodComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) { }

  parentDetail: any;
  parent_id: any;
  ngOnInit(): void {
  	this.parent_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/podJoinRequestDetail/'+this.parent_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.parentDetail = data;
        }

      );
  }
  error:any;
  acceptRequest(){
  	this.parent_id=this.route.snapshot.params['id'];
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/podRequestResponse/'+this.parent_id+'/accept',{headers}).subscribe(
        data => {
          console.log(data);
          this.parentDetail = data;
          this.router.navigate(['/teacher/alerts']);
        },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }

      );
  }
  rejectRequest(){
  	this.parent_id=this.route.snapshot.params['id'];
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/podRequestResponse/'+this.parent_id+'/reject',{headers}).subscribe(
        data => {
          console.log(data);
          this.parentDetail = data;
          this.router.navigate(['/teacher/alerts']);
        }

      );
  }

}
