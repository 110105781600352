import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-select-student',
  templateUrl: './select-student.component.html',
  styleUrls: ['./select-student.component.css']
})
export class SelectStudentComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { }
  game_id:any;
  pod_id:any;
  ngOnInit(): void {
    this.game_id=this.route.snapshot.params['gid'];
    this.pod_id=this.route.snapshot.params['pid'];
  }
  selectStudent(e:any){
    $('.select-student-page .boxes .box').removeClass('active');
    $(e.target).addClass('active');
    $(this).attr('class','active');
    // this.render.setElementClass(event.target, "active", true);
  }

}
