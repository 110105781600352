import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-teacher-on-demands',
  templateUrl: './teacher-on-demands.component.html',
  styleUrls: ['./teacher-on-demands.component.css']
})
export class TeacherOnDemandsComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private shareurl: ApiService) { }
  teacherondemand:any;
  ngOnInit(): void {
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parentDashboard',{headers}).subscribe(
        data => {
          console.log(data);
          this.teacherondemand = data;
        }

      );
  }

}
