                  <div class="vpod-body">
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Pending POD Detail</h3>
                  </div>
                    <div class="vpod-body-inner pending-pod-detail">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="d-flex align-items-start justify-content-between flex-wrap">
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
                                <h6>Name</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].fname}} {{pendingPod.pod[0].lname}}'s POD</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Country</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].cname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>State</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].sname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>City</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].city}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Zip Code</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].zipcode}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Primary Speaking Language</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].pplname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Secondary Speaking Language</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].pslname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Grade</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].gname}}</p>
                              </div> <!-- div -->
                              <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
                                <h6>Learning Enviroment</h6>
                                <p class="text-muted mt-2">{{pendingPod.pod[0].lename}}</p>
                              </div> <!-- div -->

                            </div>
                        </div>
                        <div class="col-md-4">
                          <div class="right border rounded p-2">
                              <img class="mw-100 rounded" src="{{siteurl}}/{{pendingPod.pod[0].image}}">
                            </div>
                          <a href="javascript:void(0)" class="btn btn-primary btn-lg mt-3" data-toggle="modal" data-target="#joinpod" (click)="requestJoinPod()">
                            Request to join POD</a>
                        </div> <!-- col-md-4 -->
                      </div>
                        
                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->

                    <!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h5 class="text-success text-center"><i class="fa fa-check-circle" aria-hidden="true"></i> {{message.message}}</h5>
      </div>
    </div>
  </div>
</div>
<!-- model end -->