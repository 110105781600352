import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-teacher-request-join-pod',
  templateUrl: './teacher-request-join-pod.component.html',
  styleUrls: ['./teacher-request-join-pod.component.css']
})
export class TeacherRequestJoinPodComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) { }

  teacherDetail: any;
  teacher_id: any;
  ngOnInit(): void {
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/podJoinRequestDetail/'+this.teacher_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.teacherDetail = data;
        }

      );
  }
  acceptRequest(){
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/podRequestResponse/'+this.teacher_id+'/accept',{headers}).subscribe(
        data => {
          console.log(data);
          this.teacherDetail = data;
          this.router.navigate(['/parent/alerts']);
        }

      );
  }
  rejectRequest(){
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/podRequestResponse/'+this.teacher_id+'/reject',{headers}).subscribe(
        data => {
          console.log(data);
          this.teacherDetail = data;
          this.router.navigate(['/parent/alerts']);
        }

      );
  }

}
