import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-on-demands',
  templateUrl: './teacher-on-demands.component.html',
  styleUrls: ['./teacher-on-demands.component.css']
})
export class TeacherOnDemandsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
