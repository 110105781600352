<!-- body -->
<div class="vpod-body">
<div class="vpod-nav">
<ul>
    <li><a routerLink="/parent/pod-detail/{{pod_id}}">Home</a></li>
    <li><a routerLink="/parent/assignments/{{pod_id}}" class="active">Assignments</a></li>
    <li><a routerLink="/parent/notes/{{pod_id}}">Notes</a></li>
    <li><a routerLink="/parent/quiz/{{pod_id}}">Quiz</a></li>
    <li><a routerLink="/parent/team-preparation/{{pod_id}}">Team Preparation</a></li>
</ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
<h3>{{submissionDetail.submission.pname}}</h3>
</div>

<div class="vpod-body-inner">
<div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

<div class="left">
  <div class="border rounded p-3 alert-secondary">
    <span class="d-block w-100">Student Name:</span>
    <p class="h6 mt-2 mb-0">{{submissionDetail.submission.fname}} {{submissionDetail.submission.lname}}</p>
  </div>

  <div class="border rounded p-3 alert-secondary mt-2">
    <span class="d-block w-100 mb-2">Description:</span>
    <p>{{submissionDetail.submission.description}}</p>
  </div>

  <button class="btn btn-primary mb-3 mt-3" (click)="resubmissionform()">Resubmission</button>
  <div class="upload-assignment w-100 mt-2">
    <h4 class="mb-3">Resubmission</h4>
        <form [formGroup]="form" autocomplete="off" (ngSubmit)="uploadAssignment()">
          <textarea class="form-controll w-100 mb-2" name="title" placeholder="Description" formControlName="description"></textarea>
          <input class="form-controll w-100 mb-2" type="file" name="file" (change)="asImage($event)" multiple>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
  </div> <!-- upload-assignment -->
   
  </div>
  <div class="right border rounded p-2">
      <h3>Files</h3>
      <div class="file-box mt-3 alert alert-secondary" *ngFor="let asfile of submissionDetail.submission.file">
          <a class="d-block mb-3" href="{{siteurl}}/{{asfile}}" download="{{siteurl}}/{{asfile}}">
          <i class="fa fa-file" aria-hidden="true"></i> 
          {{submissionDetail.submission.fname}} {{submissionDetail.submission.lname}}
        </a>
        <a type="application/octet-stream" class="btn btn-primary d-inline-block mr-1" href="{{siteurl}}/{{asfile}}" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

        <a class="btn btn-success d-inline-block" href="{{siteurl}}/{{asfile}}" download="{{siteurl}}/{{asfile}}"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
      </div> 
      <!-- file-box -->
  </div>
</div> <!-- box -->


</div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->

              <p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  