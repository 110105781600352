<div class="vpod-body">
  <a routerLink="/admin/manage-students"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
  <div class="vpod-body-inner mt-3">
    <h1 class="mb-2 sound_bubble_demoregular">Banner Bruce</h1>
      
  <div class="box mt-2 view-submission d-flex align-items-start justify-content-between">

        <div class="left">
          <div class="d-flex align-items-start justify-content-between flex-wrap mobile-fullw">
            <div class="dviewbox border rounded p-3 mb-2">
              <h6>POD</h6>
              <p class="text-muted mt-2"><a routerLink="#">Science</a></p>
            </div> <!-- div -->
            <div class="dviewbox border rounded p-3 mb-2">
              <h6>Roll Number</h6>
              <p class="text-muted mt-2">09887</p>
            </div> <!-- div -->
            <div class="dviewbox border rounded p-3 mb-2">
              <h6>Parent Name</h6>
              <p class="text-muted mt-2">John Doe</p>
            </div> <!-- div -->
            <div class="dviewbox border rounded p-3 mb-2">
              <h6>Requirements</h6>
              <p class="text-muted mt-2">Bigger size of Desk</p>
              <p class="text-muted mt-2">Comfortable seat</p>
              <p class="text-muted mt-2">Low price</p>
              <p class="text-muted mt-2">Adjuustable seat</p>
            </div> <!-- div -->

          </div>


          <!-- <div class="attendnce mt-4">
            <h2 class="mb-2 sound_bubble_demoregular">Attendence</h2>
            <div>
            <canvas id="atttendencepiechart"></canvas>
            </div>
          </div> -->

          </div>
          <div class="right border rounded">
            
            <div class="border rounded p-2">
               <h6 class="mb-2">Student Image</h6>
              <img class="mw-100 rounded" src="assets/img/cat.jpg">
            </div>
           



          </div> <!-- right -->
        </div>
    

  </div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->