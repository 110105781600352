import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-processing',
  templateUrl: './teacher-processing.component.html',
  styleUrls: ['./teacher-processing.component.css']
})
export class TeacherProcessingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
