import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-teacher-detail',
  templateUrl: './teacher-detail.component.html',
  styleUrls: ['./teacher-detail.component.css']
})
export class TeacherDetailComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) {
      this.form = this.fb.group({
        podID: [''],
      });

     }

  teacherDetail: any;
  teacher_id: any;
  ngOnInit(): void {
    $('.loading-overlay').hide();
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/viewTeacher/'+this.teacher_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.teacherDetail = data;
        }

      );
  }

  message: any;
  error:any;
  submitForm() {
    $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("pod_id", this.form.value.podID);
    formData.append("teacher_id", this.route.snapshot.params['id']);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/parent/parentRequestTeacherPod', formData, {headers}).subscribe(
      response => {
        $('.loading-overlay').hide();
        console.log(response);
        this.message = response;
        $('.form_status').empty().append('<h5 class="text-success text-center"><i class="fa fa-check-circle" aria-hidden="true"></i> '+this.message.message+'</h5>');
        $('.autoclick_show_modal').click();
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
      // error => {
      //   // console.log(error);
      //   this.error = error;
      //   console.log(this.error);
      //   $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.errors[0]+'</h5>');
      //   $('.autoclick_show_modal').click();
      // },
    )
  }

}
