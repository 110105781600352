<div class="inner-header d-flex align-items-center justify-content-between mb-4">
        <h3>Manage Parents</h3>
  </div>

  <!-- body -->
  <div class="card-body pt-0">
    <div class="mtable">
<table datatable class="table">
  <thead>
  <tr>
    <th>No</th>
    <th>Image</th>
    <th>Parent Name</th>
    <th>City</th>
    <th>Zipcode</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let parent of parentData.parents; let i = index">
    <td>{{ i + 1 }}</td>
     
    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{parent.image}}"></td>
    <td>{{parent.fname}} {{parent.lname}}</td>
    <td>{{parent.city}}</td>
    <td>{{parent.zipcode}}</td>
    <td>
      <a routerLink="/admin/parent-detail/{{parent.id}}" class="btn btn-primary">View</a>
    </td>
  </tr>
          
  </tbody>
</table>
</div> <!-- mtable -->
</div> <!-- card-body -->