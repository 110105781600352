<div class="clv-dasboard">
<app-teacher-sidebar></app-teacher-sidebar>

<div class="dashboard-right-section">
	<!-- <div class="right-inner"> -->
	<p class="m-0 w-100 text-white text-center p-2 version-alert" style="background:#b23885;">This is Beta Version and doesn't Represent the final Quality.</p>
<!-- </div> -->
<div class="dashboard-profilebar">
	<div class="open-menu">
		<div class="opm"></div>
			<span></span>
			<span></span>
			<span></span>
		</div> <!-- open-menu -->
		<div class="dpinner">
	<a routerLink="alerts"><i class="fa fa-bell"></i></a>
	<div class="dinner" (click)="profileOpen()">
		<div class="dremoveClass"></div>
		<div class="img">
			<img src="{{siteurl}}/{{simage}}">
		</div> <!-- img -->
		<h2>{{first_name}}</h2>
		<div class="dinnerBox">
		<a routerLink="#" class="dinnerProfile">
			<div class="img">
				<img src="{{siteurl}}/{{simage}}">
			</div> <!-- img -->
			<div class="content">
				<h2>{{first_name}} {{last_name}}</h2>
				<p>See your profile</p>
			</div> <!-- content -->
		</a> <!-- dinnerProfile -->
		<ul>
			<li><a routerLink="#">Setting</a></li>
			<li><a routerLink="#" (click)="logout()">Logout</a></li>
		</ul>
	</div> <!-- dinnerBox -->
	</div> <!-- dinner -->
</div> <!-- div -->
	
</div> <!-- dasshboard-profilebar -->

<div class="right-inner p-4">
<!-- body -->
<router-outlet></router-outlet>
<ng-container *ngIf="router.url == '/teacher'">
	<app-teacher-home></app-teacher-home>
</ng-container>

</div>
</div>
</div>