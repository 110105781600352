export class Quiz {
	constructor(
	public name: any,
    public salary: any,
    public age: any,
    public id: any,
		){

	}
	
}
