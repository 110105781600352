import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ParentSidebarComponent } from './parent-sidebar/parent-sidebar.component';
import { ParentComponent } from './parent.component';
import { ManageStudentComponent } from './manage-student/manage-student.component';
import { TeacherDetailComponent } from './teacher-detail/teacher-detail.component';
import { PendingPodDetailComponent } from './pending-pod-detail/pending-pod-detail.component';
import { AlertsComponent } from './alerts/alerts.component';
import { TeacherRequestJoinPodComponent } from './teacher-request-join-pod/teacher-request-join-pod.component';
import { PodsComponent } from './pods/pods.component';
import { PodDetailComponent } from './pod-detail/pod-detail.component';
import { AssignmentDetailComponent } from './assignment-detail/assignment-detail.component';
import { NoteDetailComponent } from './note-detail/note-detail.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { NotesComponent } from './notes/notes.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizDetailComponent } from './quiz-detail/quiz-detail.component';
import { TeamPreparationComponent } from './team-preparation/team-preparation.component';
import { AddStudentComponent } from './add-student/add-student.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { ViewSubmissionComponent } from './view-submission/view-submission.component';
import { GamificationComponent } from './gamification/gamification.component';
import { SelectStudentComponent } from './select-student/select-student.component';
import { SelectedStudentPreviewComponent } from './selected-student-preview/selected-student-preview.component';
import { GameBigSmallComponent } from './game-big-small/game-big-small.component';
import { GameResultComponent } from './game-result/game-result.component';
import { TeacherOnDemandsComponent } from './teacher-on-demands/teacher-on-demands.component';
import { ChsComponent } from './chs/chs.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';

const routes: Routes = [
	
	{
    path: 'parent', component: ParentComponent,
    children: [
    	{
	        path:'manage-student',
	        component:ManageStudentComponent
	     },
       {
          path:'teacher-detail/:id',
          component:TeacherDetailComponent
       },
       {
          path:'pending-pod-detail/:id',
          component:PendingPodDetailComponent
       },
       {
          path:'alerts',
          component:AlertsComponent
       },
       {
          path:'teacher-request-detail/:id',
          component:TeacherRequestJoinPodComponent
       },
       {
          path:'pods',
          component:PodsComponent
       },
       {
          path:'pod-detail/:id',
          component:PodDetailComponent
       },
       {
          path:'assignment-detail/:id/:aid',
          component:AssignmentDetailComponent
       },
       {
          path:'note-detail/:id/:nid',
          component:NoteDetailComponent
       },
       {
          path:'assignments/:id',
          component:AssignmentsComponent
       },
       {
          path:'notes/:id',
          component:NotesComponent
       },
       {
          path:'quiz/:id',
          component:QuizComponent
       },
       {
          path:'quiz-detail/:id/:qid',
          component:QuizDetailComponent
       },
       {
          path:'team-preparation/:id',
          component:TeamPreparationComponent
       },
       {
          path:'add-student',
          component:AddStudentComponent
       },
       {
          path:'student-detail/:id',
          component:StudentDetailComponent
       },
       {
          path:'submission-detail/:id/:sid',
          component:ViewSubmissionComponent
       },
       {
          path:'gamification',
          component:GamificationComponent
       },
       {
          path:'select-student/:gid/:pid',
          component:SelectStudentComponent
       },
       {
          path:'select-student-preview/:gid/:sid',
          component:SelectedStudentPreviewComponent
       },
       {
          path:'select-right-answer/:gid/:sid',
          component:GameBigSmallComponent
       },
       {
          path:'game-result/:gid/:sid',
          component:GameResultComponent
       },
       {
          path:'teacher-demands',
          component:TeacherOnDemandsComponent
       },
       {
          path:'chs',
          component:ChsComponent
       },
       {
          path:'create-group',
          component:CreateGroupComponent
       },
       {
          path:'view-group/:id',
          component:ViewGroupComponent
       }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParentRoutingModule { }
