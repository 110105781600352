import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'clv';
  constructor(
    private http: HttpClient,
    public router: Router){}
  bannerslides = [
    {img: "assets/img/b1.png"},
    {img: "assets/img/b2.png"},
    {img: "assets/img/box-girl.png"}
  ];
  slideConfig = {
  "slidesToShow": 1, 
  "slidesToScroll": 1,
  "dots": false,
  "infinite": true,
  "arrows": false,
  "fade": true,
  "pauseOnHover": true,
  }
  beforeChange(){
    var nextSlide = '1';
    $('.banner-section').removeClass('banner'+nextSlide);
    $('.banner-section').addClass('banner'+nextSlide);
  }
 ngOnInit(): void {
   
  }
}
