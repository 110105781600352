<!-- body -->
<div class="vpod-body">
<div class="vpod-nav">
<ul>
    <li><a routerLink="/parent/pod-detail/{{pod_id}}">Home</a></li>
    <li><a routerLink="/parent/assignments/{{pod_id}}" class="active">Assignments</a></li>
    <li><a routerLink="/parent/notes/{{pod_id}}">Notes</a></li>
    <li><a routerLink="/parent/quiz/{{pod_id}}">Quiz</a></li>
    <li><a routerLink="/parent/team-preparation/{{pod_id}}">Team Preparation</a></li>
</ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3>{{assignmentData.name}}</h3>
</div>
<div class="vpod-body-inner">
<div class="card-body pt-0">
      <div class="mtable">
      <table datatable class="table">
        <thead style="background:#EFEFEF;border-color:#E2E2E2;">
        <tr>
          <th>Assignment Numbers</th>
          <th>Assignment Title</th>
          <th>Asigned on</th>
          <th>Last Date</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let assignment of assignmentData.assignments;let i = index">
          <td>{{i + 1}}</td>
          <td>{{assignment.title}}</td>
          <td>{{assignment.assigned_on}}</td>
          <td>{{assignment.last}}</td>
          <td>
            <a routerLink="/parent/assignment-detail/{{pod_id}}/{{assignment.id}}" class="btn btn-primary">View Detail</a>  
          </td>
        </tr>
        </tbody>
      </table>
    </div> <!-- mtable -->

</div> <!-- card-body -->

      
    </div> <!-- vpod-body-inner -->

  </div> <!-- vpod-body --->