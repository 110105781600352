
<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}" class="active">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">Science</h3>
</div>
	<div class="vpod-body-inner">
          <div class="card-body pt-0">
            <div class="mtable">
               <table datatable class="table">
                  <thead>
                  <tr>
                    <th>Roll Number</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Father name</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="../assets/img/cat.jpg"></td>
                    <td>John Doe</td>
                    <td>Petter Parker</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/student-detail/{{pod_id}}/123" class="btn btn-primary">View Profile</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!-- mtable -->
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->