
<div class="vpod-body">
  <div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}" class="active">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">Science</h3>
</div>
	<div class="vpod-body-inner">
          <div class="card-body pt-0">
                <div class="fs-container mtable">
      <div class="editor-menu d-flex align-items-center mb-3">
        <div class="editor-dropdown position-relative">
          <span class="editor-dropdown-open"><i class="fa fa-bars" aria-hidden="true"></i></span>
          <div class="inner-menu">
          <a routerLink="javascript:void(0);" class='tool' id="tool-select" title="Select"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> Select</a>
        <a routerLink="javascript:void(0);" class='tool' id="undo-lc" title="Undo"><i class="fa fa-undo" aria-hidden="true"></i> Undo</a>
        <a routerLink="javascript:void(0);" class='tool' id="redo-lc" title="Redo"><i class="fa fa-repeat" aria-hidden="true"></i> Redo</a>
        <a routerLink="javascript:void(0);" class='tool' id="clear-lc">Clear</a>
        </div>
        </div> <!-- editor-drop -->
        <div class="inner">
        <a routerLink="javascript:void(0);" class='tool' id="open-image" title="Open image"><i class="fa fa-picture-o" aria-hidden="true"></i></a>

        <a routerLink="javascript:void(0);" class='tool' id="tool-eraser" title="Eraser"><i class="fa fa-eraser" aria-hidden="true"></i></a>

        <a routerLink="javascript:void(0);" class='tool' id="tool-text" title="text"><i class="fa fa-text-width" aria-hidden="true"></i></a>

        <a routerLink="javascript:void(0);" class='tool' id="tool-pencil" title="Pencil"><i class="fa fa-pencil" aria-hidden="true"></i></a>

        <a routerLink="javascript:void(0);" class='tool' id="tool-line" title="Line"><i class="fa fa-line-chart" aria-hidden="true"></i></a>
        <a routerLink="javascript:void(0);" class='tool' id="tool-arrow" title="arrow"><i class="fa fa-arrows" aria-hidden="true"></i></a>
        <a routerLink="javascript:void(0);" class='tool' id="tool-dashed" title="Dashed Line"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
        <a routerLink="javascript:void(0);" class='tool' id="tool-ellipse" title="Circle"><i class="fa fa-circle-thin" aria-hidden="true"></i></a>
        <a routerLink="javascript:void(0);" class='tool' id="tool-rectangle" title="Rectangle"><i class="fa fa-square-o" aria-hidden="true"></i></a>
        <a routerLink="javascript:void(0);" class='tool' id="tool-polygon" title="Shapes"><i class="fa fa-play" aria-hidden="true"></i></a>

        <div class="color d-flex align-items-center mt-2">
          <div class="custom-control custom-radio tool mr-2" id="colorTool-black">
            <input type="radio" class="custom-control-input" id="black" name="color">
            <label class="custom-control-label" for="black">Black</label>
          </div>
          <div class="custom-control custom-radio tool mr-2" id="colorTool-blue">
            <input type="radio" class="custom-control-input" id="blue" name="color">
            <label class="custom-control-label" for="blue">Blue</label>
          </div>
          <div class="custom-control custom-radio tool" id="colorTool-red">
            <input type="radio" class="custom-control-input" id="red" name="color">
            <label class="custom-control-label" for="red">Red</label>
          </div>
        </div>  
      </div> <!-- inner -->
      </div> <!-- editor-menu -->

      <div class="toolset">
        
        <!-- <a routerLink="javascript:void(0);" class='tool' id="change-size">Change size</a>
        <a routerLink="javascript:void(0);" class='tool' id="reset-size">Reset size</a> -->
        <!-- <a routerLink="javascript:void(0);" class='tool' id="hide-lc">Teardown</a>
        <a routerLink="javascript:void(0);" class='tool' id="show-lc">Setup</a> -->
        
      </div>


<div class="literally"></div>
    </div>
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->