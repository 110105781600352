import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-pod-detail',
  templateUrl: './pod-detail.component.html',
  styleUrls: ['./pod-detail.component.css']
})
export class PodDetailComponent implements OnInit {
  siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) {
     }

  startBroadcasting(){
    $('.allow-broadcast').addClass('hide');
    $('.allow-broadcast.start').addClass('show');
  }
   menuClick(){
      $('.vpod-body .vpod-nav ul li a').removeClass('active');
      $(this).addClass('active');
  }

  podDetail: any;
  pod_id: any;
  ngOnInit(): void {
    $('.loading-overlay').hide();
  	this.pod_id=this.route.snapshot.params['id'];

    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/podName/'+this.pod_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.podDetail = data;
        }

      );
  	
  }

}
