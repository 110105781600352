import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from "../../api.service";
import * as $ from 'jquery';
@Component({
  selector: 'app-parentprofile',
  templateUrl: './parentprofile.component.html',
  styleUrls: ['./parentprofile.component.css']
})
export class ParentprofileComponent implements OnInit {
form: FormGroup;
submitted = false;
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
private router: Router,
public fb: FormBuilder,
private shareurl: ApiService
    ) { 
    var role_nmbr = sessionStorage.getItem('prole');
    if (!sessionStorage.getItem('token')) {
          this.router.navigate(['signin']);
      }else if(role_nmbr != '1'){
        this.router.navigate(['signin']);
      }else if(role_nmbr == '1'){
        $('header.site-header nav ul li.plogout').addClass('d-none');
        $('header.site-header nav ul li.tlogout').removeClass('d-none');
      }
      this.form = this.fb.group({
        fname: [''],
        lname: [''],
        country_id: [''],
        state_id: [''],
        city: [''],
        address: [''],
        zipcode: [''],
        ppsl: [''],
        pssl: [''],
        caregiver_id: [''],
        ple: [''],
        parent: [''],
        cough: [''],
        cold: [''],
        diarhea: [''],
        sore_throat: [''],
        mylagia: [''],
        headache: [''],
        fever: [''],
        breathing: [''],
        fatigue: [''],
        travel14days: [''],
        travelcovid: [''],
        contactcovid: [''],
        grade_id: [''],
      });
   }


  parent_signup(input:any) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
              reader.onload = function(e:any) {
                  $('#parent_signup_image').css('background-image', 'url('+e.target.result +')');
                  $('#parent_signup_image').hide();
                  $('#parent_signup_image').fadeIn(650);
              }
              reader.readAsDataURL(input.files[0]);
          }
      }
    parentSignup(a:any) {
      var myImg = <HTMLInputElement>document.getElementById('parent_signup');
      myImg.src = a;
    }
    child_student(input:any) {

    if (input.files && input.files[0]) {
        var reader = new FileReader();
              reader.onload = function(e:any) {
                  $('#child_student_signup_image').css('background-image', 'url('+e.target.result +')');
                  $('#child_student_signup_image').hide();
                  $('#child_student_signup_image').fadeIn(650);
              }
              reader.readAsDataURL(input.files[0]);
          }
      }
    childStudentSignup(a:any) {
      var myImg = <HTMLInputElement>document.getElementById('child_student_signup');
      myImg.src = a;
    }
    stepOpne(){
    $('.parent-first-step').addClass('hide');
      $('.parent-third-step').removeClass('hide');
    }
    thirdstep(){
      $('.parent-second-step').addClass('hide');
      $('.parent-third-step').removeClass('hide');
    }
    fourstep(){
      $('.parent-first-step').removeClass('hide');
      $('.parent-third-step').addClass('hide');
    }
    statess: any;
    changecountry(){
      // $('.select_state option').empty();
        var country_id = $(".country_select option:selected").attr('value');
        var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
        $.ajax({
          type: "Get",
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Bearer '+sessionStorage.getItem('token')+'');
          },
          url: ''+this.siteurl+'/getStates/'+country_id+'',
          // data: "json=" + escape(JSON(createRequestObject)),
          processData: false,
          success: function(data) {
            // $("#results").append("The result =" + (msg));
            // console.log(data.states);
            $(".select_state").empty();
            this.statess = data.states;
            for (var i = this.statess.length - 1; i >= 0; i--) {
              $(".select_state").append('<option value="'+this.statess[i].id+'">'+this.statess[i].name+'</option>');
              // console.log(this.statess[i].name);
            }

          }
    });
    }
// createForm(){
//       this.form = this.fb.group({
//         image:[null, Validators.required]
//       })
//     }
    parentcompleteprofile: any;
    errors:any;
  ngOnInit(): void {
    
    $('.loading-overlay').show();
var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/parentFormData', { headers }).subscribe(
        data => {
          console.log(data);
          this.parentcompleteprofile = data;
          $('.first_name').attr({'value': this.parentcompleteprofile.user.fname});
          $('.first_name').val(this.parentcompleteprofile.user.fname);
          $('.last_name').val(this.parentcompleteprofile.user.lname);
          for (var i = this.parentcompleteprofile.countries.length - 1; i >= 0; i--) {
              $(".country_select").append('<option value="'+this.parentcompleteprofile.countries[i].id+'">'+this.parentcompleteprofile.countries[i].name+'</option>');
            }
            for (var p = this.parentcompleteprofile.PodPrimaryLangs.length - 1; p >= 0; p--) {
              $(".select_ppl").append('<option value="'+this.parentcompleteprofile.PodPrimaryLangs[p].id+'">'+this.parentcompleteprofile.PodPrimaryLangs[p].name+'</option>');
            }
            for (var pssl = this.parentcompleteprofile.PodSecondaryLangs.length - 1; pssl >= 0; pssl--) {
              $(".select_pssl").append('<option value="'+this.parentcompleteprofile.PodSecondaryLangs[pssl].id+'">'+this.parentcompleteprofile.PodSecondaryLangs[pssl].name+'</option>');
            }
            for (var carneeds = this.parentcompleteprofile.CaregiverTypes.length - 1; carneeds >= 0; carneeds--) {
              $(".carneeds").append('<option value="'+this.parentcompleteprofile.CaregiverTypes[carneeds].id+'">'+this.parentcompleteprofile.CaregiverTypes[carneeds].name+'</option>');
            }
            for (var ple = this.parentcompleteprofile.LearningEnvironments.length - 1; ple >= 0; ple--) {
              $(".ple").append('<option value="'+this.parentcompleteprofile.LearningEnvironments[ple].id+'">'+this.parentcompleteprofile.LearningEnvironments[ple].name+'</option>');
            }
            for (var grade = this.parentcompleteprofile.grades.length - 1; grade >= 0; grade--) {
              $(".grade").append('<option value="'+this.parentcompleteprofile.grades[grade].id+'">'+this.parentcompleteprofile.grades[grade].name+'</option>');
            }
        $('.loading-overlay').hide();
        }

      );

    


    // $('span.collapse-student').click(function(){
    //   $(this).toggleClass('active');
    //     $(this).parent().parent().parent().children('.student-inner').slideToggle('active');
    // });
    // $(document).on('click', '.add-student', function (ev) {
    //   var $clone = $('#a_clone').clone(true);
    //   var $newbuttons = "<span class='remove-student'><i class='fa fa-times' aria-hidden='true'></i></span><span class='collapse-student'></span>";
    //   $clone.find('.head-student').parent().parent().html($newbuttons).end().appendTo($('#new_student'));
    // });

    // $(document).on('click', '.remove-student', function () {
    //   $(this).parent().parent().parent().remove();
    // });



  //   $(".country_select").on("change", function() {
      
    
  // });
  }
  get f(){
    return this.form.controls;
  }
message: any;
  submitForm() {
    $('.loading-overlay').show();
      var formData: any = new FormData();
    formData.append("fname", this.form.value.fname);
    formData.append("lname", this.form.value.lname);
    formData.append("country_id", this.form.value.country_id);
    formData.append("state_id", this.form.value.state_id);
    formData.append("city", this.form.value.city);
    formData.append("address", this.form.value.address);
    formData.append("zipcode", this.form.value.zipcode);
    formData.append("ppsl", this.form.value.ppsl);
    formData.append("pssl", this.form.value.pssl);
    formData.append("caregiver_id", this.form.value.caregiver_id);
    formData.append("ple", this.form.value.ple);
    formData.append("parent", this.form.value.parent);
    formData.append("cough", this.form.value.cough);
    formData.append("cold", this.form.value.cold);
    formData.append("diarhea", this.form.value.diarhea);
    formData.append("sore_throat", this.form.value.sore_throat);
    formData.append("mylagia", this.form.value.mylagia);
    formData.append("headache", this.form.value.headache);
    formData.append("fever", this.form.value.fever);
    formData.append("breathing", this.form.value.breathing);
    formData.append("fatigue", this.form.value.fatigue);
    formData.append("travel14days", this.form.value.travel14days);
    formData.append("travelcovid", this.form.value.travelcovid);
    formData.append("contactcovid", this.form.value.contactcovid);
    formData.append("grade_id", this.form.value.grade_id);


    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/saveParentAndPod', formData, {headers}).subscribe(
      response => {
        console.log(response)
        this.message = response;
        if (this.message.message == 'success') {
          sessionStorage.setItem('role', '1');
          // sessionStorage.setItem('token', this.message.token);
            this.router.navigate(['/parent']);
            $('.loading-overlay').hide();
        }

      },
      (error) => {
        $("html, body").animate({ scrollTop: 0 }, 600);
          console.log(error.name);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          // console.log(this.errors);
          $('.errors').append('<li>'+this.errors[0]+'</li>');
          // for (let i = 0; i < this.errors.length; i++) {
          //     $('.errors').append('<li>'+this.errors[i]+'</li>');
          //   };
            $('.loading-overlay').hide();
      },
    );
    
  }

  nmessage: any;
  nextParent() {
    $('.loading-overlay').show();
      var formData: any = new FormData();
    formData.append("fname", this.form.value.fname);
    formData.append("lname", this.form.value.lname);
    formData.append("country_id", this.form.value.country_id);
    formData.append("state_id", this.form.value.state_id);
    formData.append("city", this.form.value.city);
    formData.append("address", this.form.value.address);
    formData.append("zipcode", this.form.value.zipcode);
    formData.append("ppsl", this.form.value.ppsl);
    formData.append("pssl", this.form.value.pssl);
    formData.append("caregiver_id", this.form.value.caregiver_id);
    formData.append("ple", this.form.value.ple);
    formData.append("parent", this.form.value.parent);
    formData.append("grade_id", this.form.value.grade_id);


    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/validationForParent', formData, {headers}).subscribe(
      response => {
        console.log(response)
        this.nmessage = response;
        if (this.nmessage.message == 'Success') {
            // this.router.navigate(['/parent']);
            $('.loading-overlay').hide();
            $('.errors').empty();
            $('.parent-first-step').addClass('hide');
            $('.parent-third-step').removeClass('hide');
        }

      },
      (error) => {
        $("html, body").animate({ scrollTop: 0 }, 600);
          console.log(error.name);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          // console.log(this.errors);
          $('.errors').append('<li>'+this.errors[0]+'</li>');
          // for (let i = 0; i < this.errors.length; i++) {
          //     $('.errors').append('<li>'+this.errors[i]+'</li>');
          //   };
            $('.loading-overlay').hide();
      },
    );
    
  }
  
  
}
