import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {
student: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) {
  	this.student = this.fb.group({
        fname: [''],
        lname: [''],
        learningEnviroment: [''],
        teachingskill: [''],
        grade: [''],
        subject: [''],
        email: [''],
        dob: [''],
        gender: [''],
        password: [''],
        confirm_password: [''],
      });
     }
     studentDrops: any;
  ngOnInit(): void {
    var today = new Date().toISOString().split('T')[0];
    $(".dob input")[0].setAttribute('max', today);

    $('.loading-overlay').hide();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/studentSignupData',{headers}).subscribe(
        data => {
          console.log(data);
          this.studentDrops = data;
          for (var i = this.studentDrops.LearningEnvironments.length - 1; i >= 0; i--) {
            $('.learning_style').append('<option value="'+this.studentDrops.LearningEnvironments[i].id+'">'+this.studentDrops.LearningEnvironments[i].name+'</oprion>');
          }
          for (var g = this.studentDrops.grades.length - 1; g >= 0; g--) {
            $('.grades').append('<option value="'+this.studentDrops.grades[g].id+'">'+this.studentDrops.grades[g].name+'</oprion>');
          }
          for (var s = this.studentDrops.subjects.length - 1; s >= 0; s--) {
            $('.subjects').append('<option value="'+this.studentDrops.subjects[s].id+'">'+this.studentDrops.subjects[s].title+'</oprion>');
          }
          for (var t = this.studentDrops.teachingSkill.length - 1; t >= 0; t--) {
            if(this.studentDrops.teachingSkill[t].id != 16){
              $('.teachingskill').append('<option value="'+this.studentDrops.teachingSkill[t].id+'">'+this.studentDrops.teachingSkill[t].name+'</oprion>');
            }
            
          }
        }

      );
  }
  files:any;
  fileName:any;
  uploadImage(event:any){
    this.fileName = event.target.files[0].name;
    this.files = event.target.files[0];
    console.log(this.files);
  }
  studentData: any;
  message: any;
  error:any;
  addStudent(){
  	if (this.files) {
      $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("fname", this.student.value.fname);
    formData.append("lname", this.student.value.lname);
    formData.append("dob", this.student.value.dob);
    formData.append("learning_environment_id", this.student.value.learningEnviroment);
    formData.append("teachingSkill", this.student.value.teachingskill);
    formData.append("grade_id", this.student.value.grade);
    formData.append("subject", this.student.value.subject);
    formData.append("gender", this.student.value.gender);
    formData.append("email", this.student.value.email);
    formData.append("password", this.student.value.password);
    formData.append("password_confirmation", this.student.value.confirm_password);

    formData.append("image", this.files, this.files.name);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/parent/saveStudent', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
          // this.router.navigate(['/parent/manage-student']);
          $('.form_status').empty().append('<h5 class="text-success text-center">An Email Verification Link has been set to the email, Please verify the entered email <br><a href="/parent/manage-student"><i class="fa fa-caret-left" aria-hidden="true"></i> Go back</a></h5>');
          $('.autoclick_show_modal').click();
        }
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> Please Upload Student Image</h5>');
          $('.autoclick_show_modal').click();
    }
  }

}
