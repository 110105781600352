import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-note-detail',
  templateUrl: './note-detail.component.html',
  styleUrls: ['./note-detail.component.css']
})
export class NoteDetailComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) { }

  noteDetail:any;
  pod_id: any;
  note_id: any;
  ngOnInit(): void {
    this.pod_id=this.route.snapshot.params['id'];
  	this.note_id=this.route.snapshot.params['nid'];
    $('.loading-overlay').hide();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/viewNotes/'+this.note_id+'', {headers}).subscribe(
        data => {
          console.log(data);
          this.noteDetail = data;
        }

      );
  }
  noteDelete:any;
  deleteNote(){
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/deleteNotes/'+this.note_id+'',{headers}).subscribe(
        data => {
          
          console.log(data);
          this.noteDelete = data;
          if (this.noteDelete.message == 'success') {
            $('.loading-overlay').hide();
            this.router.navigate(['/teacher/notes/'+this.pod_id+'']);
          }
        }

      );
  }

}
