import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) {
  		this.form = this.fb.group({
        category: [''],
        serie: [''],
        title: [''],
        tags: [''],
        description: [''],
        file: ['']
      });
  	 }
  pod_id: any;
  noteData: any;
  ngOnInit(): void {
  	var dtToday = new Date();
    var month1 = '';
    var day1 = '';
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if(month < 10){
        month1 = '0' + month.toString();
        }
    if(day < 10){
        day1 = '0' + day.toString();
        }
    
    var maxDate = year + '-' + month1 + '-' + day1;
    $('input[type="date"]').attr('min', maxDate);
    this.pod_id=this.route.snapshot.params['id'];
    $('.loading-overlay').hide();
    var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/dataForNotes',{headers}).subscribe(
        data => {
          console.log(data);
          this.noteData = data;
        }

      );
  }
  files:any;
  fileName:any;
  uploadImage(event:any){
    this.fileName = event.target.files[0].name;
    this.files = event.target.files[0];
    console.log(this.files);
  }
  message: any;
  error:any;
  addNote() {
    $('.loading-overlay').show();
    if (this.files) {
      var formData: any = new FormData();
    formData.append("serie", this.form.value.serie);
    formData.append("category", this.form.value.category);
    formData.append("title", this.form.value.title);
    formData.append("description", this.form.value.description);
    formData.append("tags", this.form.value.tags);
    formData.append("pod_id", this.pod_id);
    formData.append("file", this.files, this.files.name);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/teacher/saveNotes', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
        	this.router.navigate(['/teacher/notes/'+this.pod_id+'']);
          // $('.form_status').empty().append('<h5 class="text-success text-center"><i class="fa fa-check" aria-hidden="true"></i> Note Add Successfully!</h5>');
          // $('.autoclick_show_modal').click();
        }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.loading-overlay').hide();
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> File is required</h5>');
      $('.autoclick_show_modal').click();
    }
  }

}
