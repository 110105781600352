import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService
    ) {
if (this.route.snapshot.params['id'] !== 'ok' && !this.route.snapshot.params['token']) {
      this.router.navigate(['']);
  }
  var role_nmbr = sessionStorage.getItem('role');
    if (sessionStorage.getItem('token') && role_nmbr == '1') {
          this.router.navigate(['parent']);
    }else if(sessionStorage.getItem('token') && role_nmbr == '2'){
      this.router.navigate(['teacher']);
    };

  	this.form = this.fb.group({
        password: [''],
	      confirm_password: [''],
	    });

     }

  ngOnInit(): void {
  	$('.loading-overlay').hide();
  }
  errors:any;
  Global:any;
  submitForm() {
    $('.loading-overlay').show();
    // var ntoken = '';
    // var ntoken: any = '';
    var formData: any = new FormData();
    formData.append("password", this.form.value.password);
    formData.append("password_confirmation", this.form.value.confirm_password);
    formData.append("token", this.route.snapshot.params['token']);
    
    this.http.post(''+this.siteurl+'/updatePassword', formData).subscribe(
        data => {
          $('.loading-overlay').hide();
        	console.log(data)
        this.Global=data,
           console.log(this.Global.token);
           // sessionStorage.setItem('token', this.Global.token);
           // sessionStorage.setItem('role', this.Global.user.role);
           $('.errors').empty().append('<li class="text-success">'+this.Global.message+'</li>');
           $('form').remove();
           if (this.Global.message == 'Password updated Successfully') {
                  $('form').remove();
              }

        },
        error => {
          $('.loading-overlay').hide();
          $("html, body").animate({ scrollTop: 0 }, 600);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          console.log(this.errors);
          for (let i = 0; i < this.errors.length; i++) {
              $('.errors').append('<li>'+this.errors[i]+'</li>');
            } 
          
        }

      );
    
  }

}
