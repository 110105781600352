import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../api.service";
import * as $ from 'jquery';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) { 
  	var admin_email = sessionStorage.getItem('admin_email');
  	var admin_password = sessionStorage.getItem('admin_password');
    if (!admin_email && !admin_password) {
          this.router.navigate(['admin-login']);
      }
  }

  ngOnInit(): void {
  }
  profileOpen(){
    $('.dashboard-profilebar .dinnerBox').toggleClass('active');
  }
  logoutData:any;
  logout(){
    $('.loading-overlay').show();
    // sessionStorage.removeItem('admin_email');
    // sessionStorage.removeItem('admin_password');
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
