<div class="student-preview">
<a routerLink="/parent/select-student/{{game_id}}/12" class="h5 text-dark d-inline-blok"><img style="max-width:25px;margin-right:10px;" src="../assets/img/arrow-left.png"> Competition</a>
<div class="selected-student-preview">
	<h1 class="d-block w-100 text-center font-weight-bold sound_bubble_demoregular mt-3">Game: Select Big</h1>
	<div class="row mt-4 position-relative">
		<div class="col-md-6">
			<div class="inner">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h2>Mary Patricia</h2>
			</div>
		</div> <!-- col-md-6 -->
		<span class="vs">VS</span>
		<div class="col-md-6">
			<div class="inner">
			<div class="img">
				<img src="../assets/img/dummy-student1.png">
			</div> <!-- img -->
			<h2>James Robert</h2>
			</div>
		</div> <!-- col-md-6 -->
	</div> <!-- row -->
	<div class="text-center mt-5">
		<a routerLink="javascript:void(0)" (click)="letsPlay()" class="next-btn">Let's Play</a>
	</div>
</div><!-- selected-student-preview -->
</div> <!-- student-preview -->
<div class="select-student-countdown">
	<div class="inner-content">
		<a routerLink="/parent/select-student/{{game_id}}/12" (click)="removeclass()" class="h5 text-white d-inline-blok"><img style="max-width:25px;margin-right:10px;" src="../assets/img/white-arrow.png"> Competition</a>
		<h1 class="d-block w-100 text-center text-white font-weight-bold sound_bubble_demoregular mt-3">Game: Select Big</h1>
		<div class="inner-selection">
			<div class="box">
				<h2>Mary Patricia</h2>
			</div> <!-- box -->
			<div class="circule">
				<span>{{timeLeft}}</span>
			</div> <!-- circule -->
			<div class="box">
				<h2>James Robert</h2>
			</div> <!-- box -->
		</div> <!-- inner-selection -->
</div> <!-- inner-content -->
</div> <!-- select-student-countdown -->