import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-caregiversignup',
  templateUrl: './caregiversignup.component.html',
  styleUrls: ['./caregiversignup.component.css']
})
export class CaregiversignupComponent implements OnInit {
form: FormGroup;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
  	) {
  	this.form = this.fb.group({
	      first_name: [''],
	      last_name: [''],
	      email: [''],
	      password: [''],
	      confirm_password: [''],
	      caregiver_signup: '3'
	    });
  	 }

  ngOnInit(): void {
    $('.loading-overlay').hide();
  }
  errors:any;
  GoldData:any;
  submitForm() {
    $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("fname", this.form.value.first_name);
    formData.append("lname", this.form.value.last_name);
    formData.append("email", this.form.value.email);
    formData.append("password", this.form.value.password);
    formData.append("password_confirmation", this.form.value.confirm_password);
    formData.append("user_id", this.form.value.caregiver_signup);
    
    this.http.post('http://latif-overseas-ind.com/register', formData).subscribe(
        data => {
        this.GoldData=data;
        console.log(data);
        // this.results = data;
           // console.log(JSON.stringify(data));
           // sessionStorage.setItem('register', this.GoldData.token);
           if (this.GoldData.message == 'success') {
                  this.router.navigate(['email-varification']);
                  $('.loading-overlay').hide();
              }else{
                console.log('no');
              }
        },
        error => {
          $('.loading-overlay').hide();
          $("html, body").animate({ scrollTop: 0 }, 600);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          console.log(this.errors);
          for (let i = 0; i < this.errors.length; i++) {
              $('.errors').append('<li>'+this.errors[i]+'</li>');
            } 
          
        }

      );
  }
}
