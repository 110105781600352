<div class="pod-page">

<!-- Banner Section -->
<section class="banner-section text-white position-relative">
  <iframe class="position-absolute" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95259.608841984!2d-111.91084388950175!3d41.74505263304379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87547de05542a865%3A0xa9b33d0bcbbebcd5!2sLogan%2C%20UT%2C%20USA!5e0!3m2!1sen!2s!4v1605602788668!5m2!1sen!2s" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  <div class="inner position-relative p-5 d-flex align-items-start justify-content-center flex-column">
      <h4 class="d-block mt-5">Janet van Dyne</h4>
      <div class="pod-img d-flex align-items-center mt-4">
        <div class="img mr-2">
          <img src="assets/img/udlite-lohp-promo-teacher.jpg">
        </div>
        <h6><a href="#" class="text-white">Heather Douglas</a></h6>
      </div>
</div>
</section>

<!-- tab-section -->
<!-- Services Section -->
<section class="services-section pt-5 pb-5">
      <div class="container">
            <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" data-toggle="tab" href="#about">About</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#location">Location</a>
    </li>
  </ul>
<!-- Tab panes -->
  <div class="tab-content">
    <div id="about" class="container tab-pane active"><br>
      <h4 class="mb-2">Description</h4>
      <p class="border p-2 rounded">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    </div> <!-- about -->
    <div id="location" class="container tab-pane"><br>
      <h4 class="mb-2">Location</h4>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95259.608841984!2d-111.91084388950175!3d41.74505263304379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87547de05542a865%3A0xa9b33d0bcbbebcd5!2sLogan%2C%20UT%2C%20USA!5e0!3m2!1sen!2s!4v1605602788668!5m2!1sen!2s" width="100%" height="350" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div> <!-- location -->
  </div> <!-- tab-content -->
  </div>
</section>

<div class="sugested-teacher pb-5">
    <div class="container">
        <h4>Suggested Teachers</h4>
        <div class="row">
          <div class="col-md-6">
              <div class="box d-flex align-items-center justify-content-between w-100 p-3 rounded mt-3">
                <div class="left d-flex align-items-center">
                  <div class="img mr-3">
                      <img src="assets/img/udlite-lohp-promo-teacher.jpg">
                  </div>
                  <div class="content">
                      <h6 class="font-weight-bold mb-2"><a href="view-teacher.php" class="text_color2">Thor Odinson</a></h6>
                      <p class="mb-1 font_size2">Math</p>
                      <span class="d-block font_size1">Logan 313 E 1400 N</span>
                  </div>
                </div> <!-- left -->
                <div class="right">
                    <a href="view-teacher.php">View Profile</a>
                </div>
              </div> <!-- box -->
          </div> <!-- col-md-6 -->
          <div class="col-md-6">
              <div class="box d-flex align-items-center justify-content-between w-100 p-3 rounded mt-3">
                <div class="left d-flex align-items-center">
                  <div class="img mr-3">
                      <img src="assets/img/udlite-lohp-promo-teacher.jpg">
                  </div>
                  <div class="content">
                      <h6 class="font-weight-bold mb-2"><a href="view-teacher.php" class="text_color2">Robert Bruce</a></h6>
                      <p class="mb-1 font_size2">Physics</p>
                      <span class="d-block font_size1">Logan Utah 84341 USA </span>
                  </div>
                </div> <!-- left -->
                <div class="right">
                    <a href="view-teacher.php">View Profile</a>
                </div>
              </div> <!-- box -->
          </div> <!-- col-md-6 -->
          <div class="col-md-6">
              <div class="box d-flex align-items-center justify-content-between w-100 p-3 rounded mt-3">
                <div class="left d-flex align-items-center">
                  <div class="img mr-3">
                      <img src="assets/img/udlite-lohp-promo-teacher.jpg">
                  </div>
                  <div class="content">
                      <h6 class="font-weight-bold mb-2"><a href="view-teacher.php" class="text_color2">Wanda Maximoff</a></h6>
                      <p class="mb-1 font_size2">Chemistry</p>
                      <span class="d-block font_size1">Taco Bell</span>
                  </div>
                </div> <!-- left -->
                <div class="right">
                    <a href="view-teacher.php">View Profile</a>
                </div>
              </div> <!-- box -->
          </div> <!-- col-md-6 -->
          <div class="col-md-6">
              <div class="box d-flex align-items-center justify-content-between w-100 p-3 rounded mt-3">
                <div class="left d-flex align-items-center">
                  <div class="img mr-3">
                      <img src="assets/img/udlite-lohp-promo-teacher.jpg">
                  </div>
                  <div class="content">
                      <h6 class="font-weight-bold mb-2"><a href="view-teacher.php" class="text_color2">Cressida</a></h6>
                      <p class="mb-1 font_size2">Biology</p>
                      <span class="d-block font_size1">Sweet Burrito</span>
                  </div>
                </div> <!-- left -->
                <div class="right">
                    <a href="view-teacher.php">View Profile</a>
                </div>
              </div> <!-- box -->
          </div> <!-- col-md-6 -->
        </div>
    </div>
</div> <!-- sugested-teacher -->
</div> <!-- pod-page -->