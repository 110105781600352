<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Create POD</h3>
                  </div>

                  <!-- body -->
                  <div class="create-pod">
                      <div class="container">

                    <form>
                      <input class="w-100 rounded mb-3" type="name" name="name" placeholder="POD title">
                      <textarea height="200px" class="w-100 rounded mb-3" name="description" placeholder="POD Description"></textarea>
                      <div class="form-group">
                      <label class="mb-2">POD Tags:</label><br/>
                      <input type="text" name="tags" placeholder="Tags" class="tm-input form-control tm-input-info"/>
                    </div>
                    <div class="select mb-3">
                        <select class="rounded w-100">
                          <option>Learning Style</option>
                        </select>
                    </div>
                    <div class="select mb-3">
                        <select class="rounded w-100">
                          <option>Subject</option>
                          <option>Physics</option>
                          <option>Math</option>
                          <option>General Science</option>
                          <option>Urdu</option>
                        </select>
                    </div>
                    <button class="btn btn-primary">Create POD</button>
                    </form>

                      </div>
                  </div>