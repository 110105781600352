import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ParentDetailComponent } from './parent-detail/parent-detail.component';
import { ManageParentsComponent } from './manage-parents/manage-parents.component';
import { ManageTeachersComponent } from './manage-teachers/manage-teachers.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { TeacherDetailComponent } from './teacher-detail/teacher-detail.component';
import { ManagePodsComponent } from './manage-pods/manage-pods.component';
import { CreatePodComponent } from './create-pod/create-pod.component';
import { CompetitionsComponent } from './competitions/competitions.component';
import { MonitoringAlertsComponent } from './monitoring-alerts/monitoring-alerts.component';
import { ManageCaregiversComponent } from './manage-caregivers/manage-caregivers.component';
import { CaregiverDetailComponent } from './caregiver-detail/caregiver-detail.component';
import { ManageStudentsComponent } from './manage-students/manage-students.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';

const routes: Routes = [
	
	{
    path: 'admin', component: AdminComponent,
    children: [
    	{
	        path:'manage-parents',
	        component:ManageParentsComponent
	     },
    	{
	        path:'parent-detail/:id',
	        component:ParentDetailComponent
	     },
	     
	     {
	        path:'manage-teachers',
	        component:ManageTeachersComponent
	     },
	     {
	        path:'teacher-detail/:id',
	        component:TeacherDetailComponent
	     },
	     {
	        path:'manage-pods',
	        component:ManagePodsComponent
	     },
	     {
	        path:'create-pod',
	        component:CreatePodComponent
	     },
	     {
	        path:'competitions',
	        component:CompetitionsComponent
	     },
	     {
	        path:'monitoring-alerts',
	        component:MonitoringAlertsComponent
	     },
	     {
	        path:'manage-caregivers',
	        component:ManageCaregiversComponent
	     },
	     {
	        path:'caregiver-detail',
	        component:CaregiverDetailComponent
	     },
	     {
	        path:'manage-students',
	        component:ManageStudentsComponent
	     },
	     {
	        path:'student-detail',
	        component:StudentDetailComponent
	     }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
