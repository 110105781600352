import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-rejected',
  templateUrl: './teacher-rejected.component.html',
  styleUrls: ['./teacher-rejected.component.css']
})
export class TeacherRejectedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
