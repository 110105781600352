<!-- sign up -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="signup-page">
  <div class="inner">
    <h2>  Parent Sign Up!  </h2>
    <ul class="errors mb-2">
      
    </ul>
    
        <!-- <li *ngFor="let result of results">{{results}}</li> -->
      <form [formGroup]="form" (ngSubmit)="submitForm()" class="w-100">
        <div class="name input">
        <input type="name" name="first_name" placeholder="First Name" formControlName="first_name">
        <input type="name" name="last_name" placeholder="Last Name" formControlName="last_name">
        </div>
        <div class="email input">
        <input type="email" name="email" placeholder="Email" formControlName="email">
        </div>
        <div class="input password">
            <input type="password" name="password" placeholder="Password" formControlName="password">
        </div>
        <div class="input password">
            <input type="password" name="confirm_password" placeholder="Confirm Password" formControlName="confirm_password">
        </div>
        <p class="font-weight-bold mb-2">Upload Your Profile Pic</p>
        <div class="parent-image mb-2">
          <input type="file" name="add_file" name="image" formControlName="image" (change)="uploadImage($event)" onchange="document.getElementById('previewimage').src = window.URL.createObjectURL(this.files[0])" accept="image/png, image/jpeg, image/jpg">
          <img id="previewimage" src="../../assets/img/user.jpg">
        </div> <!-- parent-image -->
        <button class="btn login">Sign Up</button>
      </form>
      <p class="text-center mt-3 pt-3 border-top"> Already have an account? <a routerLink="/signin">Log In</a> </p>
  </div>
</div>