<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
      <div class="main-sidebar" id="main-sidebar">
        <a class="mw-100 pb-3 border-bottom text-center d-block" routerLink="#"><img src="assets/img/logo.png"></a>
            <ul class="main-menu">
                  <li><a routerLink="/teacher" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/home-icon.png"> Home</a></li>
                  <li><a routerLink="/teacher/manage-pod" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Manage PODS</a></li>
                  <li><a routerLink="/teacher/chs" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/happiness.png"> CHS</a></li>
                  <li><a routerLink="/teacher/categories" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Categories</a></li>
                  <li><a routerLink="/teacher/series" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Series</a></li>
                  <li><a routerLink="/teacher/alerts" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/notiification-icon.png"> Alerts</a></li>
                  <li><a routerLink="/teacher/tournaments" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Tournaments</a></li>
                  <li><a routerLink="/teacher/payments" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/payment-icon.png"> Payments</a></li>
                  <li><a routerLink="/teacher/setting" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/setting-icon.png"> Settings</a></li>
                  <li><a routerLink="#" (click)="logout()"><img src="../assets/img/logout.png"> Logout</a></li>
            </ul>
            <a routerLink="/teacher/teacher-demands/123" class="btn btn-primary btn-lg"><span>Apply For</span> Teacher On Demands</a>
      </div>
      