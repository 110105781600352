import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeacherComponent } from './teacher.component';
import { TeacherHomeComponent } from './teacher-home/teacher-home.component';
import { ManagePodComponent } from './manage-pod/manage-pod.component';
import { PendingPodDetailComponent } from './pending-pod-detail/pending-pod-detail.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ParentRequestJoinPodComponent } from './parent-request-join-pod/parent-request-join-pod.component';
import { PodDetailComponent } from './pod-detail/pod-detail.component';
import { NoteDetailComponent } from './note-detail/note-detail.component';
import { CategoriesComponent } from './categories/categories.component';
import { SeriesComponent } from './series/series.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddSeriesComponent } from './add-series/add-series.component';
import { AssignmentDetailComponent } from './assignment-detail/assignment-detail.component';
import { NotesComponent } from './notes/notes.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { AddNoteComponent } from './add-note/add-note.component';
import { AddAssignmentComponent } from './add-assignment/add-assignment.component';
import { BoardComponent } from './board/board.component';
import { StudentsComponent } from './students/students.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { StudentCollaborateComponent } from './student-collaborate/student-collaborate.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizDetailComponent } from './quiz-detail/quiz-detail.component';
import { AddQuizComponent } from './add-quiz/add-quiz.component';
import { ReportsComponent } from './reports/reports.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { AssignmentSubmissionComponent } from './assignment-submission/assignment-submission.component';
import { TeacherOnDemandsComponent } from './teacher-on-demands/teacher-on-demands.component';
import { ChsComponent } from './chs/chs.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';
import { EditAssignmentComponent } from './edit-assignment/edit-assignment.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { EditSeriesComponent } from './edit-series/edit-series.component';

const routes: Routes = [

	{
    path: 'teacher', component: TeacherComponent,
    children: [
    	{
	        path:'manage-pod',
	        component:ManagePodComponent
	     },
       {
          path:'pending-pod-detail/:id',
          component:PendingPodDetailComponent
       },
       {
          path:'alerts',
          component:AlertsComponent
       },
       {
          path:'parent-request-detail/:id',
          component:ParentRequestJoinPodComponent
       },
       {
          path:'pod-detail/:id',
          component:PodDetailComponent
       },
       {
          path:'note-detail/:id/:nid',
          component:NoteDetailComponent
       },
       {
          path:'categories',
          component:CategoriesComponent
       },
       {
          path:'series',
          component:SeriesComponent
       },
       {
          path:'add-category',
          component:AddCategoryComponent
       },
       {
          path:'add-series',
          component:AddSeriesComponent
       },
       {
          path:'assignment-detail/:id/:aid',
          component:AssignmentDetailComponent
       },
       {
          path:'notes/:id',
          component:NotesComponent
       },
       {
          path:'add-note/:id',
          component:AddNoteComponent
       },
       {
          path:'assignments/:id',
          component:AssignmentsComponent
       },
       {
          path:'add-assignment/:id',
          component:AddAssignmentComponent
       },
       {
          path:'board/:id',
          component:BoardComponent
       },
       {
          path:'students/:id',
          component:StudentsComponent
       },
       {
          path:'student-detail/:id/:sid',
          component:StudentDetailComponent
       },
       {
          path:'student-collaborate/:id/:sid',
          component:StudentCollaborateComponent
       },
       {
          path:'quiz/:id',
          component:QuizComponent
       },
       {
          path:'quiz-detail/:id/:qid',
          component:QuizDetailComponent
       },
       {
          path:'add-quiz/:id',
          component:AddQuizComponent
       },
       {
          path:'reports/:id',
          component:ReportsComponent
       },
       {
          path:'attendance/:id',
          component:AttendanceComponent
       },
       {
          path:'view-submission/:id/:sid',
          component:AssignmentSubmissionComponent
       },
       {
          path:'teacher-demands/:id',
          component:TeacherOnDemandsComponent
       },
       {
          path:'chs',
          component:ChsComponent
       },
       {
          path:'create-group',
          component:CreateGroupComponent
       },
       {
          path:'view-group/:id',
          component:ViewGroupComponent
       },
       {
          path:'edit-assignment/:id/:aid',
          component:EditAssignmentComponent
       },
       {
          path:'edit-category/:id',
          component:EditCategoryComponent
       },
       {
          path:'edit-series/:id',
          component:EditSeriesComponent
       }
       
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeacherRoutingModule { }
