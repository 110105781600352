<!-- body -->
<div class="vpod-body">
<a routerLink="/admin/manage-parents"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
<h2 class="sound_bubble_demoregular mt-2">Parent Detail</h2>
<div class="vpod-body-inner">
<div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

<div class="left pr-3 border-right border-2">
  
  <div class="d-flex align-items-start justify-content-between flex-wrap mobile-fullw">
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
      <h6>First name</h6>
      <p class="text-muted mt-2">{{parentDetail.parent[0].fname}}</p>
    </div> <!-- div -->
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
      <h6>Last name</h6>
      <p class="text-muted mt-2">{{parentDetail.parent[0].lname}}</p>
    </div> <!-- div -->
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
      <h6>Country</h6>
      <p class="text-muted mt-2">{{parentDetail.parent_details[0].cname}}</p>
    </div> <!-- div -->
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
      <h6>State</h6>
      <p class="text-muted mt-2">{{parentDetail.parent_details[0].sname}}</p>
    </div> <!-- div -->
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
      <h6>City</h6>
      <p class="text-muted mt-2">{{parentDetail.parent_details[0].city}}</p>
    </div> <!-- div -->
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
      <h6>Zip Code</h6>
      <p class="text-muted mt-2">{{parentDetail.parent_details[0].zipcode}}</p>
    </div> <!-- div -->
    <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
      <p class="text-muted mt-2"><img class="mw-100" src="{{siteurl}}/{{parentDetail.parent[0].image}}"></p>
    </div> <!-- div -->

  </div>

  <div class="children mt-4">
    <h4 class="font-weight-bold">Children</h4>
    <div class="mtable">
    <table class="table mt-3">
        <thead style="background:#EFEFEF;border-color:#E2E2E2;">
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>POD Name</th>
            <th>Action</th>
          </tr>
          </thead>
        <tbody>
      <tr>
        <td>1</td>
        <td>Petter Parker</td>
        <td>Science</td>
        <td>
          <a routerLink="#" class="btn btn-primary">View Detail</a>
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td>Petter Parker</td>
        <td>Science</td>
        <td>
          <a routerLink="#" class="btn btn-primary">View Detail</a>
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>Petter Parker</td>
        <td>Science</td>
        <td>
          <a routerLink="#" class="btn btn-primary">View Detail</a>
        </td>
      </tr>
      </tbody>
      </table>
    </div> <!--- mtable --->
  </div>

  </div>
  <div class="right" style="width:33%;">
    <div class="covid19-box border p-3 pt-4 pb-4" style="box-shadow:0px 0px 28px 0px #e6e6e6;border-radius:15px;">
      <h6 class="text-black font-weight-bold">Covid-19</h6>
      <div class="d-flex align-items-center mt-3 mb-2">
        <ng-container *ngIf="parentDetail.report == 'Positive'">
          <h6 class="text-danger">Positive (+)</h6>
        </ng-container>
        <ng-container *ngIf="parentDetail.report == 'Negative'">
          <h6 class="text-success">Negative (-)</h6>
        </ng-container>
      <p class="h6 mb-0 ml-3">{{parentDetail.score}}/36</p>
      </div> <!-- div -->
      <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have cough?</p>
          <ng-container *ngIf="parentDetail.covid[0].cough == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].cough == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
          
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have colds?</p>
          <ng-container *ngIf="parentDetail.covid[0].cold == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].cold == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you having Diarhea?</p>
          <ng-container *ngIf="parentDetail.covid[0].diarhea == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].diarhea == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have sore throat?</p>
          <ng-container *ngIf="parentDetail.covid[0].sore_throat == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].sore_throat == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you experiencing MYLAGIA or Body Aches?</p>
          <ng-container *ngIf="parentDetail.covid[0].mylagia == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].mylagia == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have a headache?</p>
          <ng-container *ngIf="parentDetail.covid[0].headache == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].headache == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have fever (Temperature 37.8 C and above)</p>
          <ng-container *ngIf="parentDetail.covid[0].fever == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].fever == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you having difficulty breathing?</p>
          <ng-container *ngIf="parentDetail.covid[0].breathing == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].breathing == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you experiencing Fatigue?</p>
          <ng-container *ngIf="parentDetail.covid[0].fatigue == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].fatigue == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Have you traveled recently during tha past 14 days?</p>
          <ng-container *ngIf="parentDetail.covid[0].travel14days == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].travel14days == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have a travel history to a COVID-19 INFECTED AREA?</p>
          <ng-container *ngIf="parentDetail.covid[0].travelcovid == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].travelcovid == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
          <p>Do you have direct contact or is taking care of a positive COVID-19 PATIENT? (3 PTS)</p>
          <ng-container *ngIf="parentDetail.covid[0].contactcovid == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="parentDetail.covid[0].contactcovid == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
                              </div>
                              
                              
                            </div>
                          </div> <!-- box -->
                      

                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->