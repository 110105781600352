import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DataTablesModule } from 'angular-datatables';

import { ParentRoutingModule } from './parent-routing.module';
import { ParentComponent } from './parent.component';
import { ParentSidebarComponent } from './parent-sidebar/parent-sidebar.component';
import { ManageStudentComponent } from './manage-student/manage-student.component';
import { ParentHomeComponent } from './parent-home/parent-home.component';
import { TeacherDetailComponent } from './teacher-detail/teacher-detail.component';
import { PendingPodDetailComponent } from './pending-pod-detail/pending-pod-detail.component';
import { AlertsComponent } from './alerts/alerts.component';
import { TeacherRequestJoinPodComponent } from './teacher-request-join-pod/teacher-request-join-pod.component';
import { PodsComponent } from './pods/pods.component';
import { PodDetailComponent } from './pod-detail/pod-detail.component';
import { AssignmentDetailComponent } from './assignment-detail/assignment-detail.component';
import { NoteDetailComponent } from './note-detail/note-detail.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { NotesComponent } from './notes/notes.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizDetailComponent } from './quiz-detail/quiz-detail.component';
import { TeamPreparationComponent } from './team-preparation/team-preparation.component';
import { AddStudentComponent } from './add-student/add-student.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { ViewSubmissionComponent } from './view-submission/view-submission.component';
import { GamificationComponent } from './gamification/gamification.component';
import { SelectStudentComponent } from './select-student/select-student.component';
import { SelectedStudentPreviewComponent } from './selected-student-preview/selected-student-preview.component';
import { GameBigSmallComponent } from './game-big-small/game-big-small.component';
import { GameResultComponent } from './game-result/game-result.component';
import { TeacherOnDemandsComponent } from './teacher-on-demands/teacher-on-demands.component';
import { ChsComponent } from './chs/chs.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';


@NgModule({
  declarations: [
    ParentComponent,
    ParentSidebarComponent,
    ManageStudentComponent,
    ParentHomeComponent,
    TeacherDetailComponent,
    PendingPodDetailComponent,
    AlertsComponent,
    TeacherRequestJoinPodComponent,
    PodsComponent,
    PodDetailComponent,
    AssignmentDetailComponent,
    NoteDetailComponent,
    AssignmentsComponent,
    NotesComponent,
    QuizComponent,
    QuizDetailComponent,
    TeamPreparationComponent,
    AddStudentComponent,
    StudentDetailComponent,
    ViewSubmissionComponent,
    GamificationComponent,
    SelectStudentComponent,
    SelectedStudentPreviewComponent,
    GameBigSmallComponent,
    GameResultComponent,
    TeacherOnDemandsComponent,
    ChsComponent,
    CreateGroupComponent,
    ViewGroupComponent
  ],
  imports: [
    CommonModule,
    ParentRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    SlickCarouselModule,
    DataTablesModule
  ]
})
export class ParentModule { }
