<!-- body -->
                  <div class="vpod-body">

                    <div class="vpod-body-inner pt-2 teacher-home-page">
                      <div class="d-flex">

                      <div class="report-total-pod w-50">
                        <div class="rpod-header d-flex align-items-center">
                          <h4>Total PODS</h4>
                          <div class="total ml-3 border p-2 rounded bg-secondary">
                            <p class="h5 m-0 text-white">22</p>
                          </div>
                          </div>
                          <canvas id="totalPOD" style="max-width: 500px;"></canvas>
                      </div> <!-- report-total-pod -->

                      <div class="pod-piechart w-50">
                        <div style="max-width:300px;width:100%;margin:0 auto;">
                        <canvas id="totalpodpiechart"></canvas>
                        </div>
                      </div>
                      
                    </div> <!-- div -->
                    <div class="report-total-student w-100 mt-4">
                        <div class="rpod-header d-flex align-items-center">
                          <h4>Total Students</h4>
                          <div class="total ml-3 border p-2 rounded bg-secondary">
                            <p class="h5 m-0 text-white">4720</p>
                          </div>
                          </div>

                          <div class="d-flex align-items-start">
                          <div class="account-student d-flex mt-3 w-50">
                            <div class="border rounded-circle p-2 text-center bg-secondary text-white mr-4 box">
                              <i class="fa fa-male lead" aria-hidden="true"></i>
                              <p class="h5 mt-2 mb-1">Male</p>
                              <p>2020</p>
                            </div>
                            <div class="border rounded-circle p-2 text-center bg-secondary text-white box female">
                              <i class="fa fa-female lead" aria-hidden="true"></i>
                              <p class="h5 mt-2 mb-1">Female</p>
                              <p>2700</p>
                            </div>
                          </div>
                          <div class="student-piechart w-50">
                            <div style="max-width:300px;width:100%;margin:0 auto;">
                            <canvas id="totalstudentpiechart"></canvas>
                          </div>
                          </div>
                        </div> <!-- div -->
                           <h4 class="mt-2">Daily Attendance</h4>
                          <canvas id="canvas-foobar" height="80px"></canvas>
                      </div> <!-- report-total-student -->
                   
                      

                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->