 <!-- body -->
                  <div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}" class="active">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
                    <div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3 class="text_color3">{{assignmentData.assignment.pname}}</h3>
                  </div>

                    <div class="vpod-body-inner">
                      
                      <div class="box view-submission d-flex align-items-start justify-content-between">

                          <div class="left">
                            <div class="w-100 alert alert-secondary">
                            <h5 class="sound_bubble_demoregular">Assignment Title</h5>
                            <p class="mt-2">{{assignmentData.assignment.title}}</p>
                        </div> <!-- w-100 -->
                        <div class="w-100 alert alert-secondary">
                            <h5 class="d-block sound_bubble_demoregular">Description</h5>
                            <p class="mt-2 font-weight-light">{{assignmentData.assignment.description}}</p>
                        </div>
                            <div class="box-inner d-flex align-items-start justify-content-between mt-4">
                              <div class="date border rounded text-center d-inline-blocck p-3">
                                <h5>Assigned on</h5>
                                  <h6 class="mt-2">{{assignmentData.assignment.assigned_on}}</h6>
                              </div>
                              <div class="date border rounded text-center d-inline-blocck p-3">
                                <h5>Last Date</h5>
                                  <h6 class="mt-2">{{assignmentData.assignment.last}}</h6>
                              </div>
                              <div class="date border rounded text-center d-inline-blocck p-3">
                                <h5>Total Submission</h5>
                                  <h6 class="mt-2">{{assignmentData.submissions}}</h6>
                              </div>
                            </div>
                            </div>
                            <div class="right">
                              <div class="text-right d-flex align-items-center mb-2 justify-content-end">
                                  <a routerLink="#" class="btn btn-primary d-inline-block mr-1">Edit</a>
                                  <a routerLink="#" class="btn btn-danger d-inline-block">Delete</a>
                              </div> <!-- text-right -->
                              <div class="border rounded p-2">
                                <h3>Files</h3>
                                <div class="file-box mt-3 alert alert-secondary" *ngFor="let file of assignmentData.assignment.file">
                                    <a class="d-block mb-3" href="{{siteurl}}/{{file}}" download="{{siteurl}}/{{file}}">
                                    <i class="fa fa-file" aria-hidden="true"></i> 
                                    {{assignmentData.assignment.title}}
                                  </a>
                                  <a type="application/octet-stream" class="btn btn-primary d-inline-block mr-1" href="{{siteurl}}/{{file}}" hreflang="{{siteurl}}/{{file}}" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

                                  <a class="btn btn-success d-inline-block" href="{{siteurl}}/{{file}}" download="{{siteurl}}/{{file}}"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                </div> <!-- file-box -->
                                
                            </div>
                          </div> <!-- right -->
                          </div> <!-- box -->
                      

                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->