<div class="vpod-body">

<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}" class="active">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">Science</h3>
</div>
	<div class="vpod-body-inner">
          <div class="card-body pt-0">
                <h4>Update Assignment</h4>
                  <form [formGroup]="assignments" autocomplete="off" (ngSubmit)="addAssignment()">
                      <div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

        <div class="left">
        	title: {{assignmentData.assignment.title}}
            <input class="h6 rounded w-100" type="text" name="title" placeholder="Assignment Title" formControlName="title">
            Description: {{assignmentData.assignment.description}}
          <textarea class="h6 rounded w-100" name="description" placeholder="Assignment Description" formControlName="description"></textarea>
          <div class="box-inner d-flex align-items-start justify-content-start mt-4">
            <div class="date border  rounded text-center d-inline-blocck p-3 mr-2">
              <h5>Assigned on</h5>
                <input type="date" name="assigned_on" class="h6 rounded w-100 mt-2" formControlName="assigned_on">
                Assigned on: {{assignmentData.assignment.assigned_on}}
            </div>
            <div class="date border rounded text-center d-inline-blocck p-3">
              <h5>Last Date</h5>
                <input type="date" name="last" class="h6 rounded w-100 mt-2 last_date" formControlName="last">
                last date: {{assignmentData.assignment.last}}
            </div>
          </div>
          <button type="submit" class="btn btn-primary d-inline-block text-white mt-2 pl-4 pr-4" >
          Update</button>
          </div>
          <div class="right border  rounded p-3">
              <h5 class="mb-2">Media</h5>
              <input class="mw-100" type="file" name="asfiles" formControlName="asfiles" (change)="asImage($event)" multiple>

              <h3 class="mt-3">Files</h3>
            <div class="file-box mt-3 alert alert-secondary" *ngFor="let file of assignmentData.assignment.file">
                <a class="d-block mb-3" href="{{siteurl}}/{{file}}" download="{{siteurl}}/{{file}}">
                <i class="fa fa-file" aria-hidden="true"></i> 
                {{assignmentData.assignment.title}}
              </a>
              <a type="application/octet-stream" class="btn btn-primary d-inline-block mr-1" href="{{siteurl}}/{{file}}" hreflang="{{siteurl}}/{{file}}" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

              <a class="btn btn-success d-inline-block" href="{{siteurl}}/{{file}}" download="{{siteurl}}/{{file}}"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
            </div> <!-- file-box -->
                            </div>
                          </div> <!-- box -->
                      </form>
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->

<p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  