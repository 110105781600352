<!-- body -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
                  <div class="vpod-body">

              <div class="inner-header d-flex align-items-center justify-content-between mb-4">
                    <h3>Parent Requests Join PODS</h3>
              </div>
              <div class="mtable">
<table datatable class="table">
                  <thead>
                  <tr>
                    <th>View</th>
                    <th>Profile Pic</th>
                    <th>Parent Name</th>
                    <th>POD Name</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pdetail of podData.alerts; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{pdetail.image}}"></td>
                    <td>{{pdetail.fname}} {{pdetail.lname}}</td>
                    <td>{{pdetail.name}}'s POD</td>
                     
                    <td>
                      <a routerLink="/teacher/parent-request-detail/{{pdetail.id}}" class="btn btn-primary">Detail</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!---- mtable ---->
                <div class="inner-header mb-4">
                    <h3>Group Alerts</h3>
              </div>
              <div class="mtable">
              <table datatable class="table">
                  <thead>
                  <tr>
                    <th>View</th>
                    <th>Group Pic</th>
                    <th>Group Name</th>
                    <th>From Name</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let grpalerts of groupAlerts.groupAlerts; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{grpalerts.cover}}"></td>
                    <td>{{grpalerts.title}}</td>
                    <td>{{grpalerts.fromname}}</td>
                     
                    <td>
                      <div class="d-flex align-items-center">
                      <!-- <button data-id="{{grpalerts.action_id}}" class="btn btn-success" (click)="acceptgroup($event)">Accept</button>
                      <button data-id="{{grpalerts.action_id}}" class="btn btn-danger mx-2" (click)="rejectgroup($event)">Reject</button> -->
                      <a routerLink="/teacher/view-group/{{grpalerts.action_id}}" class="btn btn-primary">Detail</a>
                      </div>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!--- mtable --->
                  </div> <!-- vpod-body -->


                  <p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  