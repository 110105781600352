<!-- body -->
<div class="vpod-body">
<div class="vpod-nav">
<ul>
    <li><a routerLink="/parent/pod-detail/{{pod_id}}">Home</a></li>
    <li><a routerLink="/parent/assignments/{{pod_id}}">Assignments</a></li>
    <li><a routerLink="/parent/notes/{{pod_id}}">Notes</a></li>
    <li><a routerLink="/parent/quiz/{{pod_id}}">Quiz</a></li>
    <li><a routerLink="/parent/team-preparation/{{pod_id}}" class="active">Team Preparation</a></li>
</ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3>Science</h3>
    <a routerLink="create-new-group.html" class="btn btn-primary">Create New Group</a>
</div>
<div class="vpod-body-inner">
<div class="card-body pt-0">
  <div class="mtable">
    <table datatable class="table">
      <thead style="background:#EFEFEF;border-color:#E2E2E2;">
      <tr>
        <th>Team Name</th>
        <th>Last Collaboration on</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Physics</td>
        <td>10/5/2020</td>
        <td>
          <a routerLink="#" class="btn btn-primary">View</a>  
        </td>
      </tr>
      <tr>
        <td>Physics</td>
        <td>12/19/2020</td>
        <td>
          <a routerLink="#" class="btn btn-primary">View</a>  
        </td>
      </tr>
      
      </tbody>
    </table>
  </div> <!--- mtable --->
  </div> <!-- card-body -->

      
    </div> <!-- vpod-body-inner -->

  </div> <!-- vpod-body -->