import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';
@Component({
  selector: 'app-teacher-detail',
  templateUrl: './teacher-detail.component.html',
  styleUrls: ['./teacher-detail.component.css']
})
export class TeacherDetailComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) { }

  teacherDetail: any;
  teacher_id: any;
  ngOnInit(): void {
    $('.loading-overlay').hide();
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'email': ''+sessionStorage.getItem('admin_email')+'', 'password': ''+sessionStorage.getItem('admin_password')+'' };

    this.http.post(''+this.siteurl+'/viewTeacher/'+this.teacher_id+'',formData).subscribe(
        data => {
          console.log(data);
          this.teacherDetail = data;
        }

      );
  }
  teacherReject(){
    $('.loading-overlay').show();
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'email': ''+sessionStorage.getItem('admin_email')+'', 'password': ''+sessionStorage.getItem('admin_password')+'' };

    this.http.post(''+this.siteurl+'/updateTeacherStatus/'+this.teacher_id+'/not_active',formData).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
          this.teacherDetail = data;
          this.router.navigate(['/admin/manage-teachers']);
        }

      );
  }
  teacherAccept(){
    $('.loading-overlay').show();
  	this.teacher_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'email': ''+sessionStorage.getItem('admin_email')+'', 'password': ''+sessionStorage.getItem('admin_password')+'' };

    this.http.post(''+this.siteurl+'/updateTeacherStatus/'+this.teacher_id+'/active',formData).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
          this.teacherDetail = data;
          this.router.navigate(['/admin/manage-teachers']);
        }

      );
  }

}
