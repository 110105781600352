import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shareurl: ApiService
  	) { 
    
  }

  ngOnInit(): void {
    var role_nmbr = sessionStorage.getItem('role');
    if (sessionStorage.getItem('token') && role_nmbr == '1') {
          // this.router.navigate(['parent']);
          $('header.site-header nav ul li.itemm').addClass('d-none');
          $('header.site-header nav ul li.parent').removeClass('d-none');
    }
    else if(sessionStorage.getItem('token') && role_nmbr == '2'){
      // this.router.navigate(['teacher']);
      $('header.site-header nav ul li.itemm').addClass('d-none');
      $('header.site-header nav ul li.teacher').removeClass('d-none');
    }else if(sessionStorage.getItem('token') && role_nmbr == 'n2'){
      // this.router.navigate(['teacher']);
      $('header.site-header nav ul li.itemm').addClass('d-none');
      $('header.site-header nav ul li.tlogout').removeClass('d-none');
    };
    $('.loading-overlay').hide();
  }
  logoutData:any;
  logoutBtn(){
    $('.loading-overlay').show();
    
    var formData: any = new FormData();
  	var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
  	this.http.post(''+this.siteurl+'/logout',formData, { headers }).subscribe(
        data => {
        	console.log(data);
        this.logoutData=data;
           // console.log(this.logoutData.token);
           
           if (this.logoutData.message == 'success') {
                  // sessionStorage.removeItem('token');
                  // sessionStorage.removeItem('role');
                  sessionStorage.clear();
                  console.log('session remove');
                  $('.loading-overlay').hide();
                  $('header.site-header nav ul li.itemm').removeClass('d-none');
                  $('header.site-header nav ul li.plogout').removeClass('d-none');
                  $('header.site-header nav ul li.tlogout').addClass('d-none');
              }else{
                console.log('no');
                $('.loading-overlay').hide();
              }
        }

      );
  }
  // logoutBtn(){
  //   $('header.site-header nav ul li.plogout').removeClass('d-none');
  //   $('header.site-header nav ul li.tlogout').addClass('d-none');
  // }

}
