import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';
@Component({
  selector: 'app-teacherprofile',
  templateUrl: './teacherprofile.component.html',
  styleUrls: ['./teacherprofile.component.css']
})
export class TeacherprofileComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
private router: Router,
public fb: FormBuilder,
private shareurl: ApiService
    ) { 
    var role_nmbr = sessionStorage.getItem('trole');
    if (!sessionStorage.getItem('token')) {
          this.router.navigate(['signin']);
      }
    if (sessionStorage.getItem('token') && role_nmbr == '1') {
          this.router.navigate(['parent']);
    }else if(role_nmbr == '2'){
        $('header.site-header nav ul li.plogout').addClass('d-none');
        $('header.site-header nav ul li.tlogout').removeClass('d-none');
      }
    // else if(sessionStorage.getItem('token') && role_nmbr == '2'){
    //   this.router.navigate(['teacher']);
    // };
    this.form = this.fb.group({
        fname: [''],
        lname: [''],
        country_id: [''],
        state_id: [''],
        city: [''],
        address: [''],
        zipcode: [''],
        ppsl: [''],
        pssl: [''],
        miles:[''],
        dob: [''],
        teachingSkill: [''],
        certification: [''],
        cv: [''],
        cough: [''],
        cold: [''],
        diarhea: [''],
        sore_throat: [''],
        mylagia: [''],
        headache: [''],
        fever: [''],
        breathing: [''],
        fatigue: [''],
        travel14days: [''],
        travelcovid: [''],
        contactcovid: [''],
        grade_id: [''],
      });
   }
  teacher_signup(input:any) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
              reader.onload = function(e:any) {
                  $('#teacher_signup_image').css('background-image', 'url('+e.target.result +')');
                  $('#teacher_signup_image').hide();
                  $('#teacher_signup_image').fadeIn(650);
              }
              reader.readAsDataURL(input.files[0]);
          }
      }
  teacherSignup(a:any) {
      var myImg = <HTMLInputElement>document.getElementById('teacher_signup');
      myImg.src = a;
    }
    // stepOpne(){
    //   $('.parent-first-step').addClass('hide');
    //     $('.parent-second-step').removeClass('hide');
    //   }
      stepTwo(){
      $('.parent-first-step').removeClass('hide');
        $('.parent-second-step').addClass('hide');
      }


      statess: any;
    changecountry(){
      // $('.select_state option').empty();
        var country_id = $(".country_select option:selected").attr('value');
        var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
        $.ajax({
          type: "Get",
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Bearer '+sessionStorage.getItem('token')+'');
          },
          url: ''+this.siteurl+'/getStates/'+country_id+'',
          // data: "json=" + escape(JSON(createRequestObject)),
          processData: false,
          success: function(data) {
            // $("#results").append("The result =" + (msg));
            // console.log(data.states);
            $(".select_state").empty();
            this.statess = data.states;
            for (var i = this.statess.length - 1; i >= 0; i--) {
              $(".select_state").append('<option value="'+this.statess[i].id+'">'+this.statess[i].name+'</option>');
              // console.log(this.statess[i].name);
            }

          }
    });
    }
    teachercompleteprofile: any;
    errors:any;
  ngOnInit(): void {
    var now = new Date(),
    // minimum date the user can choose, in this case now and in the future
    maxDate = now.toISOString().substring(0,10);

    $('.dob input').prop('max', maxDate);

    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
      this.http.get(''+this.siteurl+'/checkTeacherDetails', {headers}).subscribe(
      (response) => {
        this.message = response;
        if (this.message.message == '2') {
                this.router.navigate(['/rejected']);
            }else if(this.message.message != '3'){
          this.router.navigate(['/teacher']);
        }else if(this.message.message == '0'){
              this.router.navigate(['/processing']);
            }
        console.log(response);
      },
      (error) => {
      },
    )
      // 
    var dtToday = new Date();
    var month1 = '';
    var day1 = '';
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if(month < 10){
        month1 = '0' + month.toString();
        }
    if(day < 10){
        day1 = '0' + day.toString();
        }
    
    var maxDate = year + '-' + month1 + '-' + day1;

    // or instead:
    // var maxDate = dtToday.toISOString().substr(0, 10);

    // alert(maxDate);
    $('input[type="date"]').attr('max', maxDate);

    $('.loading-overlay').show();
      var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/teacherFormData', { headers }).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
          this.teachercompleteprofile = data;
          $('.first_name').attr({'value': this.teachercompleteprofile.user.fname});
          $('.first_name').val(this.teachercompleteprofile.user.fname);
          $('.last_name').val(this.teachercompleteprofile.user.lname);
          for (var i = this.teachercompleteprofile.countries.length - 1; i >= 0; i--) {
              $(".country_select").append('<option value="'+this.teachercompleteprofile.countries[i].id+'">'+this.teachercompleteprofile.countries[i].name+'</option>');
            }
            for (var p = this.teachercompleteprofile.PrimaryLangs.length - 1; p >= 0; p--) {
              $(".select_ppl").append('<option value="'+this.teachercompleteprofile.PrimaryLangs[p].id+'">'+this.teachercompleteprofile.PrimaryLangs[p].name+'</option>');
            }
            for (var pssl = this.teachercompleteprofile.SecondaryLangs.length - 1; pssl >= 0; pssl--) {
              $(".select_pssl").append('<option value="'+this.teachercompleteprofile.SecondaryLangs[pssl].id+'">'+this.teachercompleteprofile.SecondaryLangs[pssl].name+'</option>');
            }
            for (var grade = this.teachercompleteprofile.grades.length - 1; grade >= 0; grade--) {
              $(".grade").append('<option value="'+this.teachercompleteprofile.grades[grade].id+'">'+this.teachercompleteprofile.grades[grade].name+'</option>');
            }
            for (var mil = this.teachercompleteprofile.miles.length - 1; mil >= 0; mil--) {
              $(".miles").append('<option value="'+this.teachercompleteprofile.miles[mil].id+'">'+this.teachercompleteprofile.miles[mil].name+'</option>');
            }
            for (var tskill = this.teachercompleteprofile.teachingSkill.length - 1; tskill >= 0; tskill--) {
              if(this.teachercompleteprofile.teachingSkill[tskill].id != 15){
                $(".teachingSkills").append('<option value="'+this.teachercompleteprofile.teachingSkill[tskill].id+'">'+this.teachercompleteprofile.teachingSkill[tskill].name+'</option>');
              }
              
            }
        
        }

      );
  }
  cv:any;
  uploadCV(event:any){
    this.cv = event.target.files[0];
    console.log(this.cv);
  }
  message: any;
    submitForm() {
      if (this.cv) {
        var formData: any = new FormData();
    formData.append("fname", this.form.value.fname);
    formData.append("lname", this.form.value.lname);
    formData.append("country_id", this.form.value.country_id);
    formData.append("state_id", this.form.value.state_id);
    formData.append("city", this.form.value.city);
    formData.append("address", this.form.value.address);
    formData.append("zipcode", this.form.value.zipcode);
    formData.append("ppsl", this.form.value.ppsl);
    formData.append("pssl", this.form.value.pssl);
    formData.append("miles", this.form.value.miles);
    formData.append("dob", this.form.value.dob);
    formData.append("teachingSkill", this.form.value.teachingSkill);
    formData.append("certification", this.form.value.certification);
    formData.append("cv", this.cv, this.cv.name);
    formData.append("cough", this.form.value.cough);
    formData.append("cold", this.form.value.cold);
    formData.append("diarhea", this.form.value.diarhea);
    formData.append("sore_throat", this.form.value.sore_throat);
    formData.append("mylagia", this.form.value.mylagia);
    formData.append("headache", this.form.value.headache);
    formData.append("fever", this.form.value.fever);
    formData.append("breathing", this.form.value.breathing);
    formData.append("fatigue", this.form.value.fatigue);
    formData.append("travel14days", this.form.value.travel14days);
    formData.append("travelcovid", this.form.value.travelcovid);
    formData.append("contactcovid", this.form.value.contactcovid);
    formData.append("grade_id", this.form.value.grade_id);

    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    
    this.http.post(''+this.siteurl+'/saveTeacherDetails', formData, {headers}).subscribe(
      response => {
        this.message = response;
        if (this.message.message == 'Success') {
            this.router.navigate(['/processing']);
            $('.loading-overlay').hide();
        }
      },
      (error) => {
        $("html, body").animate({ scrollTop: 0 }, 600);
          console.log(error.name);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          // console.log(this.errors);
          $('.errors').append('<li>'+this.errors[0]+'</li>');
          // for (let i = 0; i < this.errors.length; i++) {
              
          //   } 
            $('.loading-overlay').hide();
      },
    );
      }else{
        $('.loading-overlay').hide();
        $("html, body").animate({ scrollTop: 0 }, 600);
        $('.errors').empty().append('<li>CV is required</li>');
      }
    
  }
  nextmessage: any;
  nextButtonFunction(){
    if (this.cv) {
        var formData: any = new FormData();
    formData.append("fname", this.form.value.fname);
    formData.append("lname", this.form.value.lname);
    formData.append("country_id", this.form.value.country_id);
    formData.append("state_id", this.form.value.state_id);
    formData.append("city", this.form.value.city);
    formData.append("address", this.form.value.address);
    formData.append("zipcode", this.form.value.zipcode);
    formData.append("ppsl", this.form.value.ppsl);
    formData.append("pssl", this.form.value.pssl);
    formData.append("miles", this.form.value.miles);
    formData.append("dob", this.form.value.dob);
    formData.append("teachingSkill", this.form.value.teachingSkill);
    formData.append("certification", this.form.value.certification);
    formData.append("cv", this.cv, this.cv.name);
    formData.append("grade_id", this.form.value.grade_id);

    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    
    this.http.post(''+this.siteurl+'/validationForTeacher', formData, {headers}).subscribe(
      response => {
        this.nextmessage = response;
        if (this.nextmessage.message == 'Success') {
          $('.loading-overlay').hide();
            $('.parent-first-step').addClass('hide');
        $('.parent-second-step').removeClass('hide');

        $('.errors').empty();
        }
      },
      (error) => {
        $("html, body").animate({ scrollTop: 0 }, 600);
          console.log(error.name);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          // console.log(this.errors);
          $('.errors').append('<li>'+this.errors[0]+'</li>');
          // for (let i = 0; i < this.errors.length; i++) {
              
          //   } 
            $('.loading-overlay').hide();
      },
    );
      }else{
        $('.loading-overlay').hide();
        $("html, body").animate({ scrollTop: 0 }, 600);
        $('.errors').empty().append('<li>CV is required</li>');
      }
  }
  
  }
	/*startDate = new Date(1990, 0, 1);*/
