import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css']
})
export class ParentComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) {
  		
	      var role_nmbr = sessionStorage.getItem('role');
		    if (!sessionStorage.getItem('token') && role_nmbr != '1') {
		          this.router.navigate(['signin']);
		      }else if(!role_nmbr){
            this.router.navigate(['signin']);
          }
          
  	 }
message: any;
first_name: any;
  last_name: any;
  simage: any;
  ngOnInit(): void {
    this.first_name = sessionStorage.getItem('fname');
    this.last_name = sessionStorage.getItem('lname');
    this.simage = sessionStorage.getItem('image');
    
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
          this.http.get(''+this.siteurl+'/checkParentDetails', {headers}).subscribe(
          (response) => {
            this.message = response;
            if (this.message.message == 'failure') {
                this.router.navigate(['/parent-complete-profile']);
            }
            console.log(response);
          },
          (error) => {
          },
        )
  }

  profileOpen(){
    $('.dashboard-profilebar .dinnerBox').toggleClass('active');
  }
 

  logoutData:any;
  logout(){
    $('.loading-overlay').show();
    var formData: any = new FormData();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/logout',formData, { headers }).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
        this.logoutData=data;
           // console.log(this.logoutData.token);
           
           if (this.logoutData.message == 'success') {
                  // sessionStorage.removeItem('token');
                  // sessionStorage.removeItem('role');
                  // sessionStorage.removeItem('role');
                  // sessionStorage.removeItem('role');
                  sessionStorage.clear();
                  console.log('session remove');
                  this.router.navigate(['/']);
              }else{
                console.log('no');
              }
        }

      );
  }

}
