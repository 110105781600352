<div class="gamification-page">
	<div class="box mb-4">
		<div class="head d-flex align-items-center justify-content-between">
			<h3 class="font-weight-bold">English</h3>
			<a routerLink="#" class="btn btn-secondary btn-lg">View All</a>
		</div> <!-- head -->
		<div class="row mt-3">
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/english-personel-pronounse.jpg"></a> 
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/english-pronounse.jpg"></a>
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/english-the-use-of-articles.jpg"></a>
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/english-writing-an-essay.jpg"></a>
			</div> <!-- col-md-3 -->
		</div> <!-- row -->
	</div> <!-- box -->
	<div class="box mb-4">
		<div class="head d-flex align-items-center justify-content-between">
			<h3 class="font-weight-bold">Mathematics</h3>
			<a routerLink="#" class="btn btn-secondary btn-lg">View All</a>
		</div> <!-- head -->
		<div class="row mt-3">
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/math-algebraic-expressions.jpg"></a> 
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/math-algebraic-formulae.jpg"></a>
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/math-simultaneous-equations.jpg"></a>
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/math-triangels.jpg"></a>
			</div> <!-- col-md-3 -->
		</div> <!-- row -->
	</div> <!-- box -->
	<div class="box mb-4">
		<div class="head d-flex align-items-center justify-content-between">
			<h3 class="font-weight-bold">Games</h3>
			<a routerLink="#" class="btn btn-secondary btn-lg">View All</a>
		</div> <!-- head -->
		<div class="row mt-3">
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/game-card-matching.jpg"></a> 
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="/parent/select-student/12/12"><img class="mw-100" src="../assets/img/game-select-big.jpg"></a>
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="/parent/select-student/12/12"><img class="mw-100" src="../assets/img/game-select-same.jpg"></a>
			</div> <!-- col-md-3 -->
			<div class="col-md-3 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/game-super-crossword.jpg"></a>
			</div> <!-- col-md-3 -->
		</div> <!-- row -->
	</div> <!-- box -->
	<div class="box">
		<div class="head d-flex align-items-center justify-content-between">
			<h3 class="font-weight-bold">Competition</h3>
			<a routerLink="#" class="btn btn-secondary btn-lg">View All</a>
		</div> <!-- head -->
		<div class="row mt-3">
			<div class="col-md-4 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/competition-english.jpg"></a> 
			</div> <!-- col-md-4 -->
			<div class="col-md-4 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/competition-mathematics.jpg"></a>
			</div> <!-- col-md-4 -->
			<div class="col-md-4 col-sm-6">
				<a routerLink="#"><img class="mw-100" src="../assets/img/competition-science.jpg"></a>
			</div> <!-- col-md-4 -->
		</div> <!-- row -->
	</div> <!-- box -->
</div><!-- gamification-page -->