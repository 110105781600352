<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>

<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}" class="active">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">Science</h3>
</div>
	<div class="vpod-body-inner">
          <div class="card-body pt-0">
                <h4>Create Notes</h4>
                          <form [formGroup]="form" (ngSubmit)="addNote()">
                      <div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

                          <div class="left">
                      <select class="form-control mb-3" name="category" formControlName="category">
                        <option value="" disabled hidden>Select Category</option>
                        <option *ngFor="let cat of noteData.categories" value="{{cat.id}}">{{cat.title}}</option>
                      </select>
                      <select class="form-control mb-3" name="serie" formControlName="serie">
                        <option value="" disabled hidden>Select Serie</option>
                        <option *ngFor="let serie of noteData.series" value="{{serie.id}}">{{serie.title}}</option>
                      </select>
                      
                    <input class="h6 rounded w-100" type="text" name="title" placeholder="Note Title" formControlName="title">
                  <textarea class="h6 rounded w-100" name="description" placeholder="Notes Description" formControlName="description"></textarea>
                  <input class="w-100" type="text" name="tags" placeholder="Tags" formControlName="tags">
                  <button type="submit" class="btn btn-primary d-inline-block text-white mt-2 pl-4 pr-4" >
                  Save</button>
                  </div>
                  <div class="right border  rounded p-3">
                      <h5>Upload file</h5>
                      
                      <div class="add-file nadd-file mt-4 text-center p-4 position-relative">
                        <input type="file" name="add_file" name="file" formControlName="file" (change)="uploadImage($event)" onchange="document.getElementById('previewimage').src = window.URL.createObjectURL(this.files[0])">
                        <i class="fa fa-arrow-circle-up d-block mb-2 lead" aria-hidden="true"></i>
                        <a href="javascript:void(0)" class="btn btn-light  ">Add file</a>
                      <img id="previewimage" width="100%" />
                      </div>
                      <p class="mt-3">{{fileName}}</p>
                      

                  </div>
                          </div> <!-- box -->
                      </form>
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->

<p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  