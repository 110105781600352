  <app-header></app-header>
<router-outlet></router-outlet>
<div class="mainone-page" *ngIf="router.url == '/'">

 <!-- Banner Section -->
<section class="banner-section p-5 d-flex align-items-center justify-content-center text-white">
  <div class="row align-items-center">
    <div class="col-md-7 text-left pr-0 foobottom">
      <h6 class="d-block mb-3">Online PODS for children of all skill levels and backgrounds</h6>
      <h1>Learn Math, General Science, Physics, and more all on your own time</h1>
      <!-- subscribe section -->
<div class="subscribe-form mt-3">
            <div class="inner">
                  <form method="post" class="pb-1 pl-1">
                        <div class="d-flex align-items-end flex-wrap">
                        <label><input type="email" name="email" placeholder="Enter your email address"></label>
                        <button type="submit" class="ml-4 clv-btn-shadow">Join Newslatter</button>
                        </div>
                  </form>
            </div>
</div>
    </div>
    <div class="col-md-5 p-0">
      <div class="banner-images">
      	 <ngx-slick-carousel class="banner-images" 
  #slickModal="slick-carousel" 
  [config]="slideConfig"
  (beforeChange)="beforeChange()">
  <div class="text-center" ngxSlickItem *ngFor="let slide of bannerslides">
    <img class="mw-100 mt-5 ml-auto mr-auto" src="{{ slide.img }}" alt="">
  </div>
</ngx-slick-carousel>
      </div>
    </div>
  </div>
      
</section>
<!-- home-who-we--are -->
<section class="who-we-are">
  <div class="container">
    <div class="row">
      <div class="col-md-6 left">
        <h2><span>Who</span> <br> We Are</h2>
        <p>We are an U.S based start-up contributing to the continuity of children education during COVID-19 by providing POD recommendations, personalized tutoring and K-5 gamification using artificial intelligence and data analytics.
</p>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
          <div class="box">
            <h2 class="font_family_sound_bubble">Our Aim</h2>
            <p>To create the most compelling ‘Learning Pod’ of
            the 21st century and ensure continuity of children
            education.
            </p>

            <h2 class="font_family_sound_bubble mt-5">Vision</h2>
            <p>Become a leading choice to access the most
            compelling ‘Learning Pod’ of the 21st century and
            drive the world’s transition to intelligent learning
            systems</p>
          </div>
      </div> <!-- col-md-6 -->
    </div> <!-- row -->
    </div>
</section>

<!-- pod-own-challanges -->
<section class="pod-own-challanges">
  <div class="container">
    <h2 class="text-center">But Pods Have Their Own Challenges!</h2>
    <h3 class="text-center">Learning pods have emerged as a fascinating concept of digital learning, solving discontinuance of <br> education during COVID-19, but they come with their own problems</h3>

    <div class="row">
      <div class="col-md-6">
        <div class="inner">
          <div class="img">
            <img src="assets/img/less-interactive-icon.png">
          </div>
          <p>Less interactive and <br> engaging teaching methods</p>
        </div>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <div class="inner">
          <div class="img">
            <img src="assets/img/safety-protocols-icon.png">
          </div>
          <p>Non-compliances with health and <br> safety protocols of COVID-19</p>
        </div>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <div class="inner">
          <div class="img">
            <img src="assets/img/lack-of-effective-icon.png">
          </div>
          <p>Lack of effective monitoring of <br>
students’ progress </p>
        </div>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <div class="inner">
          <div class="img">
            <img src="assets/img/significantly-icon.png">
          </div>
          <p>Significantly lower quality <br>
education than normal schools </p>
        </div>
      </div> <!-- col-md-6 -->
    </div> <!-- row -->
  </div>
</section>

<!-- home-our-solution-section -->
<section class="our-solution">
  <div class="container">
    <h2>Our Solution </h2>
    <h3>Personalized tutoring with Real-time monitoring of COVID-19 measures!</h3>
    <h4>Using Artificial Intelligence (AI) recommendation system, CLV suggests personalized pods to parents based on their needs</h4>
    <div class="row">
      <div class="col-md-3">
        <div class="img">
        <img class="mw-100" src="assets/img/about1.png">
        </div>
        <p>Advance tracking and detection technology with real-time monitoring of members’ adherence to Covid19 public health standards</p>
      </div> <!-- col-md-3 -->
      <div class="col-md-3">
        <div class="img">
        <img class="mw-100" src="assets/img/about2.png">
        </div>
        <p>Allowing teachers to give individual attention to each student and devise specific tuition plans</p>
      </div> <!-- col-md-3 -->
      <div class="col-md-3">
        <div class="img">
        <img class="mw-100" src="assets/img/about3.png">
        </div>
        <p>K-5 gamification feature for engagement enhancement and a great learning experience</p>
      </div> <!-- col-md-3 -->
      <div class="col-md-3">
        <div class="img">
        <img class="mw-100" src="assets/img/about4.png">
        </div>
        <p>Custom interactive school analytical dashboards for parents to review their children’s performance</p>
      </div> <!-- col-md-3 -->
    </div> <!-- row -->
  </div>
</section>

<!-- how-clv-work -->
<section class="how-clv-work">
  <div class="container">
    <h2 class="text-center">How CLV Works!</h2>

    <div class="boxes">
      <div class="row">

        
        <div class="col-md-4">

          <div class="box content left">
            <div class="content step_one">
                
                <p>Data analytics, dashboards
and other reports are
provided to members</p>
              <span>STEP <br><p>06</p></span>
              </div>
              <div class="content step_three">
                
                <p>Compliance to COVID-19
measures are monitored
real-time using our
advanced AI detection and
tracking technology</p>
                <span>STEP <br><p>05</p></span>
              </div>
            
          <div class="content step_five">
          
          <p>Students are provided personalized
tutoring using CLV’s advanced tutoring
technology + gamification as an
engagement enhancement tool</p>
          <span>STEP <br><p>04</p></span>
        </div>
            
          </div>
        </div>
        <div class="col-md-4">
          <div class="box logo">
        <div class="inner">
        <img src="assets/img/logo.png">
        </div>
      </div>
        </div>
        <div class="col-md-4">
          <div class="box content right">
            
              <div class="content step_two">
                <span>STEP <br><p>01</p></span>
                <p>Parents download, sign up on
CLV app and provide their
profile information</p>
              </div>
              <div class="content step_four">
          <span>STEP <br><p>02</p></span>
          <p>CLV’s AI recommendation
systems analyses the profile for
capturing relevant information</p>
        </div>
        <div class="content step_six">
          <span>STEP <br><p>03</p></span>
          <p>System then searches and
provides personalized pods
recommendations to parents
based on their community and
kid’s leanings needs</p>
        </div>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>

<!-- clv-covid-19 -->
<section class="clv-covid-19">
  <div class="box">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2>COVID-19 <br>
          Monitoring Using AI</h2>
          <p>CLV will implement industry leading and <br>
fault-proof real time AI technology that <br>
will ensure:</p>
<ul>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Detection and alert of social distancing</li>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Detection and control of groups and meetings</li>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Detection of the use of protection masks</li>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Temperature detection</li>
</ul>
        </div> <!-- col-md-6 -->
        <div class="col-md-6">
          <img class="mw-100 main" src="assets/img/mask-protected.jpg">
          <!-- <div class="images">
            <img class="mw-100" src="assets/img/social-distancing2.jpg">
            <img class="mw-100" src="assets/img/temprature.jpg">
          </div> -->
        </div> <!-- col-md-6 -->
      </div> <!-- row -->
    </div> <!-- conttainer -->
  </div> <!-- box -->
</section>

<section class="clv-covid-19 second">
  <div class="box">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="mw-100 " src="assets/img/intelligent-personalized-tutoring.jpg">
          <!-- <div class="images">
            <img class="mw-100" src="assets/img/social-distancing2.jpg">
            <img class="mw-100" src="assets/img/temprature.jpg">
          </div> -->
        </div> <!-- col-md-6 -->

        <div class="col-md-6">
          <h2 class="mb-4">Intelligent <br>
Personalized Tutoring</h2>
<ul>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Online one-on-one personalized service with
specialized tutors as part of our math intervention
program.</li>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Using CLV’s self-service live streaming video and
recording to show assignments work and
instructions.</li>
  <li><i class="fa fa-check-circle" aria-hidden="true"></i> Specifically designed for children aged 6 to 12.</li>
</ul>
        </div> <!-- col-md-6 -->
        
      </div> <!-- row -->
    </div> <!-- conttainer -->
  </div> <!-- box -->
</section>

<!-- home-gamification -->
<section class="gamification">
  <div class="container">
    <h2>Gamification To Increase Engagement</h2>
    <h3>Tapping into the natural tendency of kids towards gaming and turning these organic <br> affinities into study groups.</h3>
    <ul>
      <li>Peer-Tutoring</li>
      <li>PeerCollaboration</li>
      <li>Team Prep – Allowing parents to help <br> practice, improve and participate <br>
in gaming process
</li>
       <li>Class Tournaments</li>
    </ul>
  </div>
</section>

<section class="mental-health">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2>Mental Health <br> Challenges</h2>
          <ul>
            <li class="one">
              <span>56%</span>
              <p>Adults in America with mental illness 
claim they do not receive any 
treatment(1)</p>
            </li>
            <li class="two">
              <span>8%</span>
              <p>Youth have no access to mental 
health services through private 
insurance(2)</p>
            </li>
            <li class="three">
              <span>8.2%</span>
              <p>From 2012 to 2015 rates of depression 
have increased from 5.9% to 8.2%</p>
            </li>
            <li class="four">
              <span>76%</span>
              <p>Youth with severe depression have 
no or insufficient treatment(2). </p>
            </li>
          </ul>
        </div> <!-- col-md-6 -->
        <div class="col-md-6">
          <img class="mw-100" src="assets/img/mental-health.png">
        </div> <!-- col-md-6 -->
      </div> <!-- row -->
    </div>
</section> <!-- mental-health -->

<section class="covid19">
  <div class="container">
    <h2>COVID-19 Worsening Mental Health</h2>
    <div class="row">
      <div class="col-md-6">
        <div class="img">
          <img src="assets/img/Group32.png">
        </div>
        <p>42% of people reported anxiety or depression, up from just 11% the prior year(3).</p>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <div class="img">
          <img src="assets/img/Group36.png">
        </div>
        <p>84% of Americans reported feeling anxious at least a few days per week since the onset of the pandemic(5)</p>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <div class="img">
          <img src="assets/img/Group34.png">
        </div>
        <p>45% of Americans claim COVID-19 crisis was harming their mental health(4).</p>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <div class="img">
          <img src="assets/img/Group37.png">
        </div>
        <p>Use of crisis hotlines has skyrocketed (8000% increase in Los Angeles)(6).</p>
      </div> <!-- col-md-6 -->
    </div> <!-- row -->
  </div>
</section>

<section class="hoursolution">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h2>Our Solution to Tackle Mental Health</h2>
        <p>Community Happy Space (CHS)</p>
        <p>Assisting our users (parents, kids, and caregivers) on their healing journey by helping build a positive mindset and alleviate their inner turmoil.</p>
        <ul>
          <li>Offering Peer-to-Peer support to create a supportive</li>
          <li>community through safe and secure platform. </li>
          <li>Onboarding licensed therapists to support and assist.</li>
          <li>Use of AI to refer therapists based on user location, 
preference, and therapists’ availability.</li>
<li>Self learning digital tool-kits to help users find relief 
on their own.</li>
        </ul>
      </div> <!-- col-md-6 -->
      <div class="col-md-6">
        <img class="mw-100" src="assets/img/Group62.png">
      </div> <!-- col-md-6 -->
    </div> <!-- row -->
  </div>
</section> <!-- our-solution -->

 </div> <!-- mainone-page -->
 <app-footer></app-footer>