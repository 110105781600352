import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-quiz-detail',
  templateUrl: './quiz-detail.component.html',
  styleUrls: ['./quiz-detail.component.css']
})
export class QuizDetailComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) { }

  pod_id: any;
  quiz_id: any;
  quizDetail: any;
  ngOnInit(): void {
  	this.pod_id=this.route.snapshot.params['id'];
  	this.quiz_id=this.route.snapshot.params['qid'];
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/viewQuiz/'+this.quiz_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.quizDetail = data;
        }

      );
  }

}
