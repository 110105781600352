
<div class="vpod-body">
  <div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}" class="active">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->

<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">{{notesData.name}}</h3>
    <a class="btn btn-primary d-inline-block mb-2" routerLink="/teacher/add-note/{{pod_id}}" data-toggle="tab"><i class="fa fa-plus" aria-hidden="true"></i> Upload Notes</a>
</div>
	<div class="vpod-body-inner">
          <div class="card-body pt-0">
            <div class="mtable">
                <table datatable class="table">
                  <thead>
                  <tr>
                    <th>Sr#</th>
                    <th>Name</th>
                    <th>Series</th>
                    <th>Uploaded on</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let note of notesData.notes;let i = index">
                    <td>{{i + 1}}</td>
                    <td>{{note.title}}</td>
                    <td>{{note.ctitle}}</td>
                    <td>{{note.created_at}}</td>
                    <td>
                      <a routerLink="/teacher/note-detail/{{note.pod_id}}/{{note.id}}" class="btn btn-primary mr-1">View</a>
                    </td>
                  </tr>
                  
                  
                  </tbody>
                </table>
              </div> <!--- mtable --->
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->