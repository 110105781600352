<div class="vpod-body">

  <div class="vpod-body-inner pt-2">
    <div class="">

    <div class="report-total-pod w-100">
      <div class="rpod-header d-flex align-items-center mb-3">
        <h4>New Parents</h4>
        <div class="total ml-3 border p-2 rounded bg-secondary">
          <p class="h5 m-0 text-white">{{adminData.parents.length}}</p>
        </div>
        </div>
        <div class="mtable">
<table datatable id="manage_teachers" class="table">
<thead>
<tr>
  <th>sr</th>
  <th>Pic</th>
  <th>First Name</th>
  <th>City</th>
  <th>Zipcode</th>
  <th>Action</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let parent of adminData.parents; let i = index">
  <td>{{ i + 1 }}</td>
  <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{parent.image}}"></td>
  <td>{{parent.fname}}</td>
  <td>{{parent.city}}</td>
  <td>{{parent.zipcode}}</td>
  <td>
    <a routerLink="/admin/parent-detail/{{parent.id}}" class="btn btn-primary">View Detail</a>
  </td>
</tr>

</tbody>
</table>
</div> <!--- mtable ---->
    </div> <!-- report-total-pod -->
    <div class="report-total-pod w-100">
      <div class="rpod-header d-flex align-items-center">
        <h4>New Teachers</h4>
        <div class="total ml-3 border p-2 rounded bg-secondary">
          <p class="h5 m-0 text-white">{{adminData.teachers.length}}</p>
        </div>
        </div>
        <div class="mtable">
        <table datatable class="table mt-2">
<thead>
<tr>
  <th>sr</th>
  <th>Pic</th>
  <th>Name</th>
  <th>City</th>
  <th>Zipcode</th>
  <th>Action</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let teacher of adminData.teachers; let t = index">
  <td>{{ t + 1 }}</td>
  <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{teacher.image}}"></td>
  <td>{{teacher.fname}} {{teacher.lname}}</td>
  <td>{{teacher.city}}</td>
  <td>{{teacher.zipcode}}</td>
  <td>
    <a routerLink="/admin/teacher-detail/{{teacher.id}}" class="btn btn-primary">View Detail</a>
  </td>
</tr>

</tbody>
</table>
</div> <!--- mtable --->
    </div> <!-- report-total-pod -->

    <div class="pod-piechart w-50">
      <canvas id="totalpodpiechart"></canvas>
    </div>
    
  </div> <!-- div -->
  
 
    

  </div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->