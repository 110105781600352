<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<!-- body -->
                  <div class="vpod-body">
                    <div class="vpod-nav">
                        <ul>
                            <li><a routerLink="/teacher/pod-detail/{{pod_id}}" class="active">Start POD</a></li>
                            <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
                            <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
                            <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
                            <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
                            <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
                            <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
                            <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
                        </ul>
                    </div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3 class="text_color3">{{podDetail.name}}</h3>
                  </div>
                    <div class="vpod-body-inner">
              <div class="allow-broadcast border rounded p-2 text-center">
                            <img src="../assets/img/start-pod.png">
                            <p class="d-block mb-3">Allow Webcam to broadcast the POD to CLV</p>
                            <button class="btn btn-primary start-pod" (click)="startBroadcasting()">Start Broadcasting</button>
                        </div>
                        <div class="teacher allow-broadcast border rounded p-2 start">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="pod-camera">
                                        <div class="img">
                                            <img class="mw-100" src="../assets/img/video-call.jpg">
                                            <div class="min-camera">
                                                <img src="../assets/img/video-min-call.jpg">
                                            </div>
                                            <ul>
                                                <li><a routerLink="javascript:void(0)" title="Voice"><i class="fa fa-microphone text-white" aria-hidden="true"></i></a></li>
                                                <li><a routerLink="javascript:void(0)" title="Camera"><i class="fa fa-video-camera text-white" aria-hidden="true"></i></a></li>
                                                <li><a routerLink="javascript:void(0)" title="Window Screen"><i class="fa fa-desktop text-white" aria-hidden="true"></i></a></li>
                                                <li><a routerLink="javascript:void(0)" title="Stop POD"><i class="fa fa-stop-circle-o text-white" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                           <!-- <button class="btn-primary btn mr-2 mb-2 mt-2 text-w">Board</button> -->
                                           <div class="share-link mt-4">
                                               <h4>Share POD Link</h4>
                                               <ul class="mt-3">
                                                   <li><a routerLink="#" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                   <li><a routerLink="#" class="whatsapp"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                   <li><a routerLink="#" class="instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                               </ul>
                                           </div>
                                           <div class="discussion mt-3">
                                               <h2 class="sound_bubble_demoregular">Discussion</h2>
                                               <div class="chat-box border bg-white border rounded">
                                                <div class="inner p-3">
                                                   <div class="left">

                                                       <div class="img">
                                                           <img src="../assets/img/cat.jpg">
                                                       </div>
                                                       <div class="content">
                                                           <h2>John Doe, 5:32 PM</h2>
                                                           <p>Hlo</p>
                                                       </div>
                                                   </div>
                                                   <div class="left">
                                                       <div class="img">
                                                           <img src="../assets/img/cat.jpg">
                                                       </div>
                                                       <div class="content">
                                                           <h2>John Doe, 5:32 PM</h2>
                                                           <p>How Are you</p>
                                                       </div>
                                                   </div>
                                                   <div class="right">
                                                       <div class="content">
                                                           <h2>5:33 PM</h2>
                                                           <p>I am fine</p>
                                                       </div>
                                                       <div class="img">
                                                           <img src="../assets/img/cat.jpg">
                                                       </div>
                                                   </div>
                                               </div> <!-- inner -->
                                               <div class="form">
                                                   <form>
                                                       <input type="text" name="chat" placeholder="Type a message">
                                                       <button><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                                                   </form>
                                               </div>
                                               </div>
                                           </div>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                  <h4 class="mb-3">Students who join POD</h4>
                                    <div class="related-call">

                                        <div class="vquiz-student-box mb-3 bg-light border rounded p-2 text-secondary">
                                   <div class="img-box d-flex align-items-center w-100">
                                     <div class="img mr-2">
                                       <img class="mw-100" src="../assets/img/cat.jpg">
                                     </div>
                                     <div>
                                     <h6>John Doe</h6>
                                     <ul>
                                         <li><a routerLink="javascript:void(0)" title="Voice" class="red"><i class="fa fa-microphone" aria-hidden="true"></i></a></li>
                                        <li><a routerLink="javascript:void(0)" title="Camera" class="green"><i class="fa fa-video-camera" aria-hidden="true"></i></a></li>
                                        <li><a routerLink="javascript:void(0)" title="Window Screen"><i class="fa fa-desktop" aria-hidden="true"></i></a></li>
                                     </ul>
                                     </div>
                                   </div>
                                </div> <!-- vquiz-student-box -->
                                <div class="vquiz-student-box mb-3 bg-light border rounded p-2 text-secondary">
                                   <div class="img-box d-flex align-items-center w-100">
                                     <div class="img mr-2">
                                       <img class="mw-100" src="../assets/img/cat.jpg">
                                     </div>
                                     <div>
                                     <h6>Jimee</h6>
                                     <ul>
                                         <li><a routerLink="javascript:void(0)" title="Voice" class="red"><i class="fa fa-microphone" aria-hidden="true"></i></a></li>
                                        <li><a routerLink="javascript:void(0)" title="Camera" class="green"><i class="fa fa-video-camera" aria-hidden="true"></i></a></li>
                                        <li><a routerLink="javascript:void(0)" title="Window Screen"><i class="fa fa-desktop" aria-hidden="true"></i></a></li>
                                     </ul>
                                     </div>
                                   </div>
                                </div> <!-- vquiz-student-box -->
                                <div class="vquiz-student-box mb-3 bg-light border rounded p-2 text-secondary">
                                   <div class="img-box d-flex align-items-center w-100">
                                     <div class="img mr-2">
                                       <img class="mw-100" src="../assets/img/cat.jpg">
                                     </div>
                                     <div>
                                     <h6>Tony Stark</h6>
                                     <ul>
                                         <li><a routerLink="javascript:void(0)" title="Voice" class="red"><i class="fa fa-microphone" aria-hidden="true"></i></a></li>
                                        <li><a routerLink="javascript:void(0)" title="Camera" class="green"><i class="fa fa-video-camera" aria-hidden="true"></i></a></li>
                                        <li><a routerLink="javascript:void(0)" title="Window Screen"><i class="fa fa-desktop" aria-hidden="true"></i></a></li>
                                     </ul>
                                     </div>
                                   </div>
                                </div> <!-- vquiz-student-box -->
                                    </div>
                                </div>
                            </div>
                        </div>
        
        
        
        
        
        
                        
                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->

<p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  