import { NgModule } from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { TeacherprofileComponent } from './components/teacherprofile/teacherprofile.component';
import { TeachersignupComponent } from './components/teachersignup/teachersignup.component';
import { CaregiverprofileComponent } from './components/caregiverprofile/caregiverprofile.component';
import { CaregiversignupComponent } from './components/caregiversignup/caregiversignup.component';
import { ParentprofileComponent } from './components/parentprofile/parentprofile.component';
import { ParentsignupComponent } from './components/parentsignup/parentsignup.component';
import { PodComponent } from './components/pod/pod.component';
import { SinglepodComponent } from './components/singlepod/singlepod.component';
import { TeacherviewComponent } from './components/teacherview/teacherview.component';
import { StudentviewComponent } from './components/studentview/studentview.component';
import { JoinpodComponent } from './components/joinpod/joinpod.component';
import { EmailvarificationComponent } from './components/emailvarification/emailvarification.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { TeacherProcessingComponent } from './components/teacher-processing/teacher-processing.component';
import { TeacherRejectedComponent } from './components/teacher-rejected/teacher-rejected.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EmailProcessingComponent } from './components/email-processing/email-processing.component';

const routes: Routes = [
{
    path: '', component: LandingPageComponent,
    children: [
    	{path: 'signin', component: SigninComponent},
		{path: 'signup', component: SignupComponent},
		{path: 'forgotpassword', component: ForgotpasswordComponent},
		{path: 'teacher-complete-profile', component: TeacherprofileComponent},
		{path: 'teacher-signup', component: TeachersignupComponent},
		{path: 'caregiver-complete-profile', component: CaregiverprofileComponent},
		{path: 'caregiver-signup', component: CaregiversignupComponent},
		{path: 'parent-complete-profile', component: ParentprofileComponent},
		{path: 'parent-signup', component: ParentsignupComponent},
		{path: 'pod', component: PodComponent},
		{path: 'single-pod', component: SinglepodComponent},
		{path: 'view-teacher', component: TeacherviewComponent},
		{path: 'view-student', component: StudentviewComponent},
		{path: 'join-pod', component: JoinpodComponent},
		{path: 'email-varification', component: EmailvarificationComponent},
		{path: 'admin-login', component: AdminLoginComponent},
		{path: 'processing', component: TeacherProcessingComponent},
		{path: 'rejected', component: TeacherRejectedComponent},
		// {path: 'reset-password:id:token', component: ResetPasswordComponent},
		{
          path:'reset-password/:id/:token',
          component:ResetPasswordComponent
       },
		{path: 'email-processing', component: EmailProcessingComponent}
    ]
  }
	
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
