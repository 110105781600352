import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-studentview',
  templateUrl: './studentview.component.html',
  styleUrls: ['./studentview.component.css']
})
export class StudentviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
