<a routerLink="/parent/select-student-preview/{{game_id}}/{{student_id}}" class="h5 text-dark d-inline-blok mt-3"><img style="max-width:25px;margin-right:10px;" src="../assets/img/arrow-left.png"> Competition</a>
<div id="countdown" class="mb-3">
  <div id="countdown-number"></div>
  <svg>
    <circle r="18" cx="20" cy="20"></circle>
  </svg>
</div>
<div class="counter sound_bubble_demoregular mb-3">
	<h2 class="text-center" style="color:#262262;">{{minutes}}:{{timeLeft}}</h2>
</div>

<h1 class="sound_bubble_demoregular text-center">Select Big</h1>
<div class="select-right-ans">
	<div class="row">
		<div class="col-md-6 col-sm-6">
			<div class="inner w-100 d-flex align-items-center justify-content-center" (click)="selectRightans($event)">
				<img src="../assets/img/big.png">
			</div> <!-- inner -->
		</div> <!-- col-md-6 -->
		<div class="col-md-6 col-sm-6">
			<div class="inner w-100 d-flex align-items-center justify-content-center" (click)="selectRightans($event)">
				<img src="../assets/img/small.png">
			</div> <!-- inner -->
		</div> <!-- col-md-6 -->
	</div> <!-- row -->
	<div class="text-center mt-5">
		<a routerLink="/parent/game-result/12/12" class="next-btn" data-toggle="modal" data-target="#exampleModal">Next</a>
	</div> <!-- text-center -->
	<div class="clv-pagination d-flex align-items-center justify-content-center mt-5">
		<ul class="d-flex align-items-center">
			<li>
				<a routerLink="#"><div class="icon"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i></div>01</a>
				<a routerLink="#"><div class="icon"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i></div>02</a>
				<a routerLink="#" class="active"><div class="icon"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i></div>03</a>
				<a routerLink="#"><div class="icon"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i></div>04</a>
			</li>
		</ul>
	</div>
</div> <!-- select-right-ans -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <img class="mw-100" src="../assets/img/correct.png">
  </div>
</div>

