 <!-- Footer -->
<footer class="site-footer">
      <div class="container">
            <div class="row">
                  <div class="col-md-4">
                        <div class="box">
                          <a href="#"><img class="mw-100" src="assets/img/logo.png"></a>
                          <ul class="address">
                            <li><i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:(646) 389-2431">(646) 389-2431</a></li>
                            <li><i class="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:chris@communitylearningvillage.com">chris@communitylearningvillage.com</a></li>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> 324 South Wilmington Street, #285 Raleigh, NC</li>
                          </ul>
            <ul class="footer-social d-flex align-items-center mt-3">
              <li><a href="#" class="p-0 h4 mr-3"><i class="fa fa-facebook-official" aria-hidden="true"></i></a></li>
              <li><a href="#" class="p-0 h4"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            </ul>
            </div>
                  </div><!-- col-md-3 -->
                  <div class="col-md-4">
                        <div class="box">
                  <ul class="links">
                        <li><a href="#">Lorem ipsum dolor sit amet, consectetur</a></li>
                        <li><a href="#">adipiscing elit, sed do eiusmod tempor incididunt</a></li>
                        <li><a href="#">ut labore et dolore magna aliqua. Quis ipsum </a></li>
                        <li><a href="#">suspendisse ultrices gravida. Risus commodo viverra</a></li>
                        <li><a href="#">maecenas accumsan lacus vel facilisis. </a></li>
                        <li><a href="#">ut labore et dolore magna aliqua. Quis ipsum  </a></li>
                        <li><a href="#">suspendisse ultrices gravida. Risus commodo viverra </a></li>
                        <li><a href="#">maecenas accumsan lacus vel facilisis.  </a></li>
                  </ul>
            </div>
                  </div> <!-- col-md-3 -->
                  <div class="col-md-4">
                        <form>
                          <label>Subscribe Now</label>
                          <input type="email" name="email" placeholder="abc@example.com">
                          <button>Subscribe</button>
                        </form>
                        <p class="font-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                  </div><!-- col-md-3 -->
            </div> <!-- row -->
      </div>
      <div class="copy-right">
        <p>Copyrights © 2020 All Rights Reserved</p>
      </div>
</footer>