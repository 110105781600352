import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-pods',
  templateUrl: './pods.component.html',
  styleUrls: ['./pods.component.css']
})
export class PodsComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) { }

  podData: any;
  ngOnInit(): void {
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/parentPod',{headers}).subscribe(
        data => {
          console.log(data);
          this.podData = data;
        }

      );
  }

}
