import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-pending-pod-detail',
  templateUrl: './pending-pod-detail.component.html',
  styleUrls: ['./pending-pod-detail.component.css']
})
export class PendingPodDetailComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) {

     }

  pendingPod: any;
  pod_id: any;
  ngOnInit(): void {
  	this.pod_id=this.route.snapshot.params['id'];
  	var formData: any = new FormData();
	formData.append("email", sessionStorage.getItem('admin_email'));
    formData.append("password", sessionStorage.getItem('admin_password'));
    // var headers = { 'email':  sessionStorage.getItem('admin_email')};
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/viewPod/'+this.pod_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.pendingPod = data;
        }

      );
  }

  message: any;
  requestJoinPod() {

    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/teacher/requestJoinPod/'+this.route.snapshot.params['id'], {headers}).subscribe(
      response => {
        console.log(response)
        this.message = response;
      },
      (error) => {
        console.log(error);
      },
    )
  }

}
