<div class="teacher-page">

  <div class="teacher-top-sectionn">
    <div class="container">
      <div class="inner d-flex align-items-end justify-content-between pl-5 pr-5">
        <div class="left d-flex align-items-start">
          <div class="img">
            <img class="mw-100" src="assets/img/img_avatar.png">
          </div>
          <div class="content pl-4 pt-4 mt-2">
            <h1 class="text-white font_family_sound_bubble display-5 mb-2">Greer Grant Nelson</h1>
            <p class="text-white h5 lead"><i class="fa fa-map-marker" aria-hidden="true"></i> South Lincoln Cemetery</p>
            <a href="#" class="text-white"><i class="fa fa-check-circle text_color2" aria-hidden="true"></i> Varified By CLV Team</a>
          </div>

        </div> <!-- left -->
        <div class="right pb-4">
          <a href="tel:+962234328765" class="link d-inline-block"><i class="fa fa-mobile lead mr-1" aria-hidden="true"></i> Contact</a>
        </div> <!-- right -->
      </div> <!-- inner -->
    </div> <!-- container -->
  </div> <!-- teacher-top-sectionn -->

<div class="teacher-bottom-section pt-5 pb-5 mt-5">
    <div class="container d-flex align-items-start justify-content-between">
    <div class="left">
      <h2 class="font_family_sound_bubble mb-3">About</h2>
      <p class="border p-2 rounded" style="line-height:1.3;font-family: 'Gotham Rounded Light';">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      <!--  -->
      <h2 class="font_family_sound_bubble mb-3 mt-3">Skills</h2>
      <div class="tags-section border rounded p-3">
        <a href="#">Physics</a>
        <a href="#">Math</a>
        <a href="#">Science</a>
      </div>
      <!--  -->
      <h2 class="font_family_sound_bubble mb-3 mt-3">Schedule</h2>
      <div class="border rounded p-3">
        <p>Monday to Saturday</p>
        <p class="mt-1">8am to 3pm</p>
      </div>
    </div> <!-- left -->
    <div class="right mt-5">
    <!--  -->
    <div class="border rounded p-3">
      <h4 class="font_family_sound_bubble">Subject</h4>
      <p class="mt-3"><i class="fa fa-file mr-1" aria-hidden="true"></i> Math</p>
    </div>
    </div> <!-- right -->
  </div> <!-- container -->
</div>

</div> <!-- teacher-page -->