<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="chs-main-page">
	<div class="d-flex justify-content-between align-items-start">
	<h1 class="sound_bubble_demoregular">Groups</h1>
	<a routerLink="/teacher/create-group" class="btn btn-primary btn-lg">Add New</a>
	</div>
	<div class="chs-inner">
		<h2>Your Group</h2>

		<div class="">
			<ngx-slick-carousel class="boxes" 
  #slickModal="slick-carousel" 
  [config]="slideConfig">
  <div class="box" *ngFor="let group of groupData.groupsData" ngxSlickItem>
<div class="img">
	<img src="{{siteurl}}/{{group.group.cover}}">
</div> <!-- img -->
<div class="content">
<h3>{{group.group.title}}</h3>
<div class="members">
	<ul>
		<ng-container *ngFor="let gmem of group.members">
			<li style="background:url('{{siteurl}}/{{gmem.image}}');"></li>
		</ng-container>
		<li class="plusnumber" *ngIf="group.total == 0">{{group.total}}</li>
		<li class="plusnumber" *ngIf="group.members_count != 0">+{{group.members_count}}</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="/teacher/view-group/{{group.group.id}}">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
</div> <!-- box -->
  <div class="box" ngxSlickItem>
</div> <!-- box -->
  <div class="box" ngxSlickItem>
</div> <!-- box -->
  <div class="box" ngxSlickItem>
</div> <!-- box -->
</ngx-slick-carousel>
			
			
		</div> <!-- boxes -->
	</div> <!-- chs-inner -->
		<div class="chs-inner">
		<h2>Suggested Group</h2>
		<div class="">
						<ngx-slick-carousel class="boxes" 
  #slickModal="slick-carousel" 
  [config]="slideConfig">
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
</ngx-slick-carousel>
		</div> <!-- boxes -->
	</div> <!-- chs-inner -->
	<div class="chs-inner">
		<h2>Recently Viewed Group</h2>
		<div class="">
						<ngx-slick-carousel class="boxes" 
  #slickModal="slick-carousel" 
  [config]="slideConfig">
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
  <div class="box" ngxSlickItem>
<div class="img">
	<img src="../../assets/img/chs1.png">
</div> <!-- img -->
<div class="content">
<h3>Hundreds and Thousands</h3>
<div class="members">
	<ul>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li style="background:url('../../assets/img/chs1.png');"></li>
		<li class="plusnumber">+7</li>
	</ul>
	<h4>Members</h4>
</div> <!-- members -->
<a routerLink="#">View</a>
</div> <!-- content -->
</div> <!-- box -->
</ngx-slick-carousel>
		</div> <!-- boxes -->
	</div> <!-- chs-inner -->
</div> <!-- chs-main-page -->