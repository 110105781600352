import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DataTablesModule } from 'angular-datatables';

import { TeacherRoutingModule } from './teacher-routing.module';
import { TeacherComponent } from './teacher.component';
import { TeacherSidebarComponent } from './teacher-sidebar/teacher-sidebar.component';
import { TeacherHomeComponent } from './teacher-home/teacher-home.component';
import { ManagePodComponent } from './manage-pod/manage-pod.component';
import { PendingPodDetailComponent } from './pending-pod-detail/pending-pod-detail.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ParentRequestJoinPodComponent } from './parent-request-join-pod/parent-request-join-pod.component';
import { PodDetailComponent } from './pod-detail/pod-detail.component';
import { NoteDetailComponent } from './note-detail/note-detail.component';
import { CategoriesComponent } from './categories/categories.component';
import { SeriesComponent } from './series/series.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddSeriesComponent } from './add-series/add-series.component';
import { AssignmentDetailComponent } from './assignment-detail/assignment-detail.component';
import { NotesComponent } from './notes/notes.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { AddNoteComponent } from './add-note/add-note.component';
import { AddAssignmentComponent } from './add-assignment/add-assignment.component';
import { BoardComponent } from './board/board.component';
import { StudentsComponent } from './students/students.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { StudentCollaborateComponent } from './student-collaborate/student-collaborate.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizDetailComponent } from './quiz-detail/quiz-detail.component';
import { AddQuizComponent } from './add-quiz/add-quiz.component';
import { ReportsComponent } from './reports/reports.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { AssignmentSubmissionComponent } from './assignment-submission/assignment-submission.component';
import { TeacherOnDemandsComponent } from './teacher-on-demands/teacher-on-demands.component';
import { ChsComponent } from './chs/chs.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';
import { EditAssignmentComponent } from './edit-assignment/edit-assignment.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { EditSeriesComponent } from './edit-series/edit-series.component';


@NgModule({
  declarations: [
    TeacherComponent,
    TeacherSidebarComponent,
    TeacherHomeComponent,
    ManagePodComponent,
    PendingPodDetailComponent,
    AlertsComponent,
    ParentRequestJoinPodComponent,
    PodDetailComponent,
    NoteDetailComponent,
    CategoriesComponent,
    SeriesComponent,
    AddCategoryComponent,
    AddSeriesComponent,
    AssignmentDetailComponent,
    NotesComponent,
    AssignmentsComponent,
    AddNoteComponent,
    AddAssignmentComponent,
    BoardComponent,
    StudentsComponent,
    StudentDetailComponent,
    StudentCollaborateComponent,
    QuizComponent,
    QuizDetailComponent,
    AddQuizComponent,
    ReportsComponent,
    AttendanceComponent,
    AssignmentSubmissionComponent,
    TeacherOnDemandsComponent,
    ChsComponent,
    CreateGroupComponent,
    ViewGroupComponent,
    EditAssignmentComponent,
    EditCategoryComponent,
    EditSeriesComponent
  ],
  imports: [
    CommonModule,
    TeacherRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    SlickCarouselModule,
    DataTablesModule
  ]
})
export class TeacherModule { }
