<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
      <div class="main-sidebar" id="main-sidebar">
        <a class="mw-100 pb-3 border-bottom text-center d-block" routerLink="#"><img src="assets/img/logo.png"></a>
            <ul class="main-menu" >
                  <li><a routerLink="/parent" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/home-icon.png"> Home</a></li>
                  <li><a ng-class="{active: currentPath() == '/parent/pods'}" routerLink="/parent/pods" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> PODS</a></li>
                  <li><a routerLink="/parent/manage-student" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Manage Student</a></li>
                  <li><a routerLink="/parent/teacher-demands" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Teacher On Demands</a></li>
                  <li><a routerLink="/parent/tournaments" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Tournaments</a></li>
                  <li><a routerLink="/parent/team-preparation" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/manage-pod-icon.png"> Team Prepration</a></li>
                  <li><a routerLink="/parent/chs" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/happiness.png"> CHS</a></li>
                  <li><a routerLink="/parent/alerts" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/notiification-icon.png"> Alerts</a></li>
                  <li><a routerLink="/parent/payments" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/payment-icon.png"> Payments</a></li>
                  <li><a routerLink="/parent/gamification" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/gamification-icon.png"> Gamification</a></li>
                  <li><a routerLink="/parent/setting" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="../assets/img/setting-icon.png"> Settings</a></li>
                  <!-- <li><a (click)="menuClick($event)" routerLink="#"><img src="../assets/img/user.png"> John Doe</a></li> -->
                  <li><a routerLink="#" (click)="logout()"><img src="../assets/img/logout.png"> Logout</a></li>
            </ul>
      </div>
      