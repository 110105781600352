import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';
import Chart from 'chart.js/auto';
import * as moment from 'moment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) { }

  pod_id: any;
  totalPOD: any;
  totalPODPiechart: any;
  totalStudentiechart: any;
  ngOnInit(): void {
  	this.pod_id=this.route.snapshot.params['id'];

    // var canvas = document.getElementById('totalPOD');
    var tPOD = <HTMLCanvasElement> document.getElementById('totalPOD');
    this.totalPOD = tPOD.getContext('2d');
    var myChart = new Chart(this.totalPOD, {
    type: 'bar',
    data: {
    labels: ["English", "Math", "Science", "Social Study", "Physics", "Biology"],
    datasets: [{
    label: '# Total POD 22',
    data: [6, 4, 3, 2, 2, 3],
      backgroundColor: [
      'rgba(178, 56, 133, 0.2)',
      'rgba(251, 172, 24, 0.2)',
      'rgba(52, 51, 153, 0.2)',
      'rgba(38, 34, 98, 0.2)',
      'rgba(200, 153, 101, 0.2)',
      'rgba(77, 83, 96, 0.2)'
      ],
      borderColor: [
      'rgba(178,56,133,1)',
      'rgba(251, 172, 24, 1)',
      'rgba(52, 51, 153, 1)',
      'rgba(38, 34, 98, 1)',
      'rgba(200, 153, 101, 1)',
      'rgba(77, 83, 96, 1)'
      ],
      borderWidth: 1
      }]
    },
    options: {
    scales: {
    // yAxes: [{
    // ticks: {
    // beginAtZero: true
    // }
    // }]
    }
    }
    });
    // total pieChart end

    var tPODPiechart = <HTMLCanvasElement> document.getElementById('totalpodpiechart');
    this.totalPODPiechart = tPODPiechart.getContext('2d');
    var myLineChart = new Chart(this.totalPODPiechart, {
    type: 'doughnut',
    data: {
    labels: ["English", "Math", "Science", "Social Study", "Physics", "Biology"],
    datasets: [{
    data: [12, 19, 3, 5, 2, 3],
    backgroundColor: ["#b23885", "#fbac18", "#343399", "#262262", "#c89965", "#4D5360"],
    hoverBackgroundColor: ["#d257a5", "#f4b337", "#3b3b9f", "#363371", "#ddb990", "#616774"]
    }]
    },
    options: {
    responsive: true
    }
    });

    // total pod pie chart end 

    var tStudentPiechart = <HTMLCanvasElement> document.getElementById('totalstudentpiechart');
    this.totalStudentiechart = tStudentPiechart.getContext('2d');
    var myLineChart = new Chart(this.totalStudentiechart, {
    type: 'doughnut',
    data: {
    labels: ["Male", "Female"],
    datasets: [{
    data: [2020, 2700],
    backgroundColor: ["#b23885", "#fbac18"],
    hoverBackgroundColor: ["#d257a5", "#f4b337"]
    }]
    },
    options: {
    responsive: true
    }
    });

    // total student pie chart end
    
    new Chart("canvas-foobar", {
      type: "bar",
      data: {
        labels: [
        moment("2018-01-01"),
        moment("2018-02-01"),
        moment("2018-03-01"),
        moment("2018-04-01"),
        moment("2018-05-01"),
        moment("2018-06-01")],
        datasets: [

        {
          type: "line",
          label: "monthly data",
          borderColor: "#4bc0c0",
          data: [
            { x: moment("2018-01-01"), y: 15 },
            { x: moment("2018-02-01"), y: 28 },
            { x: moment("2018-03-01"), y: 6 },
            { x: moment("2018-04-01"), y: 21 },
            { x: moment("2018-05-01"), y: 10 },
            { x: moment("2018-06-01"), y: 11 }
          ]
        }
      ]
    },
      options: {
        responsive: true,
            scales: {
          x: {
            type: 'linear',
            // distribution: "linear"
          }
        }
        // maintainAspectRatio: false,

        // tooltips: {
          // mode: "x",
          // intersect: false
        // },

        // scales: {
          // xAxes: 
          // [
            // {
              // type: "time",
              // distribution: "linear"
            // }
          // ]
        // }
      }
    });
  }

}
