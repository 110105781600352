<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}" class="active">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
        <h3 class="text_color3">{{quizDetail.pod.name}}</h3>
  </div>
	<div class="vpod-body-inner">
<h4 class="mb-2 sound_bubble_demoregular">Quiz title: {{quizDetail.quiz.title}}</h4>

          <div class="box mt-2 view-submission d-flex align-items-start justify-content-between">

                <div class="left">
                  <h5 class="mb-2 sound_bubble_demoregular">Quiz Description</h5>
                  <p class="font-weight-light">{{quizDetail.quiz.description}}</p>

                  <div class="question mt-4">
                    <div class="box mb-4" *ngFor="let question of quizDetail.questions">
                      <h5>{{question.statement}}</h5>
                      <ul class="d-flex align-items-center mt-2">
                        <li class="h6 text-secondary mr-5" *ngFor="let option of question.options">{{option}}</li>
                      </ul>
                      <ul class="d-flex align-items-center">
                        <li class="h6 text-success mr-3"><i class="fa fa-check mr-1" aria-hidden="true"></i> {{question.answer}}</li>
                        <li class="h6 text-secondary alert-secondary p-2">Marks: {{question.marks}}</li>
                      </ul>
                      
                    </div> <!-- box -->
                    
                  </div>

                  </div>
                  <div class="right">
                    <div class="clv_bg rounded pt-3 pb-3 text-center text-white mb-3">
                      <h5 class="mb-2">Total Marks</h5>
                      <h1 class="text_color4">{{quizDetail.quiz.total_marks}}</h1>
                    </div>
                    <div class="border rounded p-2">
                    <div class="d-flex align-items-center justify-content-between mt-2">
                      <h6>Student Name</h6>
                      <h6>Marks</h6>
                    </div>

                      <a href="#" class="vquiz-student-box mt-3 d-flex align-items-center justify-content-between bg-light border rounded p-2 text-secondary" *ngFor="let student of quizDetail.students">
                         <div class="img-box d-flex align-items-center">
                           <div class="img mr-2">
                             <img class="mw-100 w-100 h-100" src="{{siteurl}}/{{student.img}}">
                           </div>
                           <h6>{{student.fname}} {{student.lname}}</h6>
                         </div>
                         <h6>{{student.marks}}</h6>
                      </a> <!-- a -->
                    
                    </div>

                  </div> <!-- right -->
                </div>
            

                    </div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->