import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-manage-caregivers',
  templateUrl: './manage-caregivers.component.html',
  styleUrls: ['./manage-caregivers.component.css']
})
export class ManageCaregiversComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService) { }

  ngOnInit(): void {
  }

}
