<div class="join-pod-page">
<div class="signup-page">
  <div class="inner">
    <h2 class="cpod-title"> let's have a quick check </h2>
      <div class="questions border rounded p-3">
        <div class="box d-flex align-items-center justify-content-between pb-3 border-bottom">
          <div class="content">
              <h6>Do  you have cough?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="cough_yes" name="cough">
              <label class="custom-control-label" for="cough_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="cough_no" name="cough">
              <label class="custom-control-label" for="cough_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->
        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do  you have colds?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="colds_yes" name="cold">
              <label class="custom-control-label" for="colds_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="colds_no" name="cold">
              <label class="custom-control-label" for="colds_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->
        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you having Diarhea?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="diarhea_yes" name="diarhea">
              <label class="custom-control-label" for="diarhea_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="diarhea_no" name="diarhea">
              <label class="custom-control-label" for="diarhea_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have sore throat?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="throat_yes" name="throat">
              <label class="custom-control-label" for="throat_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="throat_no" name="throat">
              <label class="custom-control-label" for="throat_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you experiencing MYLAGIA or Body Aches?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="mylagia_yes" name="mylagia">
              <label class="custom-control-label" for="mylagia_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="mylagia_no" name="mylagia">
              <label class="custom-control-label" for="mylagia_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have a headache?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="headache_yes" name="headache">
              <label class="custom-control-label" for="headache_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="headache_no" name="headache">
              <label class="custom-control-label" for="headache_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have fever (Temperature 37.8 C and above)</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="fever_yes" name="fever">
              <label class="custom-control-label" for="fever_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="fever_no" name="fever">
              <label class="custom-control-label" for="fever_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you having difficulty breathing?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="breathing_yes" name="breathing">
              <label class="custom-control-label" for="breathing_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="breathing_no" name="breathing">
              <label class="custom-control-label" for="breathing_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Are you experiencing Fatigue?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="fatigue_yes" name="fatigue">
              <label class="custom-control-label" for="fatigue_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="fatigue_no" name="fatigue">
              <label class="custom-control-label" for="fatigue_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Have you traveled recently during tha past 14 days?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="traveled_yes" name="traveled">
              <label class="custom-control-label" for="traveled_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="traveled_no" name="traveled">
              <label class="custom-control-label" for="traveled_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have a travel history to a COVID-19 INFECTED AREA?</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="covid_yes" name="covid">
              <label class="custom-control-label" for="covid_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="covid_no" name="covid">
              <label class="custom-control-label" for="covid_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

        <div class="box d-flex align-items-center justify-content-between pt-3 pb-3 border-bottom">
          <div class="content">
              <h6>Do you have direct contact or is taking care of a positive COVID-19 PATIENT? (3 PTS)</h6>
          </div> <!-- content -->
          <div class="options d-flex align-items-center">
            <!-- Default unchecked -->
            <div class="custom-control custom-radio mr-3">
              <input type="radio" class="custom-control-input" id="takingc_yes" name="takingc">
              <label class="custom-control-label" for="takingc_yes"><span class="d-inline-block mt-1">yes</span></label>
            </div>

            <!-- Default checked -->
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="takingc_no" name="takingc">
              <label class="custom-control-label" for="takingc_no"><span class="d-inline-block mt-1">No</span></label>
            </div>
          </div> <!-- options -->
        </div> <!-- box -->

<a routerLink="javascript:void(0)" (click)="stepOne()" class="d-block mt-3 btn login proceed">Proceed</a>

      </div><!-- questions -->
  </div>
</div>
<div class="proceed-notification text-center signup-page hide">
  <div class="inner border rounded p-3">
    <i class="text-success mb-3 fa fa-check-circle" aria-hidden="true"></i>
    <p>Your are Good to Proeed. Please Click on Create POD Button</p>
    <a routerLink="javascript:void(0)" class="btn btn-success mt-3 create-pod" (click)="stepTwo()">Create POD</a>
    </div>
</div>

<div class="create-pod hide signup-page">
    <div class="inner">
      <h3 class="mb-3 cpod-title">Create POD</h3>
        <form>
            <input class="border rounded w-100 p-2 h6" type="name" name="name" placeholder="POD title">
            <textarea class="border rounded w-100 p-2 h6" placeholder="POD Description" name="pod_description"></textarea>
<div class="form-group">
      <label class="mb-2">POD Tags:</label><br/>
      <input type="text" name="tags" placeholder="Tags" class="tm-input form-control tm-input-info"/>
    </div>
    <input
      id="pac-input"
      class="controls border rounded w-100 p-2 h6"
      type="text"
      placeholder="PODS Location"
    />
    <div id="map"></div>

            <div class="select-lr d-flex align-items-center justify-content-between mb-2">
              <h6>Learning Style:</h6>
              <div class="select">
              <select class="form-control">
                <option>Learning Style</option>
                <option>option</option>
                <option>option</option>
                <option>option</option>
              </select>
              </div>
            </div>
            <div class="select-lr d-flex align-items-center justify-content-between">
              <h6>Subject:</h6>
              <div class="select">
              <select class="form-control">
                <option>Subject</option>
                <option>Physics</option>
                <option>Math</option>
                <option>Science</option>
              </select>
              </div>
            </div>
            <button class="create_pod_btn btn btn-primary mt-3">Create POD</button>
        </form>
    </div>
</div>
</div> <!-- join-pod-page -->