
<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}" class="active">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">{{submissionData.pod.name}}</h3>
</div>
<div class="vpod-body-inner">
  <h4 class="sound_bubble_demoregular">Assignment Title: {{submissionData.assignment.title}}</h4>
  <h4 class="mt-4 mb-2 sound_bubble_demoregular">Submissions</h4>

  <div class="card-body pt-0">
    <div class="mtable">
<table datatable class="table">
<thead>
<tr>
<th>Submitted by</th>
<th>Submitted on Date</th>
<th>Submission File</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let submission of submissionData.submissions">
<td>
<div class="img-box d-flex align-items-center">
              <div class="img mr-3">
                <img class="h-100 w-100" src="{{siteurl}}/{{submission.img}}">
              </div>
              <h4>{{submission.fname}} {{submission.lname}}</h4>
          </div> 
        </td>
<td>{{submission.created_at}}</td>
<td>
	<div *ngFor="let fil of submission.file" class="mb-2">
		<a class="d-block mb-3" href="{{siteurl}}/{{fil}}" download="{{siteurl}}/{{fil}}">
    <i class="fa fa-file" aria-hidden="true"></i> 
    {{submission.fname}} {{submission.lname}}
  </a>
  <a type="application/octet-stream" class="btn btn-primary d-inline-block mr-1" href="{{siteurl}}/{{fil}}" hreflang="{{siteurl}}/{{fil}}" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

  <a class="btn btn-success d-inline-block" href="{{siteurl}}/{{fil}}" download="{{siteurl}}/{{fil}}" target="_blank"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
	</div>
	
</td>
</tr>

</tbody>
</table>
</div> <!-- mtable -->
</div> <!-- card-body -->

  
  

</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->