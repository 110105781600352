<!-- body -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="vpod-body">
<a routerLink="/admin/manage-teachers"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
<h2 class="sound_bubble_demoregular mt-2">Teacher Detail</h2>
<div class="vpod-body-inner">
<div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

  <div class="left pr-3 border-right border-2">
    <div class="d-flex align-items-start justify-content-between flex-wrap mobile-fullw">
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
        <h6>First name</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher[0].fname}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
        <h6>Last name</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher[0].lname}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>Country</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].cname}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>State</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].sname}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>City</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].city}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>Address</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].address}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>DOB</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].dob}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>Zip Code</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].zipcode}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>E-mail</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher[0].email}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6>Certification Status</h6>
        <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].certification_status}}</p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <p class="text-muted mt-2 overflow-hidden d-flex" style="max-height:300px;"><img class="mw-100" src="{{siteurl}}/{{teacherDetail.teacher[0].image}}"></p>
      </div> <!-- div -->
      <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
        <h6 class="mb-3">CV</h6>
        <a href="{{siteurl}}/{{teacherDetail.teacher_details[0].cv}}" target="_blank"><i class="fa fa-file" aria-hidden="true"></i> {{teacherDetail.teacher[0].fname}} {{teacherDetail.teacher[0].lname}}</a>
      </div> <!-- div -->

    </div>
    <div *ngIf="teacherDetail.teacher_details[0].approved == 0">
    <div class="d-flex mt-3">
      <button class="btn btn-success mr-3" data-toggle="modal" data-target="#acceptmodel">Accept</button>
      <button class="btn btn-danger" data-toggle="modal" data-target="#rejectmodel">Reject</button>

      <!-- model-0ne start -->
      <!-- Modal -->
      <div class="modal fade modelbtnaccept" id="acceptmodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <h5 class="modal-title text-center bt1">Are you sure you want <br> to accept? </h5>
              <h5 class="modal-title text-center text-success bt2"> <i class="fa fa-check-circle-o mr-2" aria-hidden="true"></i>Accepted Successfully! </h5>
              <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
            <div class="modal-body">
              <button (click)="teacherAccept()" type="button" class="btn-success accept" data-dismiss="modal">Yes</button>
              <button type="button" data-dismiss="modal">No</button>
              
            </div>
          </div>
        </div>
      </div>
      <!-- model-one-end -->
      <!-- model-two start -->
      <!-- Modal -->
      <div class="modal fade modelbtnaccept" id="rejectmodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <h5 class="modal-title text-center bt1">Are you sure you want <br> to Reject? </h5>
              <h5 class="modal-title text-center text-danger bt2"> <i class="fa fa-times-circle-o mr-2" aria-hidden="true"></i> Rejected! </h5>
              <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
            <div class="modal-body">
              <button (click)="teacherReject()" type="button" class="btn-danger accept" data-dismiss="modal">Yes</button>
              <button type="button" data-dismiss="modal">No</button>
              
            </div>
          </div>
        </div>
      </div>
      <!-- model-two-end -->
    </div> <!-- button -->
  </div> <!-- if condition -->
    
    </div>
    <div class="right" style="width:33%;">
      <div class="covid19-box border p-3 pt-4 pb-4" style="box-shadow:0px 0px 28px 0px #e6e6e6;border-radius:15px;">
        <h6 class="text-black font-weight-bold">Covid-19</h6>
        <div class="d-flex align-items-center mt-3 mb-2">
          <ng-container *ngIf="teacherDetail.report == 'Positive'">
            <h6 class="text-danger">Positive (+)</h6>
          </ng-container>
          <ng-container *ngIf="teacherDetail.report == 'Negative'">
            <h6 class="text-success">Negative (-)</h6>
          </ng-container>
        <p class="h6 mb-0 ml-3">{{teacherDetail.score}}/36</p>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have cough?</p>
          <ng-container *ngIf="teacherDetail.covid[0].cough == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].cough == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have colds?</p>
          <ng-container *ngIf="teacherDetail.covid[0].cold == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].cold == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you having Diarhea?</p>
          <ng-container *ngIf="teacherDetail.covid[0].diarhea == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].diarhea == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have sore throat?</p>
          <ng-container *ngIf="teacherDetail.covid[0].sore_throat == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].sore_throat == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you experiencing MYLAGIA or Body Aches?</p>
          <ng-container *ngIf="teacherDetail.covid[0].mylagia == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].mylagia == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have a headache?</p>
          <ng-container *ngIf="teacherDetail.covid[0].headache == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].headache == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have fever (Temperature 37.8 C and above)</p>
          <ng-container *ngIf="teacherDetail.covid[0].fever == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].fever == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you having difficulty breathing?</p>
          <ng-container *ngIf="teacherDetail.covid[0].breathing == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].breathing == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Are you experiencing Fatigue?</p>
          <ng-container *ngIf="teacherDetail.covid[0].fatigue == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].fatigue == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Have you traveled recently during tha past 14 days?</p>
          <ng-container *ngIf="teacherDetail.covid[0].travel14days == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].travel14days == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
          <p>Do you have a travel history to a COVID-19 INFECTED AREA?</p>
          <ng-container *ngIf="teacherDetail.covid[0].travelcovid == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].travelcovid == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
          <p>Do you have direct contact or is taking care of a positive COVID-19 PATIENT? (3 PTS)</p>
          <ng-container *ngIf="teacherDetail.covid[0].contactcovid == 'yes'">
              <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
          </ng-container>
          <ng-container *ngIf="teacherDetail.covid[0].contactcovid == 'no'">
              <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
          </ng-container>
        </div> <!-- div -->
                              </div>
                              
                              
                            </div>
                          </div> <!-- box -->
                      

                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->