import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-selected-student-preview',
  templateUrl: './selected-student-preview.component.html',
  styleUrls: ['./selected-student-preview.component.css']
})
export class SelectedStudentPreviewComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { }

  game_id:any;
  student_id:any;
  ngOnInit(): void {
    this.game_id=this.route.snapshot.params['gid'];
    this.student_id=this.route.snapshot.params['sid'];
    $('.select-student-countdown').hide();
  }
  timeLeft: number = 3;
  interval:any;
  startTimer() {
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.timeLeft = 60;
          }
          if (this.timeLeft == 0) {
            clearInterval(this.interval);
            // this.timeLeft = 1;
            $('.next_page').click();
            this.router.navigate(['/parent/select-right-answer/'+this.game_id+'/'+this.student_id+'']);
          }
        }, 1000);
      }
      pauseTimer() {
      clearInterval(this.interval);
    }
  letsPlay(){
    $('.student-preview').hide();
    $('.select-student-countdown').show();
    // $('.right-inner').addClass('p0');
    $('.select-student-countdown').addClass('active');
    this.startTimer();
  }
  removeclass(){
    $('.right-inner').removeClass('p0');
  }

}
