import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";

@Component({
  selector: 'app-teacher-sidebar',
  templateUrl: './teacher-sidebar.component.html',
  styleUrls: ['./teacher-sidebar.component.css']
})
export class TeacherSidebarComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shareurl: ApiService
    ) { }

  ngOnInit(): void {
    $('.loading-overlay').hide();
  }
  // menuClick(event:any){
  //   $('.clv-dasboard .main-sidebar .main-menu li a').removeClass('active');
  //   $(event.target).addClass('active');
  // }
  logoutData:any;
  logout(){
    $('.loading-overlay').show();
    var formData: any = new FormData();
  	var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
  	this.http.post(''+this.siteurl+'/logout',formData, { headers }).subscribe(
        data => {
          $('.loading-overlay').hide();
        	console.log(data);
        this.logoutData=data;
           // console.log(this.logoutData.token);
           
           if (this.logoutData.message == 'success') {
                  // sessionStorage.removeItem('token');
                  // sessionStorage.removeItem('role');
                  // sessionStorage.removeItem('role');
                  // sessionStorage.removeItem('role');
                  sessionStorage.clear();
                  console.log('session remove');
                  this.router.navigate(['/']);
              }else{
                console.log('no');
              }
        }

      );
  }

}
