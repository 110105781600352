
<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}" class="active">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">{{assignmentData.name}}</h3>
    <a class="btn btn-primary d-inline-block mb-2" routerLink="/teacher/add-assignment/{{pod_id}}" data-toggle="tab"><i class="fa fa-plus" aria-hidden="true"></i> Create Assignment</a>
</div>
	<div class="vpod-body-inner">
          <div class="card-body pt-0">
            <div class="mtable">
                <table datatable class="table">
                  <thead>
                  <tr>
                    <th>Assignment Numbers</th>
                    <th>Assignment Title</th>
                    <th>Asigned on</th>
                    <th>Last Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let assignment of assignmentData.assignments;let i = index">
                    <td>{{i + 1}}</td>
                    <td>{{assignment.title}}</td>
                    <td>{{assignment.assigned_on}}</td>
                    <td>{{assignment.last}}</td>
                    <td>
                      <a routerLink="/teacher/assignment-detail/{{assignment.pod_id}}/{{assignment.id}}" class="btn btn-primary mr-1">View Detail</a> <a routerLink="/teacher/view-submission/{{assignment.pod_id}}/{{assignment.id}}" class="btn btn-primary">View Submission</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!-- mtable -->
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->