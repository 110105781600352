<div class="inner-header d-flex align-items-center justify-content-between mb-4 flex-wrap">
      <h3>PODS List</h3>
      <a routerLink="/admin/create-pod" class="mt-2">Create POD</a>
</div>
<!-- body -->
        <div class="card-body pt-0">
          <div class="mtable">
      <table datatable class="table">
        <thead>
        <tr>
          <th>View</th>
          <th>Profile Pic</th>
          <th>POD Name</th>
          <th>City</th>
          <th>Zipcode</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let pod of podData.pods;let i = index">
          <td>{{i + 1}}</td>
          <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{pod.image}}"></td>
          <td>{{pod.name}}</td>
          <td>{{pod.city}}</td>
          <td>{{pod.zipcode}}</td>
           
          <td>
            <a routerLink="/admin/pod-detail/{{pod.id}}" class="btn btn-primary">View POD</a>
          </td>
        </tr>
        
        </tbody>
      </table>
    </div> <!--- mtable --->
    </div> <!-- card-body -->