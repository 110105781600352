import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

import { AdminRoutingModule } from './admin-routing.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminComponent } from './admin.component';
import { AdminfooterComponent } from './adminfooter/adminfooter.component';
import { ParentDetailComponent } from './parent-detail/parent-detail.component';
import { ManageParentsComponent } from './manage-parents/manage-parents.component';
import { ManageTeachersComponent } from './manage-teachers/manage-teachers.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { TeacherDetailComponent } from './teacher-detail/teacher-detail.component';
import { ManagePodsComponent } from './manage-pods/manage-pods.component';
import { CreatePodComponent } from './create-pod/create-pod.component';
import { CompetitionsComponent } from './competitions/competitions.component';
import { MonitoringAlertsComponent } from './monitoring-alerts/monitoring-alerts.component';
import { ManageCaregiversComponent } from './manage-caregivers/manage-caregivers.component';
import { CaregiverDetailComponent } from './caregiver-detail/caregiver-detail.component';
import { ManageStudentsComponent } from './manage-students/manage-students.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';


@NgModule({
  declarations: [
    SidebarComponent,
    AdminComponent,
    AdminfooterComponent,
    ParentDetailComponent,
    ManageParentsComponent,
    ManageTeachersComponent,
    AdminHomeComponent,
    TeacherDetailComponent,
    ManagePodsComponent,
    CreatePodComponent,
    CompetitionsComponent,
    MonitoringAlertsComponent,
    ManageCaregiversComponent,
    CaregiverDetailComponent,
    ManageStudentsComponent,
    StudentDetailComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    DataTablesModule
  ]
})
export class AdminModule { }
