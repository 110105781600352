<!-- sign up -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="signup-page">
  <div class="inner">
    <h2> Admin Login </h2>
    <ul class="errors"></ul>
     
      <form class="w-100" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="email input">
        <input type="email" name="email" placeholder="Email" formControlName="email">
        </div>
        <div class="input password">
            <input type="password" name="password" placeholder="Password" formControlName="password">
        </div>
        <button class="btn login" name="login">Log In</button>
      </form>
  </div>
</div>