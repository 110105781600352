<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}" class="active">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->

	<div class="vpod-body-inner p-3 mt-3">
                      <div class="d-flex align-items-center justify-content-between">
                          <h1 class="mb-2 sound_bubble_demoregular text_color3">Science</h1>
                          <a routerLink="/teacher/student-collaborate/{{pod_id}}/{{student_id}}" class="btn btn-primary">Pair Collaborate</a>
                      </div>
                        
                    <div class="box mt-2 view-submission d-flex align-items-start justify-content-between">

                          <div class="left">
                    <div class="d-flex align-items-start justify-content-between flex-wrap">
                              <div class="dviewbox border rounded p-3 mb-2">
                                <h6>POD</h6>
                                <p class="text-muted mt-2"><a routerLink="#">Science</a></p>
                              </div> <!-- div -->
                              <div class="dviewbox border rounded p-3 mb-2">
                                <h6>Roll Number</h6>
                                <p class="text-muted mt-2">09887</p>
                              </div> <!-- div -->
                              <div class="dviewbox border rounded p-3 mb-2">
                                <h6>Parent Name</h6>
                                <p class="text-muted mt-2">John Doe</p>
                              </div> <!-- div -->

                            </div>

                            <div class="assignments mt-4">
                              <h2 class="mb-2 sound_bubble_demoregular">Assignments</h2>
                              <table class="table">
                              <thead>
                                <tr>
                                  <th>Assignment Name</th>
                                  <th>Asigned on</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Physics</td>
                                  <td>9/19/2020</td>
                                  <td><a routerLink="view-assignment.html" class="btn btn-primary">View</a></td>
                                </tr>
                                <tr>
                                  <td>Physics</td>
                                  <td>9/19/2020</td>
                                  <td><a routerLink="view-assignment.html" class="btn btn-primary">View</a></td>
                                </tr>
                                <tr>
                                  <td>Physics</td>
                                  <td>9/19/2020</td>
                                  <td><a routerLink="view-assignment.html" class="btn btn-primary">View</a></td>
                                </tr>
                              </tbody>
                            </table>
                            </div>

                           <!--  <div class="attendnce mt-4">
                              <h2 class="mb-2 sound_bubble_demoregular">Attendence</h2>
                              <div>
                              <canvas id="atttendencepiechart"></canvas>
                              </div>
                            </div> -->

                            </div>
                            <div class="right border rounded">
                              
                              <div class="p-2">
                                 <h6 class="mb-2">Student Image</h6>
                                <img class="mw-100 rounded" src="../assets/img/cat.jpg">
                              </div>
                            </div> <!-- right -->
                          </div>
                      

                    </div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->