import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) {

      this.form = this.fb.group({
        title: [''],
        description: [''],
        image: ['']
      });

     }

  cat_id: any;
  ngOnInit(): void {
    $('.loading-overlay').hide();
    this.cat_id=this.route.snapshot.params['id'];
  }
  files:any;
  fileName: any;
  uploadImage(event:any){
    this.fileName = event.target.files[0].name;
    this.files = event.target.files[0];
    console.log(this.files);
  }
  message: any;
  error:any;
  submitForm() {
    if (this.files) {
      $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("title", this.form.value.title);
    formData.append("description", this.form.value.description);

    formData.append("image", this.files, this.files.name);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/teacher/saveCategory', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
          this.router.navigate(['/teacher/categories']);
        }
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> file is required</h5>');
          $('.autoclick_show_modal').click();
    }
    
    
  }

}
