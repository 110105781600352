<div class="inner-header d-flex align-items-center justify-content-between mb-4 flex-wrap">
                        <h3>Series</h3>
                        <a class="btn btn-primary d-inline-block mt-2" routerLink="/teacher/add-series" data-toggle="tab"><i class="fa fa-plus" aria-hidden="true"></i> Add New Series</a>
                  </div>

                  <!-- body -->
                  <div class="card-body pt-0">
                    <div class="mtable">
                <table datatable class="table">
                  <thead>
                  <tr>
                    <th>View</th>
                    <th>Image</th>
                    <th>Series Name</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let serie of seriesData.series; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{serie.image}}"></td>
                    <td>{{serie.title}}</td>
                    <td>
                      <a routerLink="#" class="btn btn-primary mr-2">Edit</a>
                      <a routerLink="#" class="btn btn-danger">Delete</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!--- mtable --->
              </div> 