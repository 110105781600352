<!-- body -->
                  <div class="vpod-body">
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Teacher's Detail For POD</h3>
                  </div>
<div class="vpod-body-inner pt-3">
<div class="row">
  <div class="col-md-8">
    <div class="d-flex align-items-start justify-content-between flex-wrap mobile-fullw">
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
          <h6>Name</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].fname}} {{teacherDetail.teacher_details[0].lname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3">
          <h6>Country</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].cname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>State</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].sname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>City</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].city}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>Zip Code</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].zipcode}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>Primary Speaking Language</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].pplname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>Secondary Speaking Language</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].pslname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>Grade</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].gname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>Skill</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].tsname}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6>Date of Birth</h6>
          <p class="text-muted mt-2">{{teacherDetail.teacher_details[0].dob}}</p>
        </div> <!-- div -->
        <div style="width:49%;background:#EFEFEF;border-color:#E2E2E2;" class="border rounded p-3 mt-2">
          <h6 class="mb-2">CV</h6>
          <a href="{{siteurl}}/{{teacherDetail.teacher_details[0].cv}}" target="blank"><i class="fa fa-file" aria-hidden="true"></i> {{teacherDetail.teacher_details[0].fnam}} {{teacherDetail.teacher_details[0].lname}}</a>
        </div> <!-- div -->

      </div>
  </div>
  <div class="col-md-4">
    <div class="right border rounded p-2">
        <img class="mw-100 rounded" src="{{siteurl}}/{{teacherDetail.pod[0].image}}">
      </div>
    <div class="d-flex mt-3">
        <button class="btn btn-success mr-3" data-toggle="modal" data-target="#acceptmodel">Accept</button>
        <button class="btn btn-danger" data-toggle="modal" data-target="#rejectmodel">Reject</button>

        <!-- model-0ne start -->
        <!-- Modal -->
        <div class="modal fade modelbtnaccept" id="acceptmodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <h5 class="modal-title text-center bt1">Are you sure you want <br> to accept? </h5>
                <h5 class="modal-title text-center text-success bt2"> <i class="fa fa-check-circle-o mr-2" aria-hidden="true"></i>Accepted Successfully! </h5>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
              <div class="modal-body">
                <button type="button" class="btn-success accept" (click)="acceptRequest()" data-dismiss="modal">Yes</button>
                <button type="button" data-dismiss="modal">No</button>
                
              </div>
            </div>
          </div>
        </div>
        <!-- model-one-end -->
        <!-- model-two start -->
        <!-- Modal -->
        <div class="modal fade modelbtnaccept" id="rejectmodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <h5 class="modal-title text-center bt1">Are you sure you want <br> to Reject? </h5>
                <h5 class="modal-title text-center text-danger bt2"> <i class="fa fa-times-circle-o mr-2" aria-hidden="true"></i> Rejected! </h5>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
              <div class="modal-body">
                <button type="button" class="btn-danger accept" (click)="rejectRequest()" data-dismiss="modal">Yes</button>
                <button type="button" data-dismiss="modal">No</button>
                
              </div>
            </div>
          </div>
        </div>
        <!-- model-two-end -->
      </div> <!-- button -->
  </div> <!-- col-md-4 -->
</div> <!-- row -->
          
        <!-- apply pod detail -->
        <div class="apply-pod-detail">
          <div class="inner-header mt-4 mb-3">
                  <h3>Apply for this POD</h3>
            </div>
            <div class="mtable">
            <table class="table">
    <thead style="background:#EFEFEF;border-color:#E2E2E2;">
    <tr>
      <th>Name</th>
      <th>Country</th>
      <th>State</th>
      <th>City</th>
      <th>Zip Code</th>
      <th>POD Primary Language</th>
      <th>Learning Enviroment</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{teacherDetail.pod[0].name}}</td>
      <td>{{teacherDetail.pod[0].cname}}</td>
      <td>{{teacherDetail.pod[0].sname}}</td>
      <td>{{teacherDetail.pod[0].city}}</td>
      <td>{{teacherDetail.pod[0].zipcode}}</td>
      <td>{{teacherDetail.pod[0].pplname}}</td>
      <td>{{teacherDetail.pod[0].lename}}</td>
    </tr>
    </tbody>
  </table>
</div> <!--- mtable ----->
        </div> <!-- apply-pod-detail -->

      </div> <!-- vpod-body-inner -->

    </div> <!-- vpod-body -->