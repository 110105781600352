<!-- body -->
                  <div class="vpod-body">
                    <div class="vpod-nav">
                        <ul>
                            <li><a routerLink="/parent/pod-detail/{{pod_id}}" class="active" >Home</a></li>
                            <li><a routerLink="/parent/assignments/{{pod_id}}">Assignments</a></li>
                            <li><a routerLink="/parent/notes/{{pod_id}}">Notes</a></li>
                            <li><a routerLink="/parent/quiz/{{pod_id}}">Quiz</a></li>
                            <li><a routerLink="/parent/team-preparation/{{pod_id}}">Team Preparation</a></li>
                        </ul>
                    </div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>{{podeDetail.name}}</h3>
                  </div>
                    <div class="vpod-body-inner">
          <div class="text-center">
          <i class="fa fa-clock-o display-3" aria-hidden="true"></i>
          <h5>POD Will be started in 4hours</h5>
          <p class="mt-2">POD Link: <a routerLink="#">http://www.clv.com</a></p>
        </div>

                      
                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->