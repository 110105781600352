<!-- body -->
<div class="vpod-body">
  <div class="vpod-nav">
  <div class="inner-header">
      <h3>Competitions</h3>
</div>
<div class="competition-boxes mt-3 d-flex justify-content-between flex-wrap">
    <div class="box">
        <div class="img">
          <img src="assets/img/cat.jpg">
        </div>
        <div class="content">
          <h2>Math</h2>
          <p class="fees"><span><i class="fa fa-usd" aria-hidden="true"></i>987</span></p>
          <p class="last-date">Last Date: 11/19/2021</p>
          <a routerLink="#" class="btn-primary btn">View Detail</a>
        </div>
    </div> <!-- box -->
    <div class="box">
        <div class="img">
          <img src="assets/img/cat.jpg">
        </div>
        <div class="content">
          <h2>Physics</h2>
          <p class="fees"><span><i class="fa fa-usd" aria-hidden="true"></i>987</span></p>
          <p class="last-date">Last Date: 11/19/2021</p>
          <a routerLink="#" class="btn-primary btn">View Detail</a>
        </div>
    </div> <!-- box -->
    <div class="box">
        <div class="img">
          <img src="assets/img/cat.jpg">
        </div>
        <div class="content">
          <h2>Science</h2>
          <p class="fees"><span><i class="fa fa-usd" aria-hidden="true"></i>987</span></p>
          <p class="last-date">Last Date: 11/19/2021</p>
          <a routerLink="#" class="btn-primary btn">View Detail</a>
        </div>
    </div> <!-- box -->
</div><!-- competition-boxes -->
</div> <!-- vpod-body -->

</div>