
<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}" class="active">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">{{quizData.pod.name}}</h3>
    <a routerLink="/teacher/add-quiz/{{pod_id}}" class="btn btn-primary d-inline-block"><i class="fa fa-plus" aria-hidden="true"></i> Add New</a>
</div>
	<div class="vpod-body-inner">
          <div class="d-flex align-items-center justify-content-between mb-3">
                        <h4 class="mb-2">Quiz</h4>
                        
                      </div>

                      <div class="card-body pt-0">
                        <div class="mtable">
                <table datatable class="table">
                  <thead>
                  <tr>
                    <th>Quiz Numbers</th>
                    <th>Quiz Title</th>
                    <th>Quiz Marks</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let quiz of quizData.quizes;let i =index">
                    <td>{{i + 1}}</td>
                    <td>{{quiz.title}}</td>
                    <td>{{quiz.total_marks}}</td>
                    <td>{{quiz.created_at}}</td>
                     
                     
                    <td>
                      <a routerLink="/teacher/quiz-detail/{{pod_id}}/{{quiz.id}}" class="btn btn-primary">View</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!--- mtable --->
              </div> <!-- card-body -->
</div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->