<!-- body -->
<div class="vpod-body">
  <a routerLink="/admin/manage-caregivers"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
  <div class="vpod-body-inner">
    <div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

        <div class="left">
          <h5>John Doe</h5>
          <p class="mt-2 font-weight-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="skill-box mt-4">
            <h4>Skills</h4>
            <div class="border rounded p-2 mt-2 d-flex align-items-start flex-wrap">
              <p class="bg-secondary d-inline-blocck text-white rounded p-2 mr-2 mb-2">Math</p>
              <p class="bg-secondary d-inline-blocck text-white rounded p-2 mr-2 mb-2">Science</p>
            </div>
          </div> <!-- skill-box -->
          <div class="schedule-box mt-4">
            <h4>Schedule</h4>
            <div class="border rounded p-3 mt-2">
              <p>Monday to Saturday</p>
              <p class="mt-1">8am to 3pm</p>
            </div>
          </div> <!-- skill-box -->
          </div>
          <div class="right border p-2">
            <div class="border rounded p-2 resume mb-3">
              <h3>PODS</h3>
              <a href="#" class="d-block bg-secondary text-white rounded p-2 mt-2">Science</a>
            </div> <!-- resume -->
            <div class="border rounded p-2 resume">
              <h3>Resume</h3>
              <div class="file-box mt-3 alert alert-secondary">
                  <a class="d-block mb-3" href="assets/file-sample.doc" download="assets/file-sample.doc">
                  <i class="fa fa-file" aria-hidden="true"></i> 
                  File-sample.doc
                </a>
                <a type="application/octet-stream" class="btn btn-primary d-inline-block" href="assets/file-sample.doc" hreflang="assets/file-sample.doc" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

                <a class="btn btn-success d-inline-block" href="assets/file-sample.doc" download="assets/file-sample.doc"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
              </div> <!-- file-box -->
              <div class="file-box mt-3 alert alert-secondary">
                  <a class="d-block mb-3" href="assets/file-sample.doc" download="assets/file-sample.doc">
                  <i class="fa fa-file" aria-hidden="true"></i> 
                  File-sample.doc
                </a>
                <a type="application/octet-stream" class="btn btn-primary d-inline-block" href="assets/file-sample.doc" hreflang="assets/file-sample.doc" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

                <a class="btn btn-success d-inline-block" href="assets/file-sample.doc" download="assets/file-sample.doc"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
              </div> <!-- file-box -->
            </div> <!-- resume -->
          </div>
        </div> <!-- box -->
    

  </div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->