<!-- body -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
                  <div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}" class="active">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
                   <div class="inner-header d-flex align-items-center justify-content-between mb-3">
                        <h3 class="text_color3">{{noteDetail.notes.pname}}</h3>
                  </div>

                    <div class="vpod-body-inner">
                      <div class="w-100 d-flex align-items-center justify-content-between flex-wrap">
                          <h5>Title: {{noteDetail.notes.title}}</h5>
                          <a class="btn btn-primary d-inline-block" routerLink="#" (click)="deleteNote()">Delete Notes</a>
                      </div>
                      <div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

                          <div class="left">
                            <h2>Description:</h2>
                            <p class="mt-2 font-weight-light">{{noteDetail.notes.description}}</p>
                            <div class="box-inner d-flex align-items-start justify-content-between mt-4">
                              <div class="date border rounded text-center d-inline-blocck p-3 alert-secondary">
                                <h5>Uploaded on</h5>
                                  <p class="mt-2">{{noteDetail.notes.created_at}}</p>
                              </div>
                              <div class="date border rounded text-center d-inline-blocck p-3 alert-secondary">
                                <h5>Serie</h5>
                                  <p class="mt-2">{{noteDetail.notes.stitle}}</p>
                              </div>
                              <div class="date border rounded text-center d-inline-blocck p-3 alert-secondary">
                                <h5>Category</h5>
                                  <p class="mt-2">{{noteDetail.notes.ctitle}}</p>
                              </div>

                            </div>
                            <div class="border rounded p-3 mt-3">
                              <h4 class="mb-2">Tags:</h4>
                              <ul class="tags">
                                <li *ngFor="let tag of noteDetail.notes.tags">{{tag}}</li>
                              </ul>
                            </div>
                            
                            </div>
                            <div class="right border rounded p-2">
                                <h3>Files</h3>
                                <div class="file-box mt-3 alert alert-secondary">
                                    <a class="d-block mb-3" href="{{siteurl}}/{{noteDetail.notes.file}}" download="{{siteurl}}/{{noteDetail.notes.file}}">
                                    <i class="fa fa-file" aria-hidden="true"></i> 
                                    {{noteDetail.notes.title}}
                                  </a>
                                  <a type="application/octet-stream" class="btn btn-primary d-inline-block mr-1" href="{{siteurl}}/{{noteDetail.notes.file}}" hreflang="{{siteurl}}/{{noteDetail.notes.file}}" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

                                  <a class="btn btn-success d-inline-block" href="{{siteurl}}/{{noteDetail.notes.file}}" download="{{siteurl}}/{{noteDetail.notes.file}}"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
                                </div> <!-- file-box -->
                            </div>
                          </div> <!-- box -->
                      

                    </div> <!-- vpod-body-inner -->

                  </div> <!-- vpod-body -->