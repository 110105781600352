<div class="select-student-page">
	<a routerLink="/parent/gamification" class="h5 text-dark d-inline-blok"><img style="max-width:25px;margin-right:10px;" src="../assets/img/arrow-left.png"> Competition</a>
	<h1 class="d-block w-100 text-center font-weight-bold sound_bubble_demoregular mt-3">Select Opponent</h1>
	<div class="boxes">
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
		<div class="box text-center" (click)="selectStudent($event)">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h4>Mary Patricia</h4>
		</div> <!-- box -->
	</div> <!-- boxes -->
	<div class="text-center mt-5">
		<a routerLink="/parent/select-student-preview/12/12" class="next-btn">Next</a>
	</div>
</div> <!-- select-student -->