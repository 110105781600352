<!-- body -->
<div class="vpod-body">
<div class="vpod-nav">
<ul>
    <li><a routerLink="/parent/pod-detail/{{pod_id}}">Home</a></li>
    <li><a routerLink="/parent/assignments/{{pod_id}}">Assignments</a></li>
    <li><a routerLink="/parent/notes/{{pod_id}}">Notes</a></li>
    <li><a routerLink="/parent/quiz/{{pod_id}}" class="active">Quiz</a></li>
    <li><a routerLink="/parent/team-preparation/{{pod_id}}">Team Preparation</a></li>
</ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3>{{quizData.pod.name}}</h3>
</div>
<div class="vpod-body-inner">
<div class="card-body pt-0">
  <div class="mtable">
<table datatable class="table">
  <thead style="background:#EFEFEF;border-color:#E2E2E2;">
  <tr>
    <th>Quiz Numbers</th>
    <th>Quiz Title</th>
    <th>Quiz Marks</th>
    <th>Date</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let quiz of quizData.quizes;let i=index">
    <td>{{i+1}}</td>
    <td>{{quiz.title}}</td>
    <td>{{quiz.total_marks}}</td>
    <td>{{quiz.created_at}}</td>
    <td>
      <a routerLink="/parent/quiz-detail/{{pod_id}}/{{quiz.id}}" class="btn btn-primary">View</a>
    </td>
  </tr>
  
  </tbody>
</table>
</div> <!--- mtable --->
</div> <!-- card-body -->

      
    </div> <!-- vpod-body-inner -->

  </div> <!-- vpod-body -->