<!-- Header -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<header class="site-header">
      <div class="container">
      <nav class="navbar navbar-expand-md pl-0 pr-0">
        <div class="d-flex align-items-center">
          <a class="navbar-brand d-none mobile-logo" href="#"><img src="assets/img/logo.png"></a>
        <ul class="header-social d-flex align-items-center">
          <li class="m-0"><a href="#" class="p-0 h4 mr-3 mb-0"><i class="fa fa-facebook-official" aria-hidden="true"></i></a></li>
          <li class="m-0"><a href="#" class="p-0 h4 mb-0"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
        </ul>

        </div>
  <ng-template #manageparents>
  <h1>All courses available</h1>
  ....
</ng-template>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <i class="fa fa-bars" aria-hidden="true"></i>
  </button>
  <div class="collapse navbar-collapse w-100" id="collapsibleNavbar">
    <ul class="navbar-nav d-flex align-items-center w-100">
      <div class="d-flex align-items-center justify-content-between m-auto">
      <li class="nav-item itemm">
        <a class="nav-link active" routerLink="parent-signup">Parent</a>
      </li>
      <li class="nav-item itemm">
        <a class="nav-link" routerLink="teacher-signup">Teacher</a>
      </li>
      <li class="nav-item logo">
        <a class="navbar-brand" routerLink=""><img src="assets/img/logo.png"></a>
      </li>
      <li class="nav-item itemm">
        <a class="nav-link" routerLink="caregiver-signup">Caregiver</a>
      </li> 
      <li class="nav-item itemm">
        <a class="nav-link" routerLink="signin">Sign in</a>
      </li> 
      </div>
      <li class="nav-item itemm plogout">
        <a class="nav-link menu-button" routerLink="signin">Sign in</a>
      </li>
      <li class="nav-item d-none teacher">
        <a class="nav-link menu-button" routerLink="teacher">Dashboard</a>
      </li>
      <li class="nav-item d-none parent">
        <a class="nav-link menu-button" routerLink="parent">Dashboard</a>
      </li>
      <li class="nav-item d-none tlogout">
        <a class="nav-link menu-button" (click)="logoutBtn()" routerLink="/">Logout</a>
      </li>

    </ul>
  </div>  
</nav>

</div> <!-- container -->
</header>