import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-quiz-detail',
  templateUrl: './quiz-detail.component.html',
  styleUrls: ['./quiz-detail.component.css']
})
export class QuizDetailComponent implements OnInit {
siteurl = this.shareurl.url;
form: FormGroup;
q = 0;
truefalse = 0;
interval:any;
day: number = 0;
hour: number = 0;
minute: number = 0;
second: number = 0;
continue_minutes: number = 0;
continue_seconds: number = 0;

  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) {
    this.form = this.fb.group({
        student_name: [''],

      });
     }
     
      selectRightans(event:any){
        $('.question-inner .box .box-inner .inner').removeClass('active');
        $(event.target).addClass('active');
        $('.question-inner .box .box-inner .inner input:checked').parent().addClass('active');
      }

  pod_id: any;
  quiz_id: any;
  quizDetail:any;
  question:any;
  ngOnInit(): void {
  	this.pod_id=this.route.snapshot.params['id'];
  	this.quiz_id=this.route.snapshot.params['qid'];
    $('.loading-overlay').hide();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/viewQuiz/'+this.quiz_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.quizDetail = data;
          this.q = this.quizDetail.questions.length;
          if(this.quizDetail.res == 'not started yet'){
            this.day = this.quizDetail.difference.days;
            this.hour = this.quizDetail.difference.hours;
            this.minute = this.quizDetail.difference.minutes;
            this.second = this.quizDetail.difference.seconds;
            this.startTimer();
          }
          if(this.quizDetail.res == 'continue'){
            this.continue_minutes = this.quizDetail.difference.minutes;
            this.continue_seconds = this.quizDetail.difference.seconds;
            this.continue_timer();
          }
        }

      );
  }
  startTimer() {
        this.interval = setInterval(() => {
          if(this.day == 0 && this.hour == 0 && this.minute == 0 && this.second == 0){
            clearInterval(this.interval);
            window.location.reload();
          }
          if (this.second > 0) {
            this.second--;
          } else {
            this.second = 60;
          }
          if (this.second == 0) {
            if(this.minute !== 0){
              this.minute --;
            }
            
          }
          if(this.minute == 0){
            if(this.hour !== 0){
              this.hour --;
              this.minute = 60;
            }
          }
          if(this.hour == 0){
            if(this.day !== 0){
              this.day --;
              this.hour = 24;
            }
          }
          

        }, 1000);
      }
      continue_interval: any;
      continue_timer() {
        this.continue_interval = setInterval(() => {
          if(this.continue_minutes == 0 && this.continue_seconds == 0){
            clearInterval(this.continue_interval);
            this.submitQuiz();
            // window.location.reload();
            // alert('time up');
          }
          if (this.continue_seconds > 0) {
            this.continue_seconds--;
          } else {
            this.continue_seconds = 60;
          }
          if (this.continue_seconds == 0) {
            if(this.continue_minutes !== 0){
              this.continue_minutes --;
            }
            
          }
          

        }, 1000);
      }
      // pauseTimer() {
      //   clearInterval(this.interval);
      // }

  newstudent:any;
  showquestion:any;
  selectStudent(){
    // var thisval = $(this).val();
    // alert('thisval');
    var student_id = $(".student_name").val();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    $.ajax({
      type: "Get",
      beforeSend: function(request) {
        request.setRequestHeader('Authorization', 'Bearer '+sessionStorage.getItem('token')+'');
      },
      url: ''+this.siteurl+'/parent/attempted/'+this.quiz_id+'/'+student_id+'',
      // data: "json=" + escape(JSON(createRequestObject)),
      processData: false,
      success: function(data) {
        // $("#results").append("The result =" + (msg));
        // console.log(data.states);
        $(".select_state").empty();
        this.newstudent = data;
        this.showquestion == this.newstudent.message;
        // for (var i = this.statess.length - 1; i >= 0; i--) {
        //   $(".select_state").append('<option value="'+this.statess[i].id+'">'+this.statess[i].name+'</option>');
        //   // console.log(this.statess[i].name);
        // }

      }
});
    
    
  }

  message: any;
  error:any;
  submitQuiz(){
    $('.loading-overlay').show();
    var mcqarray:any = [];
    var questions:any = [];
    for (var i = 0; i < this.q; ++i) {
      var roption = $('input[name="mcroption'+i+'"]:checked').val();
      mcqarray = [roption];
      questions.push(mcqarray);
    }
    // console.log(questions);

    console.log(questions);
    var newvar = JSON.stringify(questions);
    var formData: any = new FormData();
    formData.append("student_id", this.form.value.student_name);
    formData.append("quiz_id", this.quiz_id);
    formData.append("answers", newvar);
    formData.append("time_taken", '10');
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/parent/attemptQuiz', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">Your Marks: '+this.message.obtained+' out of '+this.message.total+'</h5>');
          $('.autoclick_show_modal').click();
            setInterval(() => {
                window.location.reload();
            }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          setInterval(() => {
                window.location.reload();
            }, 3000);
        }
    );
  }

}
