import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shareurl: ApiService
  	) { 
  	this.form = this.fb.group({
	      email: [''],
	      password: [''],
	    });
  }
  
  ngOnInit(): void {
    $('.loading-overlay').hide();
  }
  errors:any;
  GoldData:any;
  submitForm() {
    $('.loading-overlay').show();
    // var ntoken = '';
    // var ntoken: any = '';
    var formData: any = new FormData();
    formData.append("email", this.form.value.email);
    formData.append("password", this.form.value.password);
    
    this.http.post(''+this.siteurl+'/adminLogin', formData).subscribe(
        data => {
        	console.log(data)
        this.GoldData=data,
           console.log(this.GoldData.token);
           sessionStorage.setItem('admin_email', this.GoldData.admin.email);
           sessionStorage.setItem('admin_password', this.GoldData.admin.password);
           this.router.navigate(['admin']);
           $('.loading-overlay').hide();
        },
        error => {
          $("html, body").animate({ scrollTop: 0 }, 600);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          console.log(this.errors);
          for (let i = 0; i < this.errors.length; i++) {
              $('.errors').append('<li>'+this.errors[i]+'</li>');
            } 
          $('.loading-overlay').hide();
        }

      );
    
   
    var headers = { 'Authorization': 'Bearer '+this.GoldData.token+'' };
    // sessionStorage.setItem('key', 'value');
    // localStorage.setItem('token', this.GoldData.token);
    // this.http.post('https://latif-overseas-ind.com/logout', formData, { headers }).subscribe(
    //   (response) => console.log(response),
    //   (error) => console.log(error),
    // )
    
  }

}
