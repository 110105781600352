import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-view-submission',
  templateUrl: './view-submission.component.html',
  styleUrls: ['./view-submission.component.css']
})
export class ViewSubmissionComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { 
  	this.form = this.fb.group({
        description: [''],
        
      });
  }

  pod_id: any;
  submission_id: any;
  submissionDetail: any;
  ngOnInit(): void {
  	this.pod_id=this.route.snapshot.params['id'];
  	this.submission_id=this.route.snapshot.params['sid'];
  	var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/parent/viewAssignmentSubmission/'+this.submission_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.submissionDetail = data;
        }

      );
  }
  resubmissionform(){
    $('.upload-assignment').slideToggle('active');
  }
  files:string [] = [];
  asImage(event:any){
    this.files = [];
    for (var i = 0; i < event.target.files.length; i++) { 
          this.files.push(event.target.files[i]);
          console.log(this.files);
      }
  }
  studentData: any;
    message: any;
    error:any
  uploadAssignment(){
    if (this.files) {
      $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("description", this.form.value.description);
    formData.append("submission_id", this.submission_id);

    for (var i = 0; i < this.files.length; i++) { 
      formData.append("file[]", this.files[i]);
    }
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/parent/resubmitAssignment', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
          this.router.navigate(['/parent/assignments/'+this.pod_id+'']);
        }
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> file is required</h5>');
          $('.autoclick_show_modal').click();
    }
  }

}
