import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(public router: Router) { }
  bannerslides = [
    {img: "assets/img/b1.png"},
    {img: "assets/img/b2.png"},
    {img: "assets/img/box-girl.png"}
  ];
  slideConfig = {
  "slidesToShow": 1, 
  "slidesToScroll": 1,
  "dots": false,
  "infinite": true,
  "arrows": false,
  "fade": true,
  "pauseOnHover": true,
  }
  beforeChange(){
    var nextSlide = '1';
    $('.banner-section').removeClass('banner'+nextSlide);
    $('.banner-section').addClass('banner'+nextSlide);
  }

  ngOnInit(): void {
  }

}
