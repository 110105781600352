import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-game-result',
  templateUrl: './game-result.component.html',
  styleUrls: ['./game-result.component.css']
})
export class GameResultComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { }

  game_id:any;
  student_id:any;
  ngOnInit(): void {
    this.game_id=this.route.snapshot.params['gid'];
    this.student_id=this.route.snapshot.params['sid'];
  }

}
