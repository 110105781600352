import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import { Quiz } from "../../model/quiz.model";
import * as $ from 'jquery';

@Component({
  selector: 'app-add-quiz',
  templateUrl: './add-quiz.component.html',
  styleUrls: ['./add-quiz.component.css']
})
export class AddQuizComponent implements OnInit {
siteurl = this.shareurl.url;
q = 0;
truefalse = 0;
form: FormGroup;
home =new Quiz('','','','');
dataarray:any= [];
truefalsearray:any= [];
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
  	private route: ActivatedRoute,
    private shareurl: ApiService) {
      this.form = this.fb.group({
        quiz_name: [''],
        quiz_description: [''],
        startdate: [''],
        starttime: [''],
        allotedtime: [''],
        hour: [''],
        minute: [''],
        ampm: [''],

      });
     }

  pod_id: any;
  podName: any;
  ngOnInit(): void {
    $('.loading-overlay').hide();
    var today = new Date().toISOString().split('T')[0];
    $("input[type='date']")[0].setAttribute('min', today);

  	this.pod_id=this.route.snapshot.params['id'];
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/podName/'+this.pod_id+'',{headers}).subscribe(
        data => {
          console.log(data);
          this.podName = data;
        }

      );

    this.q= this.q+1;
    this.dataarray.push(this.home);
    // this.truefalse= this.truefalse+1;
    // this.truefalsearray.push(this.home);
    // console.log(this.home);
    // console.log(this.dataarray);
  }
  addForm(){
    this.q= this.q+1;
    this.dataarray.push(this.home);
    // console.log(this.home);
  }
  addtruefalse(){
    this.truefalse= this.truefalse+1;
    this.truefalsearray.push(this.home);
    // console.log(this.home);
  }
  removeform(){
    // this.dataarray.splice(index);
    $(this).parent().parent().parent().remove();
  }
  removetruefalse(){
    // this.truefalsearray.splice(index);
    $(this).parent().parent().parent().remove();
  }
  changevaluePlus(){
    for (var i = 0; i < this.q; ++i) {
      var totalmarks = $('#mctotalmarks'+i+'').val();
      console.log(totalmarks);
    }
  }
  message: any;
  error:any;
  addQuiz() {
     $('.loading-overlay').show();
    var mcqarray:any = [];
    var questions:any = [];
    for (var i = 0; i < this.q; ++i) {
      if($('#mcmainquestion'+i+'').val()){
      var type = 'mcq';
      var question = $('#mcmainquestion'+i+'').val();
      var option1 = $('.mcfoption'+i+'').val();
      var option2 = $('.mcsoption'+i+'').val();
      var option3 = $('.mctoption'+i+'').val();
      var option4 = $('.mcfooption'+i+'').val();
      var roption = $('.mcroption'+i+'').val();
      var totalmarks = $('#mctotalmarks'+i+'').val();
      mcqarray = [type, question,option1,option2,option3,option4,roption,totalmarks];
      questions.push(mcqarray);
    }
    }
    // console.log(questions);

    var trfalsearray:any = []
    var truefalsearray:any = [];
    for (var t = 0; t < this.truefalse; ++t) {
      if ($('#tfquestion'+t+'').val()) {
        if ($('input[name="truefalse'+t+'"]:checked').val()) {
          var tf= 'tf';
          var tfquestion = $('#tfquestion'+t+'').val();
          var truefalsetotal = $('#truefalsetotal'+t+'').val();
          var truefalse = $('input[name="truefalse'+t+'"]:checked').val();
          trfalsearray = [tf,tfquestion,truefalse,truefalsetotal];
          // truefalsearray.push(trfalsearray);
          questions.push(trfalsearray);
        }else{
           $('.loading-overlay').hide();
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> Please Select valid field</h5>');
          $('.autoclick_show_modal').click();
          return;
        }
        
      }
      
      // console.log(truefalse);
    }
    // console.log(truefalsearray);

    console.log(questions);
    var newvar = JSON.stringify(questions);
    var formData: any = new FormData();
    formData.append("title", this.form.value.quiz_name);
    formData.append("description", this.form.value.quiz_description);
    formData.append("start_date", this.form.value.startdate);
    formData.append("start_time", this.form.value.starttime);
    formData.append("hour", this.form.value.hour);
    formData.append("minute", this.form.value.minute);
    formData.append("ampm", this.form.value.ampm);
    formData.append("alloted_time", this.form.value.allotedtime);
    formData.append("questions", newvar);
    // formData.append("question", this.form.value.question);
    formData.append("pod_id", this.pod_id);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/teacher/saveQuiz', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
          this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          // $('.form_status').empty().append('<h5 class="text-success text-center"><i class="fa fa-check" aria-hidden="true"></i> Note Add Successfully!</h5>');
          // $('.autoclick_show_modal').click();
        }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
  }

}
