<div class="pod-page">

<!-- Banner Section -->
<section class="banner-section text-white position-relative">
  <iframe class="position-absolute" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10714.448255559875!2d-110.55027248952369!3d41.80141196127728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875130313f944519%3A0xa003a76a320ba037!2sJCPenney!5e0!3m2!1sen!2s!4v1605604131729!5m2!1sen!2s" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  <div class="inner position-relative p-5 d-flex align-items-start justify-content-center flex-column">
      <h4 class="d-block mt-5">Patricia "Patsy" Walker</h4>
      <div class="pod-img d-flex align-items-center mt-4">
        <div class="img mr-2">
          <img src="assets/img/udlite-lohp-promo-teacher.jpg">
        </div>
        <h6><a routerLink="#" class="text-white">Carol Susan Jane Danvers</a></h6>
      </div>
</div>
</section>

<!-- tab-section -->
<!-- Services Section -->
<section class="services-section pt-5 pb-5">
      <div class="container">

        <h4 class="mb-2 font_family_sound_bubble">Description</h4>
      <p class="border p-2 rounded" style="line-height:1.3;font-family: 'Gotham Rounded Light';">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    
  <div class="pod-bottom-section">
  <div class="d-flex align-items-start mt-3 first">
          <div class="border p-3 rounded text-center mr-3 box">
              <h6 class="mb-2">COVID19 Status</h6>
              <p>Positive (+)</p>
          </div>
          <div class="border p-3 rounded text-center mr-3 box">
              <h6 class="mb-2">Student Behavior</h6>
              <p>Satisfied</p>
          </div>
          <div class="border p-3 rounded text-center mr-3 box">
              <h6 class="mb-2">Location</h6>
              <p>JCPenney</p>
          </div>
      </div>
      <div class="services-section border-0 mt-3">
                <div id="students">
              <div class="student-list d-flex">
                <div class="mw-50 w-100 mr-3">
                  <h5 class="mb-2 font_family_sound_bubble">Teacher ID</h5>
              <div class="inner d-flex align-items-center justify-content-between p-3 rounded w-100">
              <div class="left d-flex align-items-center">
                  <div class="img mr-3">
                      <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
                  </div>
                  <div class="content">
                    <h6 class="font-weight-bold mb-2"><a routerLink="/view-teacher" class="text_color1">Greer Grant Nelson</a></h6>
                    <p class="mb-2 font_size2">Expert in Math</p>
                    <span class="d-block font_size1">California</span>
                  </div> <!-- content -->
              </div> <!-- left -->
              <div class="right">
                  <a routerLink="/view-teacher">View Profile</a>
              </div> <!-- right -->
            </div> <!-- inner -->
</div> <!-- div -->
<div class="mw-50 w-100">
  <h5 class="mb-2 font_family_sound_bubble">CaregiverID</h5>
            <div class="inner d-flex align-items-center justify-content-between p-3 rounded w-100">
              <div class="left d-flex align-items-center">
                  <div class="img mr-3">
                      <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
                  </div>
                  <div class="content">
                    <h6 class="font-weight-bold mb-2"><a routerLink="#" class="text_color1">Monica Rambeau</a></h6>
                    <p class="mb-2 font_size2">Physics</p>
                    <span class="d-block font_size1">Kemmere</span>
                  </div> <!-- content -->
              </div> <!-- left -->
              <div class="right">
                  <a routerLink="#">View Profile</a>
              </div> <!-- right -->
            </div>

            </div> <!-- div -->

        </div>
        </div>
        </div>
      </div> <!-- bottom-section -->
      

      <div id="students" class="mt-3">
      <h4 class="font_family_sound_bubble">Students</h4>
      <div class="student-list d-flex align-items-center flex-wrap justify-content-between">
        <div class="inner d-flex align-items-center justify-content-between mt-3 p-3 rounded">
          <div class="left d-flex align-items-center">
              <div class="img mr-3">
                  <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
              </div>
              <div class="content">
                <h6 class="font-weight-bold mb-2"><a routerLink="/view-student" class="text_color1">David Johns</a></h6>
                <p class="mb-2 font_size2">Math</p>
                <span class="d-block font_size1">California</span>
              </div> <!-- content -->
          </div> <!-- left -->
          <div class="right">
              <a routerLink="/view-student">View Profile</a>
          </div> <!-- right -->
        </div> <!-- inner -->
        <div class="inner d-flex align-items-center justify-content-between mt-3 p-3 rounded">
          <div class="left d-flex align-items-center">
              <div class="img mr-3">
                  <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
              </div>
              <div class="content">
                <h6 class="font-weight-bold mb-2"><a routerLink="/view-student" class="text_color1">Tony Stark</a></h6>
                <p class="mb-2 font_size2">Physics</p>
                <span class="d-block font_size1">London, United Kingdom</span>
              </div> <!-- content -->
          </div> <!-- left -->
          <div class="right">
              <a routerLink="/view-student">View Profile</a>
          </div> <!-- right -->
        </div> <!-- inner -->
        <div class="inner d-flex align-items-center justify-content-between mt-3 p-3 rounded">
          <div class="left d-flex align-items-center">
              <div class="img mr-3">
                  <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
              </div>
              <div class="content">
                <h6 class="font-weight-bold mb-2"><a routerLink="/view-student" class="text_color1">John do</a></h6>
                <p class="mb-2 font_size2">Science</p>
                <span class="d-block font_size1">South America</span>
              </div> <!-- content -->
          </div> <!-- left -->
          <div class="right">
              <a routerLink="/view-student">View Profile</a>
          </div> <!-- right -->
        </div> <!-- inner -->
        <div class="inner d-flex align-items-center justify-content-between mt-3 p-3 rounded">
          <div class="left d-flex align-items-center">
              <div class="img mr-3">
                  <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
              </div>
              <div class="content">
                <h6 class="font-weight-bold mb-2"><a routerLink="/view-student" class="text_color1">Petter Parker</a></h6>
                <p class="mb-2 font_size2">Math</p>
                <span class="d-block font_size1">London, United Kingdom</span>
              </div> <!-- content -->
          </div> <!-- left -->
          <div class="right">
              <a routerLink="/view-student">View Profile</a>
          </div> <!-- right -->
        </div> <!-- inner -->
      </div> <!-- student-list -->
    </div> <!-- students -->

    <h4 class="mb-2 font_family_sound_bubble mt-4">Review</h4>
      <div class="review-box p-3 border rounded mt-3">
        <div class="inner d-flex align-items-center justify-content-between">
            <div class="left d-flex align-items-center">
                <div class="img mr-3">
                  <img class="mw-100" src="assets/img/udlite-lohp-promo-teacher.jpg">
                </div>
                <div>
                <h5 class="mb-2"><a routerLink="#">Obada Qawwas</a></h5>
                <span>15 min ago</span>
                </div>
          </div>
          <div class="right d-flex align-items-center">
            <ul class="d-flex align-items-center mr-3">
              <li><i class="fa fa-star" aria-hidden="true"></i></li>
              <li><i class="fa fa-star" aria-hidden="true"></i></li>
              <li><i class="fa fa-star" aria-hidden="true"></i></li>
              <li><i class="fa fa-star" aria-hidden="true"></i></li>
              <li class="gray"><i class="fa fa-star" aria-hidden="true"></i></li>
            </ul>
              <span>8 months ago</span>
          </div>
        </div> <!-- inner -->
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      </div> <!-- review-box -->
  </div>
</section>

<div class="join-pod">
  <div class="container">
      <div class="inner border rounded pl-3 pr-3 pt-5 pb-5 text-center bg-secondary text-white">
          <h2 class="mb-3">Join This POD Today!</h2>
          <a routerLink="/join-pod" class="d-inline-block">Join Now</a>
      </div>
  </div>
</div>
</div> <!-- pod-page -->