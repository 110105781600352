import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-team-preparation',
  templateUrl: './team-preparation.component.html',
  styleUrls: ['./team-preparation.component.css']
})
export class TeamPreparationComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { }

  pod_id: any;
  ngOnInit(): void {
  	this.pod_id=this.route.snapshot.params['id'];
  }

}
