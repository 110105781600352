<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>PODS List</h3>
                  </div>

                  <!-- body -->
                  <div class="card-body pt-0">
                    <div class="mtable">
                <table datatable class="table">
                  <thead style="background:#EFEFEF;border-color:#E2E2E2;">
                  <tr>
                    <th>View</th>
                    <th>Profile Pic</th>
                    <th>Teacher Name</th>
                    <th>POD Name</th>
                    <th>Grade</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pods of podData.pods; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{pods.pimage}}"></td>
                    <td>{{pods.fname}} {{pods.lname}}</td>
                    <td>{{pods.pname}}</td>
                    <td>{{pods.gname}}</td>
                     
                    <td>
                      <a routerLink="/parent/pod-detail/{{pods.pod_id}}" class="btn btn-primary">View POD</a>
                    </td>
                  </tr>
                  
                  
                  </tbody>
                </table>
              </div> <!-- mtable -->
              </div> <!-- card-body -->