import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {
  group: FormGroup;
siteurl = this.shareurl.url;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) {
      this.group = this.fb.group({
        gname: [''],
        gcat: [''],
        gprivacy: [''],
        gdescription: ['']
      });
     }
  groupCategory:any;
  ngOnInit(): void {
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};
    this.http.get(''+this.siteurl+'/createGroup',{headers}).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
          this.groupCategory = data;
        }

      );
  }
  files:any;
  uploadImage(event:any){
    this.files = event.target.files[0];
    console.log(this.files);
  }

  studentData: any;
  message: any;
  error:any
  createGroup(){
    if (this.files) {
      $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("title", this.group.value.gname);
    formData.append("group_category_id", this.group.value.gcat);
    formData.append("privacy", this.group.value.gprivacy);
    formData.append("description", this.group.value.gdescription);

    formData.append("cover", this.files, this.files.name);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/saveGroup', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'Success') {
          // this.router.navigate(['/parent/manage-student']);
          // $('.form_status').empty().append('<h5 class="text-success text-center">Group has been created successfully!</h5>');
          // $('.autoclick_show_modal').click();
          this.router.navigate(['/parent/chs']);
        }
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> Please Upload Group Image</h5>');
          $('.autoclick_show_modal').click();
    }
  }

}
