<div class="inner-header d-flex align-items-center justify-content-between mb-4">
        <h3>Pending PODs</h3>
  </div>
  <div class="pending_pod">
    <div class="mtable">
    <table datatable class="table">
                  <thead>
                  <tr>
                    <th>View</th>
                    <th>Profile Pic</th>
                    <th>POD Primary Language</th>
                    <th>City</th>
                    <th>Zipcode</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pod of ppodData.pendingPods; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{pod.image}}"></td>
                    <td>{{pod.pplname}}</td>
                    <td>{{pod.city}}</td>
                    <td>{{pod.zipcode}}</td>
                     
                    <td>
                      <a routerLink="/teacher/pending-pod-detail/{{pod.id}}" class="btn btn-primary" >Detail</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!--- mtable --->
  </div>


  <div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>PODS List</h3>
                  </div>

                  <!-- body -->
                  <div class="card-body pt-0">
                    <div class="mtable">
                <table datatable class="table">
                  <thead>
                  <tr>
                    <th>View</th>
                    <th>Profile Pic</th>
                    <th>Parent Name</th>
                    <th>POD Name</th>
                    <th>Grade</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pods of podData.pods; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{pods.pimage}}"></td>
                    <td>{{pods.fname}} {{pods.lname}}</td>
                    <td>{{pods.pname}}'s POD</td>
                    <td>{{pods.gname}}</td>
                     
                    <td>
                      <a routerLink="/teacher/pod-detail/{{pods.pod_id}}" class="btn btn-primary">View POD</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
              </div> <!--- mtable ---->
              </div> 
              <!-- card-body -->