<!-- body -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="vpod-body">
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3>Add New Student</h3>
</div>

<div class="vpod-body-inner p-3">
      
      <form [formGroup]="student" autocomplete="off" (ngSubmit)="addStudent()">
  <div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

<div class="left">                            
<input class="h6 rounded w-100" type="text" name="fname" placeholder="First Name" formControlName="fname">
<input class="h6 rounded w-100" type="text" name="lname" placeholder="Last Name" formControlName="lname">
<div class="select w-100 mb-2">
  <select class="w-100 learning_style" name="learningEnviroment" formControlName="learningEnviroment">
        <option>Learn Style </option>
  </select>
</div>
<div class="select w-100 mb-2">
  <select class="w-100" name="gender" formControlName="gender">
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
  </select>
</div>
<div class="select w-100 mb-2">
  <select class="w-100 teachingskill" name="teachingskill" formControlName="teachingskill">
            <option>Special Needs</option>
  </select>
</div>
<div class="select w-100 mb-2">
  <select class="w-100 grades" name="grade" formControlName="grade">
        <option>Grade Level </option>
  </select>
</div>
<div class="select w-100 mb-2">
  <select class="w-100 subjects" name="subject" formControlName="subject">
      <option>Pod Learning Subject </option>
  </select>
</div>
<input class="h6 rounded w-100" type="email" name="email" placeholder="E-mail" formControlName="email">
<div class="dob">
<input class="h6 rounded w-100" type="text" onfocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" name="dob" placeholder="Date of birth" formControlName="dob">
</div>
<input class="h6 rounded w-100" type="password" name="password" placeholder="Password" formControlName="password">
<input class="h6 rounded w-100" type="password" name="confirm_password" placeholder="Confirm Password" formControlName="confirm_password">
<button type="submit" class="btn btn-primary d-inline-block text-white mt-2 pl-4 pr-4" >
Add Student</button>
</div>
<div class="right">
  <div class="border  rounded p-3 mb-4">
    <h5>Upload Student Image</h5>
    <div class="add-file nadd-file mt-4 text-center p-4 position-relative">
    <input type="file" name="add_file" name="image" formControlName="image" (change)="uploadImage($event)" onchange="document.getElementById('previewimage').src = window.URL.createObjectURL(this.files[0])" accept="image/png, image/jpeg, image/jpg">
    <i class="fa fa-arrow-circle-up d-block mb-2 lead" aria-hidden="true"></i>
    <a href="javascript:void(0)" class="btn btn-light  ">Add Image</a>
    <img id="previewimage" width="100%" src="../../assets/img/user.jpg" />
  </div>
  
  <p class="mt-3">{{fileName}}</p>
</div>


</div>
</div> <!-- box -->
</form>

</div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->
              <p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  