<div class="teacher-demands">
	<h2 class="sound_bubble_demoregular mb-3">Filter</h2>
			<div class="search-box d-flex align-items-start justify-content-between flex-wrap mb-5">
					<select>
						<option>Select Country</option>
						<option>Select Country</option>
						<option>Select Country</option>
					</select>
					<select>
						<option>Select State</option>
						<option>Select State</option>
						<option>Select State</option>
					</select>
					<select>
						<option>Select Grade</option>
						<option>Select Grade</option>
						<option>Select Grade</option>
					</select>
					<select>
						<option>Select Learning Style</option>
						<option>Select Learning Style</option>
						<option>Select Learning Style</option>
					</select>
					<input type="text" name="zipcode" placeholder="Enter Zipcode">
					<div class="w-100 text-center">
						<button class="btn btn-primary btn-lg">Submit</button>
					</div>
				
			</div> <!-- search-box -->
			<div class="recomended-teachers">
			<h2>Recommended Teachers</h2>
			<div class="boxes">
				<div class="box" *ngFor="let teacher of teacherondemand.teachers">
					<div class="cover-img">
						<img src="{{siteurl}}/{{teacher.image}}">
					</div>
					<div class="profile-pic"><img src="{{siteurl}}/{{teacher.image}}"></div>
					<h3>{{teacher.fname}} {{teacher.lname}}</h3>
					<ul>
						<li><span>Country</span>Country</li>
						<li><span>State</span>State</li>
						<li><span>Grade</span>{{teacher.gname}}</li>
						<li><span>Zipcode</span> Zipcode</li>
					</ul>
					<div class="text-center">
					<a routerLink="/parent/teacher-detail/{{teacher.id}}" class="btn btn-primary btn-lg">View Profile</a>
					</div>
				</div> <!-- box -->
				
			</div> <!-- boxes -->
</div> <!-- recomemded-teachers -->
</div> <!-- teacher-demands -->