import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.css']
})
export class ViewGroupComponent implements OnInit {
siteurl = this.shareurl.url;
invitepeoplelength = 0;
uppost: FormGroup;
commentpost: FormGroup;
invitetojoingroup: FormGroup;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) {
      this.uppost = this.fb.group({
        postcontent: ['']
      });
      this.commentpost = this.fb.group({
        comment: [''],
        // commentid: []
      });
      this.invitetojoingroup = this.fb.group({
        // comment: [''],
        // commentid: []
      });
     }

  groupID:any;
  groupData:any;
  ngOnInit(): void {
    $('.loading-overlay').show();
    this.groupID=this.route.snapshot.params['id'];
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/groupById/'+this.groupID+'',{headers}).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
          this.groupData = data;
          this.invitepeoplelength = this.groupData.invites.length;
        }

      );
  }
  files:any;
  uploadImage(event:any){
    this.files = event.target.files[0];
    console.log(this.files);
  }
  message: any;
  error:any;
  uploadPost(){
    if (this.files) {
      $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("group_id", this.groupID);
    formData.append("description", this.uppost.value.postcontent);

    formData.append("media", this.files, this.files.name);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/saveGroupPost', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
          // this.router.navigate(['/teacher/manage-student']);
          $('.form_status').empty().append('<h5 class="text-success text-center">Post Upload Successfully!</h5>');
          $('.autoclick_show_modal').click();
        }
        window.location.reload();
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> Please Upload Media</h5>');
          $('.autoclick_show_modal').click();
    }
  }
  likeMessage:any;
  likeError:any;
  likePost(event:any){
    $('.loading-overlay').show();
    var poois = $(event.target).attr('id');
    console.log(poois);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/likeGroupPost/'+poois+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.likeMessage = response;
        $('.loading-overlay').hide();
        if (this.likeMessage.message == 'success') {
          // this.router.navigate(['/teacher/manage-student']);
          window.location.reload();
        }
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.likeError = error;
          console.log(this.likeError.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
  }

  unlikeMessage:any;
  unlikeError:any;
  unlikePost(event:any){
    $('.loading-overlay').show();
    var poois = $(event.target).attr('id');
    console.log(poois);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/unlikeGroupPost/'+poois+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.unlikeMessage = response;
        $('.loading-overlay').hide();
        if (this.unlikeMessage.message == 'success') {
          window.location.reload();
        }
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.unlikeError = error;
          console.log(this.unlikeError.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
  }
  commentmessage:any;
  commenterror:any;
  submitComment(event:any){
    $('.loading-overlay').show();
    var formData: any = new FormData();
    formData.append("comment", this.commentpost.value.comment);
    formData.append("post_id", event.target.id);

    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/commentGroupPost', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.commentmessage = response;
        $('.loading-overlay').hide();
        if (this.commentmessage.message == 'success') {
          // this.router.navigate(['/teacher/manage-student']);
          $('.form_status').empty().append('<h5 class="text-success text-center">Success</h5>');
          $('.autoclick_show_modal').click();
        }
        window.location.reload();
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.commenterror = error;
          console.log(this.commenterror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
  }
  invitemessage: any;
  inviteerror:any;
  inviteToJoinGroup(){
    $('.loading-overlay').show();
    var mcqarray:any = [];
    var questions:any = [];
    for (var i = 0; i < this.invitepeoplelength; ++i) {
      var roption = $('input[name="st'+i+'"]:checked').val();
      mcqarray = [roption];
      questions.push(mcqarray);
    }

    console.log(questions);
    var members_id = questions;
    var formData: any = new FormData();
    formData.append("group_id", this.groupID);
    formData.append("member_ids", members_id);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/inviteToJoinGroup', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.invitemessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.invitemessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
            //     window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.inviteerror = error;
          console.log(this.inviteerror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.inviteerror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }
  requestToJoinGroupmessage:any
  requestToJoinGrouperror:any
  requestToJoinGroup(){
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/requestToJoinGroup/'+this.groupID+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.requestToJoinGroupmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.requestToJoinGroupmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
            //     window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.requestToJoinGrouperror = error;
          console.log(this.requestToJoinGrouperror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.requestToJoinGrouperror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }
  acceptGroupJoinRequestmessage:any
  acceptGroupJoinRequesterror:any
  acceptGroupJoinRequest(event:any){
    console.log(event.target.id);
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/acceptGroupJoinRequest/'+event.target.id+'/'+this.groupID+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.acceptGroupJoinRequestmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.acceptGroupJoinRequestmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
            //     window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.acceptGroupJoinRequesterror = error;
          console.log(this.acceptGroupJoinRequesterror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.acceptGroupJoinRequesterror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }
  rejectGroupJoinRequestmessage:any;
  rejectGroupJoinRequesterror:any;
  rejectGroupJoinRequest(event:any){
    console.log(event.target.id);
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/rejectGroupJoinRequest/'+event.target.id+'/'+this.groupID+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.rejectGroupJoinRequestmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.rejectGroupJoinRequestmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
                window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.rejectGroupJoinRequesterror = error;
          console.log(this.rejectGroupJoinRequesterror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.rejectGroupJoinRequesterror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }

  acceptgroupmessage:any;
  acceptgrouperror:any;
  acceptgroup(event:any){
    console.log(event.target.id);
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/acceptGroupInvite/'+event.target.id+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.acceptgroupmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.acceptgroupmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
                window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.acceptgrouperror = error;
          console.log(this.acceptgrouperror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.acceptgrouperror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }
  rejectgroupmessage:any;
  rejectgrouperror:any;
  rejectgroup(event:any){
    console.log(event.target.id);
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/rejectGroupInvite/'+event.target.id+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.rejectgroupmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.rejectgroupmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
                window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.rejectgrouperror = error;
          console.log(this.rejectgrouperror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.rejectgrouperror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }

}
