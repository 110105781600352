import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shareurl: ApiService
  	) { 
    var role_nmbr = sessionStorage.getItem('role');
    if (sessionStorage.getItem('token') && role_nmbr == '1') {
          this.router.navigate(['parent']);
    }else if(sessionStorage.getItem('token') && role_nmbr == '2'){
      this.router.navigate(['teacher']);
    };

  	this.form = this.fb.group({
	      email: [''],
	      password: [''],
	    });

  }

  ngOnInit(): void {
    $('.loading-overlay').hide();
// var formData: any = new FormData();
//     var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
//   	this.http.get('http://latif-overseas-ind.com/user',formData ).subscribe(
//         data => {
//         	console.log(data);
//         }

//       );
  }
  errors:any;
  GoldData:any;
  submitForm() {
    $('.loading-overlay').show();
    // var ntoken = '';
    // var ntoken: any = '';
    var formData: any = new FormData();
    formData.append("email", this.form.value.email);
    formData.append("password", this.form.value.password);
    
    this.http.post(''+this.siteurl+'/login', formData).subscribe(
        data => {
        	console.log(data)
        this.GoldData=data,
           console.log(this.GoldData.token);
           
           if (this.GoldData.user.role == '1' && this.GoldData.completed == 'ok') {
                  this.router.navigate(['/parent']);
                  sessionStorage.setItem('token', this.GoldData.token);
                  sessionStorage.setItem('role', this.GoldData.user.role);
                  sessionStorage.setItem('fname', this.GoldData.user.fname);
                  sessionStorage.setItem('lname', this.GoldData.user.lname);
                  sessionStorage.setItem('image', this.GoldData.user.image);
              }else if(this.GoldData.user.role == '1' && this.GoldData.completed == 'no'){
                sessionStorage.setItem('token', this.GoldData.token);
                  sessionStorage.setItem('prole', this.GoldData.user.role);
                this.router.navigate(['/parent-complete-profile']);
                $('header.site-header nav ul li.plogout').addClass('d-none');
                  $('header.site-header nav ul li.tlogout').removeClass('d-none');
                  $('.loading-overlay').hide();
              } 
              if(this.GoldData.user.role == '2' && this.GoldData.completed == 'ok'){
                
                if (this.GoldData.status == 'pending') {
                  this.router.navigate(['/processing']);
                  sessionStorage.setItem('token', this.GoldData.token);
                  $('header.site-header nav ul li.plogout').addClass('d-none');
                  $('header.site-header nav ul li.tlogout').removeClass('d-none');
                  $('.loading-overlay').hide();
                }
                if (this.GoldData.status == 'rejected') {
                  this.router.navigate(['/rejected']);
                  sessionStorage.setItem('token', this.GoldData.token);
                  $('header.site-header nav ul li.plogout').addClass('d-none');
                  $('header.site-header nav ul li.tlogout').removeClass('d-none');
                  $('.loading-overlay').hide();
                }
                if (this.GoldData.status == 'approved') {
                  sessionStorage.setItem('token', this.GoldData.token);
                  sessionStorage.setItem('role', this.GoldData.user.role);
                  sessionStorage.setItem('fname', this.GoldData.user.fname);
                  sessionStorage.setItem('lname', this.GoldData.user.lname);
                  sessionStorage.setItem('image', this.GoldData.user.image);
                  this.router.navigate(['/teacher']);
                  $('header.site-header nav ul li.plogout').removeClass('d-none');
                  $('header.site-header nav ul li.tlogout').addClass('d-none');
                  $('.loading-overlay').hide();
                }
              }else if(this.GoldData.user.role == '2' && this.GoldData.completed == 'no'){
                sessionStorage.setItem('token', this.GoldData.token);
                  sessionStorage.setItem('trole', this.GoldData.user.role);
                this.router.navigate(['/teacher-complete-profile']);
                $('.loading-overlay').hide();
              }
        },
        error => {
          $('.loading-overlay').hide();
          $("html, body").animate({ scrollTop: 0 }, 600);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          console.log(this.errors);
          for (let i = 0; i < this.errors.length; i++) {
              $('.errors').append('<li>'+this.errors[i]+'</li>');
            } 
          
        }

      );
    
   
    var headers = { 'Authorization': 'Bearer '+this.GoldData.token+'' };
    // sessionStorage.setItem('key', 'value');
    // localStorage.setItem('token', this.GoldData.token);
    // this.http.post('https://latif-overseas-ind.com/logout', formData, { headers }).subscribe(
    //   (response) => console.log(response),
    //   (error) => console.log(error),
    // )
    
  }

}
