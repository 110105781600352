import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";
@Component({
  selector: 'app-teachersignup',
  templateUrl: './teachersignup.component.html',
  styleUrls: ['./teachersignup.component.css']
})
export class TeachersignupComponent implements OnInit {

form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shareurl: ApiService
  	) { 
    var role_nmbr = sessionStorage.getItem('role');
    if (sessionStorage.getItem('token') && role_nmbr == '1') {
          this.router.navigate(['parent']);
    }else if(sessionStorage.getItem('token') && role_nmbr == '2'){
      this.router.navigate(['teacher']);
    };
  	this.form = this.fb.group({
	      first_name: [''],
	      last_name: [''],
	      email: [''],
	      password: [''],
	      confirm_password: [''],
	      teachher_signup: '2'
	    });
  }

  ngOnInit(): void {
    $('.loading-overlay').hide();
  }
  files:any;
  uploadImage(event:any){
    this.files = event.target.files[0];
    console.log(this.files);
  }
  GoldData:any;
  errors:any;
  submitForm() {
    if (this.files) {
    // var ntoken = '';
    // var ntoken: any = '';
    var formData: any = new FormData();
    formData.append("fname", this.form.value.first_name);
    formData.append("lname", this.form.value.last_name);
    formData.append("email", this.form.value.email);
    formData.append("password", this.form.value.password);
    formData.append("password_confirmation", this.form.value.confirm_password);
    formData.append("user_id", this.form.value.teachher_signup);
    formData.append("img", this.files, this.files.name);
    $('.loading-overlay').show();
    this.http.post(''+this.siteurl+'/register', formData).subscribe(
        data => {
        this.GoldData=data;
        console.log(data);
           if (this.GoldData.message == 'success') {
                  this.router.navigate(['email-varification']);
                  $('.loading-overlay').hide();
              }else{
                console.log('no');
              }
        },
        error => {
          $("html, body").animate({ scrollTop: 0 }, 600);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          console.log(this.errors);
          for (let i = 0; i < this.errors.length; i++) {
              $('.errors').append('<li>'+this.errors[i]+'</li>');
            } 
            $('.loading-overlay').hide();
          
        }

      );
   // sessionStorage.setItem('token', this.GoldData.token);
   //  var headers = { 'Authorization': 'Bearer '+this.GoldData.token+'' };
    // sessionStorage.setItem('key', 'value');
    // localStorage.setItem('token', this.GoldData.token);
    // this.http.post('https://latif-overseas-ind.com/logout', formData, { headers }).subscribe(
    //   (response) => console.log(response),
    //   (error) => console.log(error),
    // )
    }else{
    $("html, body").animate({ scrollTop: 0 }, 600);
      $('.errors').empty().append('<li>Please Upload Profile Image</li>');
    }
    
  }

}
