<!-- sign up -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="signup-page">
  <div class="inner">
    <h2>  Forgot Password  </h2>
    <ul class="errors"></ul>
      <form class="w-100" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="email input">
        <input type="email" name="email" placeholder="Email" formControlName="email">
        </div>
        <button class="btn login">Reset</button>
      </form>
      <p class="text-center mt-3 pt-3 border-top"> or <a routerLink="/signin">Log In</a> </p>
  </div>
</div>