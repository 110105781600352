import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-edit-assignment',
  templateUrl: './edit-assignment.component.html',
  styleUrls: ['./edit-assignment.component.css']
})
export class EditAssignmentComponent implements OnInit {
siteurl = this.shareurl.url;
assignments: FormGroup;
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) {

    this.assignments = this.fb.group({
        title: [''],
        description: [''],
        assigned_on: [''],
        last: [''],
        asfiles: ['']
      });

     }

  assignmentData:any;
  assignment_id: any;
  pod_id: any;
  ngOnInit(): void {
    var today = new Date().toISOString().split('T')[0];
    $("input[type='date']")[0].setAttribute('min', today);
    $(".last_date")[0].setAttribute('min', today);

    $('.loading-overlay').hide();
    this.pod_id=this.route.snapshot.params['id'];
    $('.loading-overlay').hide();

    this.pod_id=this.route.snapshot.params['id'];
    this.assignment_id=this.route.snapshot.params['aid'];
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/viewAssignment/'+this.assignment_id+'', {headers}).subscribe(
        data => {
          console.log(data);
          this.assignmentData = data;
        }

      );
  }


  // files:any;
  files:string [] = [];
  asImage(event:any){
    this.files = [];
    for (var i = 0; i < event.target.files.length; i++) { 
          this.files.push(event.target.files[i]);
          // this.files = event.target.files[i];
          console.log(this.files);
      }
  }


  message: any;
  error:any;
  addAssignment(){
   $('.loading-overlay').show();
    if (this.files) {
      var formData: any = new FormData();
    formData.append("title", this.assignments.value.title);
    formData.append("description", this.assignments.value.description);
    formData.append("assigned_on", this.assignments.value.assigned_on);
    formData.append("last", this.assignments.value.last);
    formData.append("pod_id", this.pod_id);
    for (var i = 0; i < this.files.length; i++) { 
      formData.append("file[]", this.files[i]);
    }

    // formData.append("file[]", this.files, this.files.name);
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.post(''+this.siteurl+'/teacher/saveAssignment', formData, {headers}).subscribe(
      response => {
        console.log(response);
        this.message = response;
        $('.loading-overlay').hide();
        if (this.message.message == 'success') {
          this.router.navigate(['/teacher/assignments/'+this.pod_id+'']);
          // $('.form_status').empty().append('<h5 class="text-success text-center"><i class="fa fa-check" aria-hidden="true"></i> Assignment Add Successfully!</h5>');
          // $('.autoclick_show_modal').click();
        }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.error = error;
          console.log(this.error.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.error.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
        }
    );
    }else{
      $('.loading-overlay').hide();
      $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> File is required</h5>');
      $('.autoclick_show_modal').click();
    }
  }

}
