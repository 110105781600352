<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Pending PODS</h3>
                  </div>

                  <!-- body -->
                  <div class="card-body pt-0">
                      <div class="mtable">
                        <table datatable class="table">
                  <thead style="background:#EFEFEF;border-color:#E2E2E2;">
                  <tr>
                    <th>sr</th>
                    <th>Profile Pic</th>
                    <th>POD Primary Language</th>
                    <th>Zip code</th>
                    <th>Learning Enviorment</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let parent of parentData.pendingPod;let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{parent.image}}"></td>
                    <td>{{parent.pplname}}</td>
                    <td>{{parent.zipcode}}</td>
                    <td>{{parent.lename}}</td>
                    <td>
                      <a routerLink="pending-pod-detail/{{parent.id}}" class="btn btn-primary">Details</a>
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
                </div> <!-- mtable -->
              </div> <!-- card-body -->

              <div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Recomended Teachers</h3>
                  </div>

                  <!-- body -->
                  <div class="card-body pt-0">
                    <div class="mtable">
                      <table datatable class="table">
                  <thead style="background:#EFEFEF;border-color:#E2E2E2;">
                  <tr>
                    <th>View</th>
                    <th>Teacher Pic</th>
                    <th>Teacher Name</th>
                    <th>Grade Level</th>
                    <th>Certification</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let parent of parentData.teachers; let i = index">
                    <td>{{i + 1}}</td>
                    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{parent.image}}"></td>
                    <td>{{parent.fname}} {{parent.lname}}</td>
                    <td>{{parent.gname}}</td>
                    <td><ng-container *ngIf="parent.certification_status == 'active'">
                      <span class="alert alert-success">Active</span>
                    </ng-container><ng-container *ngIf="parent.certification_status == 'notactive'">
                      <span class="alert alert-danger">Not Active</span>
                    </ng-container></td>
                    <td>
                      <a routerLink="teacher-detail/{{parent.id}}" class="btn btn-primary">View</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
                </div> <!-- mtable -->
              </div> <!-- card-body -->