<div class="student-preview">
<div class="selected-student-preview">
	<h1 class="d-block w-100 text-center font-weight-bold sound_bubble_demoregular mt-3">Result</h1>
	<h4 class="d-block text-center sound_bubble_demoregular">(Game: Select Same)</h4>
	<div class="row mt-4 position-relative">
		<div class="col-md-6">
			<div class="inner">
			<div class="img">
				<img src="../assets/img/dummy-student.png">
			</div> <!-- img -->
			<h2>Mary Patricia</h2>
			<span class="bg-white pl-4 pr-4 pt-3 pb-3 d-inline-block mt-3 rounded">Score: 500</span>
			</div>
		</div> <!-- col-md-6 -->
		<span class="vs">VS</span>
		<div class="col-md-6">
			<div class="inner">
			<div class="img">
				<img src="../assets/img/dummy-student1.png">
			</div> <!-- img -->
			<h2>James Robert</h2>
			<span class="bg-white pl-4 pr-4 pt-3 pb-3 d-inline-block mt-3 rounded">Waiting</span>
			</div>
		</div> <!-- col-md-6 -->
	</div> <!-- row -->
	<div class="text-center mt-5">
		<a routerLink="/parent/gamification" class="next-btn">Go Back</a>
	</div>
</div><!-- selected-student-preview -->
</div> <!-- student-preview -->