<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Monitoring Alerts</h3>
                  </div>
    <!-- body -->
    <div class="card-body monitoring-alert">
      <div class="social-distancing">
        <h4>Social Distancing</h4>
        <div class="d-flex align-items-start justify-content-between mt-3 flex-wrap">

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/social-distancing1.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Name Would be here</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/social-distancing2.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Name Would be here</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/social-distance.png">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Name Would be here</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/social-distance.png">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Name Would be here</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

      </div> <!-- div -->
      </div> <!-- social-distancing -->

      <div class="detection-of-mask mt-3">
        <h4>Detection of Mask</h4>
        <div class="d-flex align-items-start justify-content-between mt-3 flex-wrap">

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/mask-protected.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>No Mask</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/mask-protected1.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>NO Mask</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/mask-protected2.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>NO Mask</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/mask-protected3.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>NO Mask</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

      </div> <!-- div -->
      </div> <!-- detection-of-mask -->

      <div class="temperature mt-3">
        <h4>Temperature</h4>
        <div class="d-flex align-items-start justify-content-between mt-3 flex-wrap">

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/temprature.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Temprature</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/temprature.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Temprature</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/temprature.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Temprature</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

        <div class="border rounded box text-center rounded   text-white">
          <div class="img">
            <img src="assets/img/temprature.jpg">
          </div>
          <div class="content p-2">
            <p>POD ID: 98765</p>
            <h4>Temprature</h4>
            <p><i class="fa fa-clock-o" aria-hidden="true"></i> 19/11/2020</p>
          </div>
        </div> <!-- box -->

      </div> <!-- div -->
      </div> <!-- Temperature -->

</div> <!-- card-body -->