import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
form: FormGroup;
siteurl = this.shareurl.url;
  constructor(
  	public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shareurl: ApiService
    ) {
    var role_nmbr = sessionStorage.getItem('role');
    if (sessionStorage.getItem('token') && role_nmbr == '1') {
          this.router.navigate(['parent']);
    }else if(sessionStorage.getItem('token') && role_nmbr == '2'){
      this.router.navigate(['teacher']);
    };
  	this.form = this.fb.group({
	      email: [''],
	    });

     }

  ngOnInit(): void {
  	$('.loading-overlay').hide();
  }
  errors:any;
  Global:any;
  submitForm() {
    $('.loading-overlay').show();
    // var ntoken = '';
    // var ntoken: any = '';
    var formData: any = new FormData();
    formData.append("email", this.form.value.email);
    
    this.http.post(''+this.siteurl+'/forgotPassword', formData).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data)
        this.Global=data,
           console.log(this.Global.token);
           // sessionStorage.setItem('token', this.Global.token);
           // sessionStorage.setItem('role', this.Global.user.role);
           if (this.Global.message == 'success') {
                  this.router.navigate(['/email-processing']);
              }
              if (this.Global.message == 'No Such Email Exist') {
                  $('.errors').empty().append('<li>'+this.Global.message+'</li>');
              }

        },
        error => {
          $('.loading-overlay').hide();
          $("html, body").animate({ scrollTop: 0 }, 600);
          $('.errors').empty();
          this.errors = error['error']['errors'];
          console.log(this.errors);
          for (let i = 0; i < this.errors.length; i++) {
              $('.errors').append('<li>'+this.errors[i]+'</li>');
            } 
          
        }

      );
    
  }

}
