import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-caregiverprofile',
  templateUrl: './caregiverprofile.component.html',
  styleUrls: ['./caregiverprofile.component.css']
})
export class CaregiverprofileComponent implements OnInit {

  constructor() { }

  caregiver_signup(input:any) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
              reader.onload = function(e:any) {
                  $('#caregiver_signup_image').css('background-image', 'url('+e.target.result +')');
                  $('#caregiver_signup_image').hide();
                  $('#caregiver_signup_image').fadeIn(650);
              }
              reader.readAsDataURL(input.files[0]);
          }
      }
    caregiverSignup(a:any) {
      var myImg = <HTMLInputElement>document.getElementById('caregiver_signup');
      myImg.src = a;
    }
    stepOpne(){
      $('.parent-first-step').addClass('hide');
        $('.parent-second-step').removeClass('hide');
      }
      stepTwo(){
      $('.parent-first-step').removeClass('hide');
        $('.parent-second-step').addClass('hide');
      }
  ngOnInit(): void {
    $('.loading-overlay').hide();
  }
  
}
