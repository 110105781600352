import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
siteurl = this.shareurl.url;
  constructor(
  	private http: HttpClient,
	public fb: FormBuilder,
  	public router: Router,
    private shareurl: ApiService
  	) { }

  podData: any;
  groupAlerts:any;
  ngOnInit(): void {
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/teacher/podJoinRequests', {headers}).subscribe(
        data => {
          console.log(data);
          this.podData = data;
          $('.loading-overlay').hide();
        }

      );
    // group alerts
    this.http.get(''+this.siteurl+'/groupAlerts',{headers}).subscribe(
        data => {
          console.log(data);
          this.groupAlerts = data;
        }

      );
  }

  acceptGroupJoinRequestmessage:any;
  acceptGroupJoinRequesterror:any;
  acceptgroup(event:any){
    console.log(event.target.id);
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/acceptGroupInvite/'+event.target.id+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.acceptGroupJoinRequestmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.acceptGroupJoinRequestmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
            //     window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.acceptGroupJoinRequesterror = error;
          console.log(this.acceptGroupJoinRequesterror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.acceptGroupJoinRequesterror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }
  rejectGroupJoinRequestmessage:any;
  rejectGroupJoinRequesterror:any;
  rejectgroup(event:any){
    console.log(event.target.id);
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+'' };
    this.http.get(''+this.siteurl+'/rejectGroupInvite/'+event.target.id+'', {headers}).subscribe(
      response => {
        console.log(response);
        this.rejectGroupJoinRequestmessage = response;
        $('.loading-overlay').hide();
        // if (this.message.obtained) {
          // this.router.navigate(['/teacher/quiz/'+this.pod_id+'']);
          $('.form_status').empty().append('<h5 class="text-success text-center">'+this.rejectGroupJoinRequestmessage.message+'</h5>');
          $('.autoclick_show_modal').click();
            // setInterval(() => {
            //     window.location.reload();
            // }, 3000);
        // }
        
      },
      error => {
        $('.loading-overlay').hide();
          // console.log(error);
          this.rejectGroupJoinRequesterror = error;
          console.log(this.rejectGroupJoinRequesterror.error.errors);
          $('.form_status').empty().append('<h5 class="text-danger text-center"><i class="fa fa-times-circle" aria-hidden="true"></i> '+this.rejectGroupJoinRequesterror.error.errors[0]+'</h5>');
          $('.autoclick_show_modal').click();
          // setInterval(() => {
          //       window.location.reload();
          //   }, 3000);
        }
    );
  }

}
