<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
      <div class="main-sidebar" id="main-sidebar">
        <a class="mw-100 pb-3 border-bottom text-center d-block" href="#"><img src="assets/img/logo.png"></a>
            <ul class="main-menu">
                  <li><a routerLink="/admin" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/home-icon.png"> Home</a></li>
                  <li><a routerLink="/admin/create-pod" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/create-pod-icon.png"> Create PODS</a></li>
                  <li><a routerLink="/admin/manage-pods" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Manage PODS</a></li>
                  <li><a routerLink="/admin/competitions" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Competitions</a></li>
                  <li><a routerLink="/admin/monitoring-alerts" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/notiification-icon.png"> Monitoring Alerts</a></li>
                  <li><a routerLink="/admin/manage-teachers" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Manage Teachers</a></li>
                  <li><a routerLink="/admin/manage-parents" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Manage Parents</a></li>
                  <li><a routerLink="/admin/manage-caregivers" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Manage Caregivers</a></li>
                  <li><a routerLink="/admin/manage-students" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Manage Students</a></li>
                  <li><a routerLink="/admin/payments" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/payment-icon.png"> Payments</a></li>
                  <li><a routerLink="/admin/setting" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/setting-icon.png"> Settings</a></li>
                  <li><a routerLink="/admin/insights" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/img/manage-pod-icon.png"> Insights</a></li>
                  <li><a routerLink="#" (click)="logout()"><img src="../assets/img/logout.png"> Logout</a></li>
            </ul>
      </div>
      