<!-- sign up -->
<div class="signup-page">
  <div class="inner">
    <h2>  Sign Up and Start Learning!  </h2>
    <button type="button" class="btn facebook w-100 text-left"><i class="fa fa-facebook" aria-hidden="true"></i> Signup with Facebook</button>
      <button type="button" class="btn w-100 text-left"><img src="assets/img/google.svg"> Signup with Google</button>
      <form [formGroup]="form" (ngSubmit)="submitForm()" class="w-100">
        <div class="name input">
        <input type="name" name="name" placeholder="Full Name" formControlName="name">
        </div>
        <div class="email input">
        <input type="email" name="email" placeholder="Email" formControlName="email">
        </div>
        <div class="input password">
            <input type="password" name="password" placeholder="Password" formControlName="password">
        </div>
        <button class="btn login">Sign Up</button>
      </form>
      <p class="text-center mt-3 pt-3 border-top"> Already have an account? <a routerLink="/signin">Log In</a> </p>
  </div>
</div>