<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3>Student List</h3>
    <a routerLink="/parent/add-student">Add Student</a>
</div>
<!-- body -->
 <div class="card-body pt-0">
  <div class="mtable">
<table datatable class="table">
  <thead style="background:#EFEFEF;border-color:#E2E2E2;">
  <tr>
    <th>View</th>
    <th>Profile Pic</th>
    <th>Student Name</th>
    <th>Subject</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let student of students.students; let i = index">
    <td>{{i + 1}}</td>
    <td><img width="60px" height="60px" class="border rounded-circle" src="{{siteurl}}/{{student.img}}"></td>
    <td>{{student.fname}} {{student.lname}}</td>
    <td>{{student.stitle}}</td>
     
    <td>
      <a routerLink="/parent/student-detail/{{student.id}}" class="btn btn-primary">View Student</a>
    </td>
  </tr>
  
  
  </tbody>
</table>
</div> <!--- mtable --->
</div> <!-- card-body -->