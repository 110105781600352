import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../api.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
	form: FormGroup;
  siteurl = this.shareurl.url;
  constructor(
      public fb: FormBuilder,
    private http: HttpClient,
    private shareurl: ApiService
    ) { 
  	this.form = this.fb.group({
      name: [''],
      email: [''],
      password: ['']
    });
   }

  ngOnInit(): void {
    console.log();
  }
  // submitForm() {
  //   console.log(this.form.value.name)
  // }
  submitForm() {
    var formData: any = new FormData();
    formData.append("name", this.form.value.name);
    formData.append("email", this.form.value.email);
    formData.append("password", this.form.value.password);

    this.http.post(''+this.siteurl+'/testingPost', formData).subscribe(
      (response) => console.log(response),
      (error) => console.log(error),
    )
  }

}
