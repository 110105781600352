<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="chs-add-new-group">
	<form [formGroup]="group" autocomplete="off" (ngSubmit)="createGroup()">
	<h1><a routerLink="/teacher/chs"><img src="../../assets/img/arrow-left.png"></a>Create New Group</h1>
		<div class="top-area">
		<input type="text" name="gname" formControlName="gname" placeholder="Enter Group Name">
		<select name="gcat" formControlName="gcat">
			<option value="">Select Group Category</option>
			<option *ngFor="let gcategorry of groupCategory.groupCategories" value="{{gcategorry.id}}">{{gcategorry.title}}</option>
		</select>
		<select name="gprivacy" formControlName="gprivacy">
			<option value="">Group Privacy</option>
			<option value="public">Public</option>
			<option value="private">Private</option>
		</select>
		<textarea name="gdescription" formControlName="gdescription" placeholder="Enter Description"></textarea>
	</div> <!-- top-area -->
	<div class="bottom-area">
			<div class="coverimg-box">
				<div class="inner-box">
				<div class="upload-image" id="dropContainer">
						<img id="previewimage" src="../../assets/img/picture.png">
						<p>Drag and drop your Photo here <br>or browse</p>
					
				</div> <!-- upload-image -->
				<div class="upfile">
					<p>Browse</p>
					<input type="file" name="add_file" name="image" formControlName="image" (change)="uploadImage($event)" onchange="document.getElementById('previewimage').src = window.URL.createObjectURL(this.files[0])" accept="image/png, image/jpeg, image/jpg">
				</div> <!-- upfile -->
			</div> <!-- inner-box -->
			</div> <!-- coverimg-box -->
	</div> <!-- bottom-area -->
	<button class="btn btn-primary btn-lg mt-3" type="submit">Create Group</button>
	</form>
</div>

              <p class="autoclick_show_modal" data-toggle="modal" data-target="#creategroup"></p>
<!-- Modal -->
<div class="modal fade" id="creategroup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  