<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}" class="active">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
    <h3 class="text_color3">{{podName.name}}</h3>
</div>
	<div class="vpod-body-inner">


  <div class="add-quiz box view-submission d-flex align-items-start justify-content-between">

    <div class="main-box w-100">
      <form [formGroup]="form" (ngSubmit)="addQuiz()">
        <div class="">
          <div class="mr-3">
            <input class="rounded h6 w-100" type="text" name="quiz_name" placeholder="Quiz Title" formControlName="quiz_name">
            <textarea class="rounded h6 w-100" name="quiz_description" placeholder="Quiz Description" formControlName="quiz_description"></textarea>
            <div class="d-flex align-items-start justify-content-between mt-2 add-q">
                <label style="width:30%;">
                  <span class="mb-2 d-block">Start Date</span>
                <input class="rounded h6 w-100" type="date" name="startdate" placeholder="Start Date" formControlName="startdate">
                </label>
                <div style="width:40%;">
                  <span class="mb-2 d-block">Start time</span>
                  <div class="d-flex align-items-end">
                    <select name="hour" formControlName="hour">
                      <option value="">Hour</option>
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <select class="ml-2" name="minute" formControlName="minute">
                      <option value="">Minute</option>
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                      <option value="51">51</option>
                      <option value="52">52</option>
                      <option value="53">53</option>
                      <option value="54">54</option>
                      <option value="55">55</option>
                      <option value="56">56</option>
                      <option value="57">57</option>
                      <option value="58">58</option>
                      <option value="59">59</option>
                      <option value="60">60</option>
                    </select>
                    <select class="ml-2" name="ampm" formControlName="ampm">
                      <option value="">AM or PM</option>
                      <option value="am">AM</option>
                      <option value="pm">PM</option>
                    </select>
                  </div>
                  
                  
                
                </div>
                <label style="width:20%;">
                  <span class="mb-2 d-block">Alloted time</span>
                <input class="rounded h6 w-100" type="number" min="1" name="allotedtime" placeholder="in minutes" formControlName="allotedtime">
                </label>
            </div>
            
          </div>
          <!-- <div class="w-25 border p-2 rounded d-flex align-items-center justify-content-center flex-column">
            <h5>Total Marks</h5>
            <h2 class="mt-2 mcqtotal-number">520</h2>
          </div> -->
        </div>
        

        <div class="add-question">
          
          <!-- <button class="btn btn-primary" (click)="addForm()">Add New</button> -->
          <div class="w-100" *ngFor="let obj of dataarray; let i=index">
            <div id="question" class="mcq box mt-2">
            
            <div class="head-question d-flex align-items-center justify-content-between mb-3">
              <h5>MCQ</h5>
              <div class="d-flex align-items-center">
              <span class='remove-question' (click)="removeform()"><i class='fa fa-times' aria-hidden='true'></i></span><span class='collapse-question'></span>
              </div>
            </div> 
            <div class="mcq">
            <div class="question-inner p-4">
            <input class="rounded h6 w-100" id="mcmainquestion{{i}}" type="text" name="mcmainquestion{{i}}" placeholder="Question">
            <div class="option d-flex align-items-center justify-content-between">
              <input id="option1" class="mcfoption{{i}} rounded h6 mr-2" type="text" name="first_option" placeholder="Option1">
              <input id="option2" class="mcsoption{{i}} rounded h6 mr-2" type="text" name="second_option" placeholder="Option2">
              <input id="option3" class="mctoption{{i}} rounded h6 mr-2" type="text" name="third_option" placeholder="Option3">
              <input id="option4" class="mcfooption{{i}} rounded h6" type="text" name="four_option" placeholder="Option4">
            </div> 
            <div id="right_ans" class="right-ans" name="right_ans">
              <select id="select" class="rounded w-100 h6 mcroption{{i}}">
                <option value="">Select Right Ans</option>
                <option id="roption1" value=""></option>
                <option id="roption2" value=""></option>
                <option id="roption3" value=""></option>
                <option id="roption4" value=""></option>
              </select>
            </div>
            <input type="number" (change)="changevaluePlus()" min="1" id="mctotalmarks{{i}}" class="totalmark rounded h6 mr-2 w-100" name="mcq_marks" placeholder="Marks Number">
          </div>
        </div>
          </div>  <!-- #question -->
            
          </div>
          <!-- <button class="btn btn-primary" (click)="addtruefalse()">Add New</button> -->
          <div *ngFor="let obj of truefalsearray; let i=index">
            <div id="question" class="true_false box mt-2">
            <div class="head-question d-flex align-items-center justify-content-between mt-3 mb-3">
              <h5>True False</h5>
              <div class="d-flex align-items-center">
              <span class='remove-question' (click)="removetruefalse()"><i class='fa fa-times' aria-hidden='true'></i></span><span class='collapse-question'></span>
              </div>
            </div> 
            <div class="true-false p-4 ">
            <input class="rounded h6 w-100" id="tfquestion{{i}}" type="text" name="true_falsetfquestion{{i}}" placeholder="Question">
            <div class="tru-fals d-flex align-items-center">
              <div class="custom-control custom-radio mr-2">
                <input type="radio" class="custom-control-input" value="true" id="truefalse{{i}}" name="truefalse{{i}}">
                <label class="custom-control-label" for="truefalse{{i}}">True</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" value="false" id="truefalsesecond{{i}}" name="truefalse{{i}}">
                <label class="custom-control-label" for="truefalsesecond{{i}}">False</label>
              </div>
            </div>
            <input type="number" min="1" id="truefalsetotal{{i}}" class="totalmark rounded h6 mr-2 w-100" name="truefalsetotal{{i}}" placeholder="Marks Number">
          </div>
        </div>
          </div>
        
          <div id="new_question" class="new-question">
            
          </div>

          <!--  -->
          <div class="head d-flex align-items-center justify-content-between mt-3">
            <select id="select_question" class="rounded h6 m-0">
              <option disabled selected>Question Type</option>
              <option id="mcq" value="mcq">MCQ</option>
              <option id="true_false" value="True False">True False</option>
            </select>
            <a href="javascript:void(0)" class="add-new-question mcq btn btn-primary btn-lg" (click)="addForm()">Add Question</a>
            <a href="javascript:void(0)" class="add-new-question truefalse btn btn-primary btn-lg" (click)="addtruefalse()">Add Question</a>
            
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-3 btn-lg">Create Quiz</button>
      </form>
      </div>
    </div>

    
  </div> <!-- vpod-body-inner -->
</div> <!-- vpod-body -->
              <p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  