import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../../api.service";
import * as $ from 'jquery';

@Component({
  selector: 'app-chs',
  templateUrl: './chs.component.html',
  styleUrls: ['./chs.component.css']
})
export class ChsComponent implements OnInit {
siteurl = this.shareurl.url;
slideConfig = {
  "slidesToShow": 4,
  "dots": false,
  "infinite": true,
  "arrows": true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        "slidesToShow": 3
      }
    },
    {
      breakpoint: 500,
      settings: {
        "slidesToShow": 2
      }
    },
    {
      breakpoint: 360,
      settings: {
        "slidesToShow": 1
      }
    }
  ]
  }
  constructor(
    private http: HttpClient,
  public fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private shareurl: ApiService) { }

  groupData:any;
  ngOnInit(): void {
    $('.loading-overlay').show();
    var headers = { 'Authorization': 'Bearer '+sessionStorage.getItem('token')+''};

    this.http.get(''+this.siteurl+'/groups',{headers}).subscribe(
        data => {
          $('.loading-overlay').hide();
          console.log(data);
          this.groupData = data;
          if(this.groupData.groupsData.length < 5){
            $('.chs-main-page .chs-inner .boxes').addClass('noarrow');
          }
        }

      );
  }

}
