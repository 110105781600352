<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>

		<div class="chs-section">
			<div class="row">
				<div class="col-md-8 left">
					<div class="inrbox">
						<div class="currentgroup-name">
							<div class="bgimg">
								<img src="{{siteurl}}/{{groupData.group.cover}}">
							</div> <!-- bgimg -->
							<div class="content">
								<h2>{{groupData.group.title}}</h2>
								<div class="group-member">
									<ng-container *ngFor="let gmem of groupData.members">
										<img src="{{siteurl}}/{{gmem.image}}">
									</ng-container>
									<span class="count ml-0" *ngIf="groupData.total == 0">{{groupData.total}}</span>
									<span class="count" *ngIf="groupData.members_count != 0">+{{groupData.members_count}}</span>
									<span>Members</span>
								</div>
							</div> <!-- content -->
							<button *ngIf="groupData.isMember == false" class="join-now" (click)="requestToJoinGroup()">Join Now</button>
							<button *ngIf="groupData.isMember == 'pending'" class="join"  data-toggle="modal" data-target="#pendingstatus">Pending</button>
							<button *ngIf="groupData.isMember == 'admin'" class="join">Admin</button>
							<button *ngIf="groupData.isMember == true" class="join">Joined</button>
							<button *ngIf="groupData.isMember == 'requested'" class="join">Requested</button>
							<!-- Modal -->
<div class="modal fade" id="pendingstatus" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex align-items-center justify-content-center">
        <button data-id="{{groupData.group.id}}" class="btn btn-success btn-sm" (click)="acceptgroup($event)">Accept</button>
        <button data-id="{{groupData.group.id}}" class="btn btn-danger btn-sm" (click)="rejectgroup($event)">Reject</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- model end --> 
						</div>
						<div class="chs-posts">
							<div class="box" *ngFor="let group of groupData.groupposts">
								<a class="title" routerLink="#">
									<div class="img">
										<img class="mw-100" src="{{siteurl}}/{{group.post.uimage}}">
									</div>
									<div class="name">
										<h2>{{group.post.fname}} {{group.post.lname}}</h2>
										<span>{{group.post.updated_at}}</span>
									</div>
								</a>
								<p>{{group.post.description}}</p>
								<div class="main-img">
									<ng-container *ngIf="group.post.media_type == 'image'">
										<img class="mw-100 d-block m-auto" src="{{siteurl}}/{{group.post.media}}">
									</ng-container>
									<ng-container *ngIf="group.post.media_type == 'video'">
										<video width="100%" controls>
										  <source src="{{siteurl}}/{{group.post.media}}" type="video/mp4">
										</video>
									</ng-container>
								
								</div>
								<div class="post-action">
									<div class="like">
										<ng-container *ngIf="group.liked == 0">
											<button data-id="{{group.post.id}}" (click)="likePost($event)"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {{group.likes}}</button>
										</ng-container>
										<ng-container *ngIf="group.liked == 1">
											<button class="active" data-id="{{group.post.id}}" (click)="unlikePost($event)"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {{group.likes}}</button>
										</ng-container>
										
										<button><i class="fa fa-commenting" aria-hidden="true"></i> {{group.comment_count}}</button>
									</div>
									<div class="comments">
										<div class="cmboxes">
											<div class="cbox" *ngFor="let comm of group.comments">
												<div class="img">
													<img class="mw-100" src="{{siteurl}}/{{comm.image}}">
												</div> <!-- img -->
												<div class="content">
													<h2>{{comm.fname}} {{comm.lname}}</h2>
													<p>{{comm.comment}}</p>
												</div> <!-- content -->
											</div> <!-- cbox -->
										</div> <!-- cmboxes -->
									<form [formGroup]="commentpost" (ngSubmit)="submitComment($event)" id="{{group.post.id}}">
										<!-- <input type="hidden" name="commentid" formControlName="commentid" ng-init="Price=100" ng-value="Price">  -->
										<input type="text" name="comment" placeholder="Write your comment" formControlName="comment">
										<button type="submit"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
									</form>
									</div>
								</div>
							</div> <!-- box -->
							

							<!-- <div class="text-center">
								<button class="loadmore">Load More</button>
							</div> -->
						</div>
					</div>
				</div> <!-- col-md-8 -->
				<div class="col-md-4 right">
					<div class="upload-post chsrbox">
						<h2>Upload Your Post</h2>
						<form [formGroup]="uppost" (ngSubmit)="uploadPost()">
						<textarea placeholder="Write Something" class="w-100 mb-2" name="postcontent" formControlName="postcontent"></textarea>
    <input type="file" name="add_file" name="image" formControlName="image" (change)="uploadImage($event)" value="Upload Media">
						<button type="submit">Upload</button>
						</form>
					</div> <!-- upload-post -->
						<div class="invite-people chsrbox mt-4">
							<h2>Invite People</h2>
							<form class="mt-3" [formGroup]="invitetojoingroup" (ngSubmit)="inviteToJoinGroup()">
							<div class="inner-box">
								<ul>
									<li *ngFor="let invite of groupData.invites;let i = index">
										<div class="img">
											<img class="mw-100" src="{{siteurl}}/{{invite.image}}">
										</div> <!-- img -->
										<h2>{{invite.fname}} {{invite.lname}}</h2>
										<input type="checkbox" name="st{{i}}" value="{{invite.id}}">
									</li>
									
								</ul>
								<div class="text-center mt-4">
								<button class="btn btn-primary btn-lg" type="submit">Send Invitation</button>
								</div>
							</div> <!-- inner-box -->
						</form>
						</div> <!-- invite-people -->
						<ng-container *ngIf="groupData.joinRequests != ''">
						<div class="chsrbox mt-4">
							<h2>Request to Join Group</h2>
							<div class="inner-box">
								<ul>
									<li class="d-flex align-items-center justify-content-between border-bottom pt-2 pb-2" *ngFor="let joinrqest of groupData.joinRequests;let i = index">
										<div  class="d-flex align-items-center">
										<div class="img overflow-hidden rounded mr-2" style="width:35px;height:35px;">
											<img class="mw-100" src="{{siteurl}}/{{joinrqest.image}}">
										</div> <!-- img -->
										<h6 class="m-0">{{joinrqest.fname}} {{joinrqest.lname}}</h6>
										</div>
										<div class="d-flex align-items-center">
											<button class="btn  btn-success btn-sm" data-id="{{joinrqest.id}}" (click)="acceptGroupJoinRequest($event)">Accept</button>
											<button class="btn btn-primary btn-sm ml-1" data-id="{{joinrqest.id}}" (click)="rejectGroupJoinRequest($event)">Reject</button>
										</div>
									</li>
									
								</ul>
							</div> <!-- inner-box -->
						</div> <!-- invite-people -->
						</ng-container>
					<div class="rboxgroups chsrbox">
						<h2>Your Groups </h2>
						<div class="gbox">
							<div class="inner"  *ngFor="let ygroup of groupData.myGroups">
							<div class="left">
								<div class="img">
									<img src="{{siteurl}}/{{ygroup.group.cover}}">
								</div>
								<div class="title">
									<h2>{{ygroup.group.title}}</h2>
									<p>{{ygroup.members}} member</p>
								</div>
							</div> <!-- left -->
							<div class="right">
								<span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
								<div class="acinner">
									<a routerLink="#"><i class="fa fa-eye" aria-hidden="true"></i> view</a>
									<a routerLink="#"><i class="fa fa-ban" aria-hidden="true"></i> Block</a>
								</div>
							</div>
						</div> <!-- inner -->
						
						<div class="text-center">
						<a class="view-all" routerLink="/parent/chs">View All</a>
						</div>
						</div> <!-- gbox -->
					</div> <!-- rboxgroups -->
					<div class="rboxgroups chsrbox">
						<h2>Suggested Groups </h2>
						<div class="gbox">
							<div class="inner">
							<div class="left">
								<div class="img">
									<img src="../../assets/img/pairgroup.png">
								</div>
								<div class="title">
									<h2>Group Name</h2>
									<p>13.6k member</p>
								</div>
							</div> <!-- left -->
							<div class="right">
								<span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
								<div class="acinner">
									<a routerLink="#"><i class="fa fa-eye" aria-hidden="true"></i> view</a>
									<a routerLink="#"><i class="fa fa-ban" aria-hidden="true"></i> Block</a>
								</div>
							</div>
						</div> <!-- inner -->
						<div class="inner">
							<div class="left">
								<div class="img">
									<img src="../../assets/img/pairgroup.png">
								</div>
								<div class="title">
									<h2>Group Name</h2>
									<p>13.6k member</p>
								</div>
							</div> <!-- left -->
							<div class="right">
								<span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
								<div class="acinner">
									<a routerLink="#"><i class="fa fa-eye" aria-hidden="true"></i> view</a>
									<a routerLink="#"><i class="fa fa-ban" aria-hidden="true"></i> Block</a>
								</div>
							</div>
						</div> <!-- inner -->
						<div class="inner">
							<div class="left">
								<div class="img">
									<img src="../../assets/img/pairgroup.png">
								</div>
								<div class="title">
									<h2>Group Name</h2>
									<p>13.6k member</p>
								</div>
							</div> <!-- left -->
							<div class="right">
								<span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
								<div class="acinner">
									<a routerLink="#"><i class="fa fa-eye" aria-hidden="true"></i> view</a>
									<a routerLink="#"><i class="fa fa-ban" aria-hidden="true"></i> Block</a>
								</div>
							</div>
						</div> <!-- inner -->
						<div class="text-center">
						<a class="view-all" routerLink="/parent/chs">View All</a>
						</div>
						</div> <!-- gbox -->
					</div> <!-- rboxgroups -->
				</div> <!-- col-md-4 -->
			</div> <!-- row -->
		</div> <!-- chs -->

<p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  