<div class="inner-header d-flex align-items-center justify-content-between mb-4">
          <h3>Manage Students</h3>
    </div>

    <!-- body -->
    <div class="card-body pt-0">
      <div class="mtable">
  <table datatable class="table">
    <thead>
    <tr>
      <th>No</th>
      <th>Student Name</th>
      <th>Parent Name</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>1</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>2</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>3</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>4</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>5</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>6</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>7</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>8</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>9</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>10</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>11</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>12</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>13</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>14</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>15</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>16</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>17</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>18</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>19</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    <tr>
      <td>20</td>
       
      <td>Banner Bruce</td>
      <td>California</td>
       
       
      <td>
        <a routerLink="/admin/student-detail" class="btn btn-primary">View</a>
      </td>
    </tr>
    
    </tbody>
  </table>
</div> <!--- mtable --->
</div> <!-- card-body -->