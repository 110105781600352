import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './guards/auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { LoaderService } from './loader/services/loader.service';
import { LoaderInterceptorService } from './loader/interceptors/loader-interceptor.service';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { AdminModule } from './admin/admin.module';
import { ParentModule } from './parent/parent.module';
import { TeacherModule } from './teacher/teacher.module';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { TeacherprofileComponent } from './components/teacherprofile/teacherprofile.component';
import { CaregiverprofileComponent } from './components/caregiverprofile/caregiverprofile.component';
import { ParentprofileComponent } from './components/parentprofile/parentprofile.component';
import { PodComponent } from './components/pod/pod.component';
import { TeacherviewComponent } from './components/teacherview/teacherview.component';
import { StudentviewComponent } from './components/studentview/studentview.component';
import { JoinpodComponent } from './components/joinpod/joinpod.component';
import { SinglepodComponent } from './components/singlepod/singlepod.component';
import { ParentsignupComponent } from './components/parentsignup/parentsignup.component';
import { TeachersignupComponent } from './components/teachersignup/teachersignup.component';
import { EmailvarificationComponent } from './components/emailvarification/emailvarification.component';
import { CaregiversignupComponent } from './components/caregiversignup/caregiversignup.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { TeacherProcessingComponent } from './components/teacher-processing/teacher-processing.component';
import { TeacherRejectedComponent } from './components/teacher-rejected/teacher-rejected.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EmailProcessingComponent } from './components/email-processing/email-processing.component';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SigninComponent,
    SignupComponent,
    ForgotpasswordComponent,
    TeacherprofileComponent,
    CaregiverprofileComponent,
    ParentprofileComponent,
    PodComponent,
    TeacherviewComponent,
    StudentviewComponent,
    JoinpodComponent,
    SinglepodComponent,
    ParentsignupComponent,
    TeachersignupComponent,
    EmailvarificationComponent,
    CaregiversignupComponent,
    LandingPageComponent,
    AdminLoginComponent,
    TeacherProcessingComponent,
    TeacherRejectedComponent,
    ResetPasswordComponent,
    EmailProcessingComponent,
    LoaderComponent

    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    AdminRoutingModule,
    AdminModule,
    ParentModule,
    TeacherModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
