<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
                        <h3>Edit Serie</h3>
                  </div>
                          <form [formGroup]="form" (ngSubmit)="submitForm()">
                      <div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

                          <div class="left">
                    <input class="h6 rounded w-100" type="text" name="title" placeholder="Serie Title" formControlName="title">
                  <textarea class="h6 rounded w-100 " name="description" placeholder="Serie Description" formControlName="description"></textarea>
                  <button type="submit" class="btn btn-primary d-inline-block text-white mt-2 pl-4 pr-4" >
                  Update</button>
                  </div>
                  <div class="right border  rounded p-3">
                      <h5>Upload Image</h5>
                      
                      <div class="add-file mt-4 text-center p-4 position-relative">
                        <input type="file" name="add_file" name="image" formControlName="image" (change)="uploadImage($event)" onchange="document.getElementById('previewimage').src = window.URL.createObjectURL(this.files[0])" accept="image/png, image/jpeg, image/jpg">
                        <i class="fa fa-arrow-circle-up d-block mb-2 lead" aria-hidden="true"></i>
                        <a href="javascript:void(0)" class="btn btn-light  ">Add Image</a>
                        <img id="previewimage" width="100%" />
                      </div>
                      <p class="mt-3">{{fileName}}</p>
                      

                  </div>
                          </div> <!-- box -->
                      </form>
              <p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->  