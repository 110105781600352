<!-- body -->
<div class="loading-overlay">
  <img src="../assets/img/loader.gif">
</div>
<div class="vpod-body">

<a routerLink="/parent" class="mb-2 d-inline-block"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
<div class="tcoverimg">
  <img src="{{siteurl}}/{{teacherDetail.teacher[0].image}}">
</div>
<div class="vpod-body-inner p-3 teacher-detail">
<div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

<div class="left pr-3 border-right border-2">
  <div class="tpimg">
      <img class="mw-100 w-100 h-100" src="{{siteurl}}/{{teacherDetail.teacher[0].image}}">
  </div> <!-- tpimg -->
  <h2 class="sound_bubble_demoregular text-center">{{teacherDetail.teacher[0].fname}} {{teacherDetail.teacher[0].lname}}</h2>
  <p class="text-muted text-center mt-1 mb-4">{{teacherDetail.teacher[0].email}}</p>
  <div class="tprofile-inner">
    <ul>
      <li>
        <div class="img">
          <img src="../../assets/img/country-icon.png">
        </div>
        <div class="content">
          <h2>Country</h2>
          <span>{{teacherDetail.teacher_details[0].cname}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/state-icon.png">
        </div>
        <div class="content">
          <h2>State</h2>
          <span>{{teacherDetail.teacher_details[0].sname}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/city-icon.png">
        </div>
        <div class="content">
          <h2>City</h2>
          <span>{{teacherDetail.teacher_details[0].city}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/address-icon.png">
        </div>
        <div class="content">
          <h2>Address</h2>
          <span>{{teacherDetail.teacher_details[0].address}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/dob-icon.png">
        </div>
        <div class="content">
          <h2>DOB</h2>
          <span>{{teacherDetail.teacher_details[0].dob}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/zipcode-icon.png">
        </div>
        <div class="content">
          <h2>Zip Code</h2>
          <span>{{teacherDetail.teacher_details[0].zipcode}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/certificate-icon.png">
        </div>
        <div class="content">
          <h2>Certification Status</h2>
          <span>{{teacherDetail.teacher_details[0].certification_status}}</span>
        </div>
      </li> <!-- li -->
      <li>
        <div class="img">
          <img src="../../assets/img/grade-icon.png">
        </div>
        <div class="content">
          <h2>Grade</h2>
          <span>{{teacherDetail.teacher_details[0].gname}}</span>
        </div>
      </li> <!-- li -->
      <li class="cv">
        <div class="img">
          <img src="../../assets/img/cv-icon.png">
        </div>
        <div class="content">
          <div class="leftc">
          <h2>CV</h2>
          <span>{{teacherDetail.teacher[0].fname}}</span>
        </div> <!-- left -->
        <a class="d-inline-block btn btn-primary btn-lg" href="{{siteurl}}/{{teacherDetail.teacher_details[0].cv}}" download="{{siteurl}}/{{teacherDetail.teacher_details[0].cv}}">
        Download
      </a>
        </div>
      </li> <!-- li -->
    </ul>
  </div> <!-- tprofile-inner -->

<div class="tprofile-inner mt-4">
<form [formGroup]="form" (ngSubmit)="submitForm()" class="d-flex align-items-center joipodrequest justify-content-between">
  
  <select class="form-control" name="podID" formControlName="podID" >
    <option value="">Select POD</option>
    <option *ngFor="let tdetail of teacherDetail.pods" value="{{tdetail.id}}">{{tdetail.name}}</option>
  </select>
  <button class="btn btn-primary btn-lg">Request to Join POD</button>
  
</form>
<p class="autoclick_show_modal" data-toggle="modal" data-target="#joinpod"></p>
   </div>  <!-- tprofile-inner -->
  </div>
<div class="right" style="width:33%;">
<div class="covid19-box border p-3 pt-4 pb-4" style="box-shadow:0px 0px 28px 0px #e6e6e6;border-radius:15px;">
  <h6 class="text-black font-weight-bold">Covid-19</h6>
  <div class="d-flex align-items-center mt-3 mb-2">
    <ng-container *ngIf="teacherDetail.report == 'Positive'">
      <h6 class="text-danger">Positive (+)</h6>
    </ng-container>
    <ng-container *ngIf="teacherDetail.report == 'Negative'">
      <h6 class="text-success">Negative (-)</h6>
    </ng-container>
  <p class="h6 mb-0 ml-3">{{teacherDetail.score}}/36</p>
  </div> <!-- div -->
  <div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Do you have cough?</p>
  <ng-container *ngIf="teacherDetail.covid[0].cough == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].cough == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Do you have colds?</p>
  <ng-container *ngIf="teacherDetail.covid[0].cold == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].cold == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Are you having Diarhea?</p>
  <ng-container *ngIf="teacherDetail.covid[0].diarhea == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].diarhea == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Do you have sore throat?</p>
  <ng-container *ngIf="teacherDetail.covid[0].sore_throat == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].sore_throat == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Are you experiencing MYLAGIA or Body Aches?</p>
  <ng-container *ngIf="teacherDetail.covid[0].mylagia == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].mylagia == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Do you have a headache?</p>
  <ng-container *ngIf="teacherDetail.covid[0].headache == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].headache == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Do you have fever (Temperature 37.8 C and above)</p>
  <ng-container *ngIf="teacherDetail.covid[0].fever == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].fever == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Are you having difficulty breathing?</p>
  <ng-container *ngIf="teacherDetail.covid[0].breathing == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].breathing == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Are you experiencing Fatigue?</p>
  <ng-container *ngIf="teacherDetail.covid[0].fatigue == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].fatigue == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Have you traveled recently during tha past 14 days?</p>
  <ng-container *ngIf="teacherDetail.covid[0].travel14days == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].travel14days == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2 border-bottom">
  <p>Do you have a travel history to a COVID-19 INFECTED AREA?</p>
  <ng-container *ngIf="teacherDetail.covid[0].travelcovid == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].travelcovid == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
<div class="d-flex align-items-center justify-content-between pt-2 pb-2">
  <p>Do you have direct contact or is taking care of a positive COVID-19 PATIENT? (3 PTS)</p>
  <ng-container *ngIf="teacherDetail.covid[0].contactcovid == 'yes'">
      <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
  </ng-container>
  <ng-container *ngIf="teacherDetail.covid[0].contactcovid == 'no'">
      <span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>
  </ng-container>
</div> <!-- div -->
    </div>
    
    
  </div>
</div> <!-- box -->

</div> <!-- vpod-body-inner -->
</div> <!-- vpod-body -->

<!-- Modal -->
<div class="modal fade" id="joinpod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body form_status">
        
      </div>
    </div>
  </div>
</div>
<!-- model end -->    