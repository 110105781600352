<div class="vpod-body">
<div class="vpod-nav">
    <ul>
        <li><a routerLink="/teacher/pod-detail/{{pod_id}}">Start POD</a></li>
        <li><a routerLink="/teacher/board/{{pod_id}}">Board</a></li>
        <li><a routerLink="/teacher/notes/{{pod_id}}">Notes</a></li>
        <li><a routerLink="/teacher/students/{{pod_id}}" class="active">Students</a></li>
        <li><a routerLink="/teacher/assignments/{{pod_id}}">Assignments</a></li>
        <li><a routerLink="/teacher/quiz/{{pod_id}}">Quiz</a></li>
        <li><a routerLink="/teacher/attendance/{{pod_id}}">Attendence</a></li>
        <li><a routerLink="/teacher/reports/{{pod_id}}">Reports</a></li>
    </ul>
</div> <!-- vpod-nav -->

	<div class="vpod-body-inner p-3">
                      <h2 class="sound_bubble_demoregular mb-2 text_color3">Student Pair Collaborate</h2>
                         <div class="fs-container w-75 m-auto">
      <div class="editor-menu d-flex align-items-center mb-3">
        <div class="editor-dropdown position-relative">
          <span class="editor-dropdown-open"><i class="fa fa-bars" aria-hidden="true"></i></span>
          <div class="inner-menu">
          <a href="javascript:void(0);" class='tool' id="tool-select" title="Select"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> Select</a>
        <a href="javascript:void(0);" class='tool' id="undo-lc" title="Undo"><i class="fa fa-undo" aria-hidden="true"></i> Undo</a>
        <a href="javascript:void(0);" class='tool' id="redo-lc" title="Redo"><i class="fa fa-repeat" aria-hidden="true"></i> Redo</a>
        <a href="javascript:void(0);" class='tool' id="clear-lc">Clear</a>
        </div>
        </div> <!-- editor-drop -->
        <div class="inner">
        <a href="javascript:void(0);" class='tool' id="open-image" title="Open image"><i class="fa fa-picture-o" aria-hidden="true"></i></a>

        <a href="javascript:void(0);" class='tool' id="tool-eraser" title="Eraser"><i class="fa fa-eraser" aria-hidden="true"></i></a>

        <a href="javascript:void(0);" class='tool' id="tool-text" title="text"><i class="fa fa-text-width" aria-hidden="true"></i></a>

        <a href="javascript:void(0);" class='tool' id="tool-pencil" title="Pencil"><i class="fa fa-pencil" aria-hidden="true"></i></a>

        <a href="javascript:void(0);" class='tool' id="tool-line" title="Line"><i class="fa fa-line-chart" aria-hidden="true"></i></a>
        <a href="javascript:void(0);" class='tool' id="tool-arrow" title="arrow"><i class="fa fa-arrows" aria-hidden="true"></i></a>
        <a href="javascript:void(0);" class='tool' id="tool-dashed" title="Dashed Line"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
        <a href="javascript:void(0);" class='tool' id="tool-ellipse" title="Circle"><i class="fa fa-circle-thin" aria-hidden="true"></i></a>
        <a href="javascript:void(0);" class='tool' id="tool-rectangle" title="Rectangle"><i class="fa fa-square-o" aria-hidden="true"></i></a>
        <a href="javascript:void(0);" class='tool' id="tool-polygon" title="Shapes"><i class="fa fa-play" aria-hidden="true"></i></a>

        <div class="color d-flex align-items-center mt-2">
          <div class="custom-control custom-radio tool mr-2" id="colorTool-black">
            <input type="radio" class="custom-control-input" id="black" name="color">
            <label class="custom-control-label" for="black">Black</label>
          </div>
          <div class="custom-control custom-radio tool mr-2" id="colorTool-blue">
            <input type="radio" class="custom-control-input" id="blue" name="color">
            <label class="custom-control-label" for="blue">Blue</label>
          </div>
          <div class="custom-control custom-radio tool" id="colorTool-red">
            <input type="radio" class="custom-control-input" id="red" name="color">
            <label class="custom-control-label" for="red">Red</label>
          </div>
        </div>  
      </div> <!-- inner -->
      </div> <!-- editor-menu -->

      <div class="toolset">
        
        <!-- <a href="javascript:void(0);" class='tool' id="change-size">Change size</a>
        <a href="javascript:void(0);" class='tool' id="reset-size">Reset size</a> -->
        <!-- <a href="javascript:void(0);" class='tool' id="hide-lc">Teardown</a>
        <a href="javascript:void(0);" class='tool' id="show-lc">Setup</a> -->
        
      </div>


<div class="literally"></div>
<div class="collaborate-camera">
  <div class="inner">
    <div class="img">
      <img class="mw-100" src="../assets/img/video-min-call.jpg">
    </div>  
    <h4 class="text-center mt-2">John Doe</h4>
    <ul>
     <li><a href="javascript:void(0)" title="Voice"><i class="fa fa-microphone" aria-hidden="true"></i></a></li>
    <li><a href="javascript:void(0)" title="Camera"><i class="fa fa-video-camera" aria-hidden="true"></i></a></li>
    <li><a href="javascript:void(0)" title="Window Screen"><i class="fa fa-desktop" aria-hidden="true"></i></a></li>
  </ul>
  </div> <!-- inner -->
  <div class="inner">
    <div class="img">
      <img class="mw-100" src="../assets/img/video-call.jpg">
    </div>
    <h4 class="text-center mt-2">Petter Parker</h4>
    <ul>
     <li><a href="javascript:void(0)" title="Voice"><i class="fa fa-microphone" aria-hidden="true"></i></a></li>
    <li><a href="javascript:void(0)" title="Camera"><i class="fa fa-video-camera" aria-hidden="true"></i></a></li>
    <li><a href="javascript:void(0)" title="Window Screen"><i class="fa fa-desktop" aria-hidden="true"></i></a></li>
 </ul>
  </div>
</div> <!-- camera -->
<h2 class="sound_bubble_demoregular mt-5">Discussion</h2>
<div class="chat-box border bg-white border rounded">
<div class="inner p-3">
   <div class="left">

       <div class="img rounded-circle">
           <img class="mw-100" src="../assets/img/cat.jpg">
       </div>
       <div class="content">
           <h2>John Doe, 5:32 PM</h2>
           <p>Hlo</p>
       </div>
   </div>
   <div class="left">
       <div class="img rounded-circle">
           <img class="mw-100" src="../assets/img/cat.jpg">
       </div>
       <div class="content">
           <h2>John Doe, 5:32 PM</h2>
           <p>How Are you</p>
       </div>
   </div>
   <div class="right">
       <div class="content">
           <h2>5:33 PM</h2>
           <p>I am fine</p>
       </div>
       <div class="img rounded-circle">
           <img class="mw-100" src="../assets/img/cat.jpg">
       </div>
   </div>
</div> <!-- inner -->
<div class="form">
   <form>
       <input type="text" name="chat" placeholder="Type a message">
       <button><i class="fa fa-angle-right" aria-hidden="true"></i></button>
   </form>
</div>
</div>
    </div>
          

                    </div> <!-- vpod-body-inner -->
          </div> <!-- vpod-body -->