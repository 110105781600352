import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-joinpod',
  templateUrl: './joinpod.component.html',
  styleUrls: ['./joinpod.component.css']
})
export class JoinpodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  stepOne(){
	  $('.join-pod-page .signup-page').addClass('hide');
    $('.join-pod-page .proceed-notification').removeClass('hide');
  }
  stepTwo(){
  	$('.join-pod-page .proceed-notification').addClass('hide');
    $('.join-pod-page .create-pod').removeClass('hide');
  }
}
