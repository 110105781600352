<!-- body -->
<div class="vpod-body">
<div class="vpod-nav">
<ul>
    <li><a routerLink="/parent/pod-detail/{{pod_id}}">Home</a></li>
    <li><a routerLink="/parent/assignments/{{pod_id}}">Assignments</a></li>
    <li><a routerLink="/parent/notes/{{pod_id}}" class="active">Notes</a></li>
    <li><a routerLink="/parent/quiz/{{pod_id}}">Quiz</a></li>
    <li><a routerLink="/parent/team-preparation/{{pod_id}}">Team Preparation</a></li>
</ul>
</div> <!-- vpod-nav -->
<div class="inner-header d-flex align-items-center justify-content-between mb-4">
<h3>{{noteData.notes.pname}}</h3>
</div>

<div class="vpod-body-inner">

<div class="box mt-4 view-submission d-flex align-items-start justify-content-between">

<div class="left">
<div class="border rounded p-3 alert-secondary">
    <span class="d-block w-100">Title:</span>
    <p class="h6 mt-2 mb-0">{{noteData.notes.title}}</p>
  </div>

  <div class="border rounded p-3 alert-secondary mt-2">
    <span class="d-block w-100 mb-2">Description:</span>
    <p>{{noteData.notes.description}}</p>
  </div>

<div class="box-inner d-flex align-items-start justify-content-between mt-4">
  <div class="date border rounded text-center d-inline-blocck p-3">
    <h5>Uploaded on</h5>
      <h6 class="mt-2">{{noteData.notes.created_at}}</h6>
  </div>
  <div class="date border rounded text-center d-inline-blocck p-3">
    <h5>Series</h5>
      <h6 class="mt-2">{{noteData.notes.stitle}}</h6>
  </div>
  <div class="date border rounded text-center d-inline-blocck p-3">
    <h5>Category</h5>
      <h6 class="mt-2">{{noteData.notes.ctitle}}</h6>
  </div>

</div>
<div class="border rounded p-3 mt-3">
  <h4 class="mb-2">Tags:</h4>
  <ul class="tags">
    <li *ngFor="let tag of noteData.notes.tags">{{tag}}</li>
  </ul>
</div>
</div>
<div class="right border rounded p-2">
    <h3>Files</h3>
    <div class="file-box mt-3 alert alert-secondary">
        <a class="d-block mb-3" href="{{siteurl}}/{{noteData.notes.file}}" download="{{siteurl}}/{{noteData.notes.file}}">
        <i class="fa fa-file" aria-hidden="true"></i> 
        {{noteData.notes.title}}
      </a>
      <a type="application/octet-stream" class="btn btn-primary d-inline-block mr-1" href="{{siteurl}}/{{noteData.notes.file}}" hreflang="{{siteurl}}/{{noteData.notes.file}}" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i> View</a>

      <a class="btn btn-success d-inline-block" href="{{siteurl}}/{{noteData.notes.file}}" download="{{siteurl}}/{{noteData.notes.file}}"><i class="fa fa-download" aria-hidden="true"></i> Download</a>
    </div> <!-- file-box -->
</div>
</div> <!-- box -->


</div> <!-- vpod-body-inner -->

</div> <!-- vpod-body -->